import React from "react";
import logo from "../../assets/img/brand/logo.png"
import "./loading.css"

const Loading = () => {
  return (
    <div className="preloader">
      <div className="flash-screen">
        <div className="flash-body">
          <img src={logo} alt="Logo" width="120px" style={{ marginRight: '0.5rem' }} />
          <div className="text-container">
            <h1 className="club-name text-black text-4xl">Rourkela Club</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
