import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getUnassignedBookingPurpose } from '../../services/RoomService';

import { useNavigate, useLocation } from 'react-router-dom';
import { Overlay, Tooltip } from 'react-bootstrap';

const TooltipComponent = ({ roomId, roomName }) => {
    const [unassignedPurposes, setUnassignedPurposes] = useState([]);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const target = useRef(null);
    const tooltipRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const FetchData = useCallback(() => {
        setLoading(true);
        getUnassignedBookingPurpose(roomId)
            .then((response) => {
                if (response.success) {
                    setUnassignedPurposes(response.results);
                }
            })
            .catch((error) => {
                console.log('Error fetching unassigned purposes:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [roomId]);

    // Fetch unassigned purposes when the component mounts
    useEffect(() => {
        FetchData();
    }, [FetchData]);

    // Handle clicks outside the tooltip
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (target.current && !target.current.contains(event.target) && tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [target, tooltipRef]);

    const handleEditClick = () => {
        navigate(`/room/${roomId}`);
    };

    const isRoomPage = location.pathname === `/room/${roomId}`;

    // Don't show the icon if there are no unassigned purposes
    if (loading || unassignedPurposes.length === 0) {
        return null;
    }

    return (
        <>
            <span className='cursor-pointer ms-2' ref={target} onClick={() => setShow(!show)}>
                <i className="fas fa-info-circle" style={{ color: '#8B0000' }}></i>
            </span>

            <Overlay target={target.current} show={show} placement="right" ref={tooltipRef}>
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        {loading ? (
                            <div>Loading...</div>
                        ) : (
                            <div style={{ textAlign: 'left' }}>
                                <p>
                                    {roomName} has the following booking purposes missing:
                                </p>
                                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                    {unassignedPurposes.length > 0 ? (
                                        unassignedPurposes.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))
                                    ) : (
                                        <li>No unassigned Booking purposes.</li>
                                    )}
                                </ul>
                                {/* Only show the click here message if there are unassigned purposes and not on the room page */}
                                {unassignedPurposes.length > 0 && !isRoomPage && (
                                    <p>
                                        <span onClick={handleEditClick} style={{ color: 'blue', cursor: 'pointer' }}>
                                            Click here
                                        </span> to add them.
                                    </p>
                                )}
                            </div>
                        )}
                    </Tooltip>
                )}
            </Overlay>
        </>
    );
};

export default TooltipComponent;
