import React from "react";
import Navbar from "../Component/Navbar/Navbar";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import Footer from "../Component/Footer/Footer";
import BottomNavbar from "../Component/BottomNavbar/BottomNavbar";
import useWindowSize from "../../../constant/WindowSize";

const Layout = ({ userRole }) => {
  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
      {(isMobile && userRole === "User") && <BottomNavbar />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "Admin",
  };
};

export default connect(mapStateToProps)(Layout);
