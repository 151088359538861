import React from 'react'
import { Placeholder } from 'react-bootstrap'
const LoadingSkeleton = () => {
    return (
        [1, 2, 3, 4, 5, 6, 7, 8, 9].map(item => (
            <tr key={item} colSpan={6}>
                <Placeholder animation="waves" tag={function noRefCheck() { }}>
                    <Placeholder color="primary"     xs={12} />
                </Placeholder>
            </tr>
        ))

    )
}

export default LoadingSkeleton
