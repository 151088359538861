import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  regeneratePaymentOrder,
  generatePaymentOrder,
  callbackPayment,
  removeFailedBooking
} from "../../../../services/RoomService";

const loadRazorpayScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve(true);
    script.onerror = () => reject(new Error("Script load error"));
    document.body.appendChild(script);
  });
};

const RazorpayPayment = ({
  price,
  roomId,
  bookingId,
  purpose_id,
  bookingDate,
  bookingTime,
  user,
  coupon_id,
  isPendingBooking,
  onPaymentSuccess,
  handleCancelBook = undefined,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadRazorpayScript("https://checkout.razorpay.com/v1/checkout.js");
  }, []);

  const defaultTimeout = 300;

  const handlePaymentModalAction = (order_id, action) => {
    if (handleCancelBook) {
      const handleRemoveFailedBooking = async () => { 
          try {
            const response = await removeFailedBooking({order_id});
            handleCancelBook(false);
          } catch (error) {
            console.error( error);
          }
      };
      handleRemoveFailedBooking();
    }
  };

  const initiatePayment = async () => {
    setIsProcessing(true);
    let order_id, booking_type;
    try {
      let paymentData;
      if (isPendingBooking) {
        const response = await regeneratePaymentOrder({
          bookingId,
          price,
        });
        order_id = response.data.order_id;
        booking_type = response.data.booking_type;
      } else {
        paymentData = {
          price,
          room_pricing_id: purpose_id,
          room_id: roomId,
          bookingDate,
          bookingTime,
          coupon_id,
        };
        const resp = await generatePaymentOrder(paymentData);
        order_id = resp.data.order_id;
        booking_type = resp.data.booking_type;
      }
      const amountInPaise = price * 100;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: amountInPaise,
        currency: "INR",
        name: "Rourkela Club",
        timeout: defaultTimeout,
        image: `${process.env.REACT_APP_BASE_URL}/static/logo.png`,
        description: "Booking Payment",
        order_id,
        handler: async (response) => {
          try {
            const paymentVerification = await callbackPayment({
              payment_id: response.razorpay_payment_id,
              order_id: response.razorpay_order_id,
              signature: response.razorpay_signature,
              booking_type,
            });
            toast.success("Payment successful!");
            if (onPaymentSuccess) onPaymentSuccess();
            navigate(
              `/booking-history?payment_id=${response.razorpay_payment_id}`
            );
          } catch (err) {
            toast.error("Payment verification failed");
            navigate(
              `/booking-history?payment_id=${response.razorpay_payment_id}`
            );
          }
        },
        modal: {
          escape: true,
          handleback: true,
          confirm_close: true,
          ondismiss: function () {
            handlePaymentModalAction(order_id, "Modal close by user");
          },
        },
        retry: {
          enabled: false,
        },
        prefill: {
          name: `${user?.first_name || ''} ${user?.last_name || ''}`,
          email: `${user?.email || ''}`,
        },
        notes: {
          address: "Your Company Address",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
      razorpay.on("payment.failed", function (response) {
        handlePaymentModalAction(order_id, "Payment Failed");
      });
    } catch (error) {
      console.log("error", error)
      toast.error(error.response?.data?.message);
      if (!isPendingBooking) {
        handleCancelBook(false);
      }
      setIsProcessing(false);
    }
  };


  return (
    <div>
      <button
        type="submit"
        className={`btn btn-sm ${
          isPendingBooking ? "btn-primary" : "btn-success"
        } me-2`}
        onClick={initiatePayment}
        disabled={isProcessing}
      >
        {isProcessing ? "Processing..." : "Pay Now"}
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.auth.user || "",
});

export default connect(mapStateToProps)(RazorpayPayment);


