import "../Sidebar/Sidebar.css";

const Header = () => {
  return (
    <>
      <div
        className="header"
        style={{
          backgroundColor: "transparent",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        }}
      ></div>
    </>
  );
};

export default Header;
