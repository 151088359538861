import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmBooking } from "../../services/RoomService";
import "../../components/Public&User/Component/PopUpPage/BookingPage.css";

const ConfirmBookingModal = ({
  updateBookingData,
  booking,
  payment_source: initialPaymentSource,
}) => {
  const [paymentSource, setPaymentSource] = useState(initialPaymentSource);
  const [screenshot, setScreenshot] = useState(null);
  const [paymentTransactionID, setTransactionID] = useState("");
  const [transactionIDError, setTransactionIDError] = useState("");
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookingToCancel, setBookingToCancel] = useState(null);
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const [showCancelBooking, setShowCancelBooking] = useState(false);

  const handleCancelShow = () => {
    setBookingToCancel(booking);
    setShowCancelBooking(true);
  };

  useEffect(() => {
    setIsConfirmEnabled(
      paymentSource?.trim() !== "" &&
      validateTransactionID(paymentTransactionID) &&
      screenshot !== null // Ensure screenshot is selected
    );
  }, [paymentSource, paymentTransactionID, screenshot]);

  const validateTransactionID = (transactionID) => {
    const regex = /^[a-zA-Z0-9_]{5,}$/;
    return regex.test(transactionID);
  };

  const resetForm = () => {
    setPaymentSource(paymentSource || "");
    setScreenshot(null);
    setTransactionID("");
    setTransactionIDError("");
  };

  const handleOpenOffCanvas = () => {
    setIsOffCanvasOpen(true);
  };

  const handleCloseOffCanvas = () => {
    setIsOffCanvasOpen(false);
    resetForm();
  };

  const handleFileChange = (e) => {
    setScreenshot(e.target.files[0]);
  };

  const handleConfirmBooking = async (e) => {
    e.preventDefault();

    if (!validateTransactionID(paymentTransactionID)) {
      setTransactionIDError(
        "Invalid Transaction ID. It must be at least 5 alphanumeric characters."
      );
      return;
    }

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("paymentSource", paymentSource);
      formData.append("bookingId", booking.id);
      formData.append("paymentTransactionID", paymentTransactionID);
      formData.append("attachment", screenshot);

      const resp = await confirmBooking(formData);
      toast.success(resp.data.message);
      updateBookingData(resp.data.results);
      handleCloseOffCanvas();
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    if (bookingToCancel) {
      const payload = { bookingId: bookingToCancel.id, cancelBooking: "YES" };

      confirmBooking(payload)
        .then((resp) => {
          const { message, results } = resp.data;
          updateBookingData(results);
          toast.success(message);
          setShowCancelBooking(false);
        })
        .catch((error) => {
          if (error?.response) {
            toast.error(error.response?.data?.message || "An error occurred");
          }
        });
    }
  };

  return (
    <>
      <button className="btn btn-sm" style={{ color:"#e1a15c" }} onClick={handleOpenOffCanvas}>
        <i className="fa-solid fa-pen-to-square"></i> Pending
      </button>

      {isOffCanvasOpen && (
        <div
          className={`offcanvas-container ${isOffCanvasOpen ? "open" : ""
            } mt-sm-0 mt-5`}
        >
          <div className="offcanvas-content">
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <h1>
                <strong>Confirm Booking</strong>
              </h1>
              <button className="btn text-xl" onClick={handleCloseOffCanvas}>
                &times;
              </button>
            </div>
            <div>
              <label htmlFor="payment-source" className="form-label">
                Payment Source
              </label>
              <div className="mb-3">
                <select
                  id="payment-source"
                  className="form-select"
                  value={paymentSource}
                  placeholder={paymentSource}
                  onChange={(e) => setPaymentSource(e.target.value)}
                  aria-label="Select Payment Source"
                >
                  <option value="">Select Payment Source</option>
                  <option value="Cash">Cash</option>
                  <option value="DebitCard">DebitCard</option>
                  <option value="PhonePe">PhonePe</option>
                  <option value="GooglePay">GooglePay</option>
                  <option value="Paytm">Paytm</option>
                  <option value="NEFT">NEFT</option>
                  <option value="IMPS">IMPS</option>
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="transaction-id" className="form-label">
                Transaction ID <span className="text-danger">*</span>
              </label>
              <input
                id="transaction-id"
                type="text"
                name="transaction-id"
                className="form-control"
                placeholder="Enter Transaction ID"
                value={paymentTransactionID}
                onChange={(e) => {
                  setTransactionID(e.target.value);
                  setTransactionIDError("");
                }}
              />
              {transactionIDError && (
                <small className="text-danger">{transactionIDError}</small>
              )}
            </div>
            <div className="mb-5">
              <label htmlFor="screenshot">
                <strong style={{ fontSize: "0.85em" }} className="me-1">
                  Upload Screenshot
                </strong>
              </label>
              <input
                type="file"
                id="screenshot"
                className="form-control"
                onChange={handleFileChange}
              />
            </div>

            <div className="d-flex align-items-start justify-content-center flex-column">
              <div className="mb-3">
                <Button
                  type="button"
                  className="btn btn-success"
                  onClick={handleConfirmBooking}
                  disabled={loading}
                >
                  {loading ? "Processing..." : "Confirm"}
                </Button>

                <Button
                  type="button"
                  className="btn btn-danger ms-2"
                  onClick={handleCloseOffCanvas}
                >
                  Cancel
                </Button>
              </div>

              <button
                type="button"
                className="btn btn-outline-danger mt-3"
                onClick={handleCancelShow}
              >
                <i className="fas fa-times-circle me-2"></i> Cancel Booking
              </button>

              <small className="text-muted mt-2 text-center">
                If you want to cancel this booking, click on this button
              </small>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={showCancelBooking}
        onHide={() => setShowCancelBooking(false)}
        centered="true"
        backdrop="static"
        keyboard={false}
        size="sm"
      >
        <Modal.Body className="p-4">
          <p>
            Are you sure to <span className="text-danger">Cancel</span> the
            booking?
          </p>
        </Modal.Body>
        <div className="d-flex align-items-center justify-content-center mb-3">
          <Button
            variant="secondary"
            onClick={() => setShowCancelBooking(false)}
          >
            No
          </Button>
          <Button variant="danger" onClick={handleConfirm}>
            Yes
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmBookingModal;
