import React, { useState, useEffect } from "react";
import { Polar } from "react-chartjs-2";
import { Card, NavItem, NavLink, Nav, Row, Col } from "reactstrap";
import classnames from "classnames";
import { getRoomTypeGraph } from "../../services/DashBoardService";
import "./Dashboard.css";

const vibrantColors = [
  "#6439FF",
  "#4F75FF",
  "#00CCDD",
  "#7CF5FF",
];

const BookingRoomGraph = () => {
  const [dataset, setDataSet] = useState(null);
  const [labels, setLabels] = useState([]);
  const [bgColors, setBgColors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState("weekly");
  const [selectedDatasets, setSelectedDatasets] = useState([]);

  const toggleTimePeriod = (tp) => {
    setTimePeriod(tp);
  };

  useEffect(() => {
    setIsLoading(true);
    getRoomTypeGraph(timePeriod)
      .then((resp) => {
        const { results } = resp.data;
        const result = {
          ...results,
          datasets: [
            {
              ...results.datasets[0],
              backgroundColor: vibrantColors,
              borderColor: "#ffffff",
              borderWidth: 2,
            },
          ],
        };
        setLabels(results.labels);
        setBgColors(vibrantColors);
        setDataSet(result);
        setSelectedDatasets([]); // Reset selection on new data load
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error.response ? error.response.data.message : error);
        setIsLoading(false);
      });
  }, [timePeriod]);

  const handleLegendClick = (index) => {
    setSelectedDatasets((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((i) => i !== index) // Deselect if already selected
        : [...prevSelected, index] // Add to selection if not selected
    );
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      animateScale: true,
      animateRotate: true,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "rgba(0,0,0,0.8)",
        titleFont: { size: 14, weight: "bold" },
        bodyFont: { size: 12 },
        bodyColor: "#fff",
        padding: 10,
      },
    },
  };

  const polarChartData = dataset
    ? {
        ...dataset,
        datasets: [
          {
            ...dataset.datasets[0],
            backgroundColor: dataset.datasets[0].backgroundColor.map((color, index) =>
              selectedDatasets.length === 0 || selectedDatasets.includes(index)
                ? color
                : "rgba(0, 0, 0, 0.1)"
            ),
          },
        ],
      }
    : null;

  return (
    <Card className="shadow-sm p-4" style={{ borderRadius: "12px" }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="mb-0" style={{ color: "#2c3e50" }}>
          <strong>Venue Usage Distribution</strong>
        </h2>
        <Nav pills>
          <NavItem>
            <NavLink
              className={classnames("py-1 px-3", { active: timePeriod === "yearly" })}
              style={{
                backgroundColor: timePeriod === "yearly" ? "#d63031" : "transparent",
                color: "#2c3e50",
                borderRadius: "10px",
                transition: "0.3s ease",
              }}
              onClick={() => toggleTimePeriod("yearly")}
            >
              Y
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("py-1 px-3", { active: timePeriod === "monthly" })}
              style={{
                backgroundColor: timePeriod === "monthly" ? "#6c5ce7" : "transparent",
                color: "#2c3e50",
                borderRadius: "10px",
                transition: "0.3s ease",
              }}
              onClick={() => toggleTimePeriod("monthly")}
            >
              M
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("py-1 px-3", { active: timePeriod === "weekly" })}
              style={{
                backgroundColor: timePeriod === "weekly" ? "#00b894" : "transparent",
                color: "#2c3e50",
                borderRadius: "10px",
                transition: "0.3s ease",
              }}
              onClick={() => toggleTimePeriod("weekly")}
            >
              W
            </NavLink>
          </NavItem>
        </Nav>
      </div>

      <div
        style={{
          height: "1px",
          background: "linear-gradient(90deg, black, white)",
          marginBottom: "15px",
          borderRadius: "2px",
          transition: "width 0.5s ease",
          width: "100%",
        }}
      ></div>

      <div className="mt-2" style={{ height: "350px" }}>
        {isLoading ? (
          <div className="text-center my-5">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : dataset ? (
          <div style={{ height: "350px" }}>
            <Polar data={polarChartData} options={options} />
          </div>
        ) : (
          <div className="text-center py-8">
            <i className="fas fa-search-minus" style={{ fontSize: "1.5rem", opacity: 0.8, color: "#17a2b8" }}></i>
            <h3 style={{ opacity: 0.5 }}>No Booking Occurs</h3>
          </div>
        )}
      </div>

      {/* Custom Legend */}
      {dataset && (
        <div className="custom-legend mt-4">
          <Row>
            {labels.map((label, index) => (
              <Col xs="6" sm="3" key={index} className="mb-3">
                <div
                  className="legend-item d-flex align-items-center"
                  onClick={() => handleLegendClick(index)}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className="legend-color-circle"
                    style={{
                      backgroundColor: vibrantColors[index],
                      opacity: selectedDatasets.includes(index) || selectedDatasets.length === 0 ? 1 : 0.3,
                    }}
                  />
                  <span
                    className="legend-label"
                    style={{
                      textDecoration: selectedDatasets.includes(index) || selectedDatasets.length === 0 ? "none" : "line-through",
                    }}
                  >
                    {label}
                  </span>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </Card>
  );
};

export default BookingRoomGraph;
