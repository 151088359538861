import React, { useState } from "react";
import { Container } from "reactstrap";
import Header from "../../components/Headers/Header.js";
import { Tab, Nav } from "react-bootstrap";
import AllSalesReport from "./AllSalesReport.js";
import DailySalesReport from "./DailyReport.js";
import DiscountReport from "./DiscountReport.js";
import RefundReport from "./RefundReport.js";
import DownloadExcel from "./DownloadReport.js";
import DateRangeDropdown from "../../constant/DateRangeFilter.js";

const Reports = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [activeKey, setActiveKey] = useState("allsalesreport");
  const [filter, setFilter] = useState({
    start_date: "",
    end_date: "",
  });
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  ;

  const handleResetDateFilter = () => {
    setFilter({ start_date: "", end_date: "" });
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
    setSearchQuery("");
    setFilter({ start_date: "", end_date: "" });
    setYear("");
    setMonth("");
  };

  const downloadProps = {
    searchQuery,
    startDate: filter.start_date,
    endDate: filter.end_date,
    year,
    month,
  };

  return (
    <>
      <Header />
      <Container className="Container mt-sm-0 mt-7 mb-sm-0 mb-7 p-sm-2 p-1" fluid>
        <div className="d-flex flex-wrap align-items-center justify-content-between p-2">
          <h2 className="mb-2 text-dark fw-bold pb-2">
            <i className="fa-solid fa-chart-line p-1"></i>
            Reports
          </h2>
          <div className="row align-items-end">
            <div className="col-md-7 mb-3">
              <DateRangeDropdown
                filter={filter}
                setFilter={setFilter}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleResetDateFilter={handleResetDateFilter}
              />
            </div>
            <div className="col-md-5 mb-3">
              <div className="search-bar position-relative">
                <input
                  type="text"
                  className="form-control search-input w-100"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                {!searchQuery && <i className="fas fa-search search-icon"></i>}
                {searchQuery && (
                  <i
                    className="fas fa-times position-absolute"
                    style={{
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      color: "#999999",
                    }}
                    onClick={() => setSearchQuery("")}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <Tab.Container activeKey={activeKey} onSelect={handleTabChange}>
            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="allsalesreport" className="tabnav p-3">
                    <strong className="text-primary text-sm">
                      <i className="fas fa-file-alt mr-2"></i> All Sales Report
                    </strong>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="daywisesalesreport" className="tabnav p-3">
                    <strong className="text-primary text-sm">
                      <i className="fas fa-calendar-check mr-2"></i> Daywise
                      Sales Report
                    </strong>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="discountreport" className="tabnav p-3">
                    <strong className="text-primary text-sm">
                      <i className="fas fa-percent mr-2"></i> Discount Report
                    </strong>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="refundreport" className="tabnav p-3">
                    <strong className="text-primary text-sm">
                      <i className="fas fa-undo-alt mr-2"></i> Refund Report
                    </strong>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div className="d-flex align-items-center justify-content-between px-4" key={activeKey}>

                <DownloadExcel currentPage={activeKey} {...downloadProps} />
              </div>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="allsalesreport">
                {activeKey === "allsalesreport" && (
                  <AllSalesReport
                    searchQuery={searchQuery}
                    year={year}
                    month={month}
                    start_date={filter.start_date}
                    end_date={filter.end_date}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="daywisesalesreport">
                {activeKey === "daywisesalesreport" && (
                  <DailySalesReport
                    searchQuery={searchQuery}
                    year={year}
                    month={month}
                    start_date={filter.start_date}
                    end_date={filter.end_date}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="discountreport">
                {activeKey === "discountreport" && (
                  <DiscountReport
                    searchQuery={searchQuery}
                    year={year}
                    month={month}
                    start_date={filter.start_date}
                    end_date={filter.end_date}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="refundreport">
                {activeKey === "refundreport" && (
                  <RefundReport
                    searchQuery={searchQuery}
                    year={year}
                    month={month}
                    start_date={filter.start_date}
                    end_date={filter.end_date}
                  />
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </>
  );
};

export default Reports;
