import React, { useState, useEffect } from "react";
import TableNoResultFound from "../../constant/NoResultFound/TableNoResultFound.js";
import { Card, CardFooter, Table, Row, Col } from "reactstrap";
import { getRefundReportList } from "../../services/ReportService.js";

const RefundReport = ({ searchQuery, year, month, start_date, end_date }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchRefundReport = async () => {
      setIsLoading(true);
      try {
        const response = await getRefundReportList(searchQuery, year, month, start_date, end_date, false);
        setData(response.data.results);
      } catch (error) {
        console.error("Error fetching refund report:", error.response ? error.response.data.message : error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRefundReport();
  }, [searchQuery, year, month, start_date, end_date]);

  return (
    <div className="px-0" id="table-to-download">
      <Card className="shadow">
        <Table className="table-component__table align-items-center table-flush" responsive>
          <thead className="booking-table-head disable-select">
            <tr className="booking-table-header">
              <th scope="col">Date</th>
              {data.refunds?.[0]?.payments.map((payment, index) => (
                <th key={index} scope="col">{payment.payment_source}</th>
              ))}
              <th scope="col">Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center my-7" colSpan="7">
                  <div style={{ width: "100%", height: "100%" }}>
                    <div className="spinner-grow text-black me-2" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            ) : data.refunds?.length > 0 ? (
              data.refunds.map((item, index) => (
                <tr key={index} className="booking-table-row">
                  <td>{item.date}</td>
                  {data.refunds?.[0]?.payments.map((paymentSource, idx) => {
                    // Find the corresponding payment entry
                    const payment = item.payments.find(p => p.payment_source === paymentSource.payment_source);
                    return (
                      <td key={idx}>
                        {payment ? (
                          <>
                            {payment.total_refund.toLocaleString()} 
                            <span className="text-xs"> ({payment.refund_count})</span>
                          </>
                        ) : (
                          0
                        )}
                      </td>
                    );
                  })}
                  {/* Total Refund Column */}
                  <td>{item.total_refund.toLocaleString()}</td>
                </tr>
              ))
            ) : (
              <TableNoResultFound />
            )}
          </tbody>
        </Table>

        <CardFooter className="py-4">
          <Row>
            <Col><strong>Total Amount</strong></Col>
            <Col>{data.totals?.refund_count || 0}</Col>
            <Col className="text-center">{data.totals?.total_refund?.toLocaleString() || 0}</Col>
          </Row>
        </CardFooter>
      </Card>
    </div>
  );
};

export default RefundReport;
