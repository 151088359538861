import React from "react";
import { BiExport } from "react-icons/bi";
import {
  getAllSalesReportList,
  getDailySalesReportList,
  getDiscountReportList,
  getRefundReportList,
} from "../../services/ReportService";
import useWindowSize from "../../constant/WindowSize";

const DownloadExcel = ({
  currentPage,
  searchQuery,
  startDate,
  endDate,
  year,
  month,
}) => {
  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  const handleDownload = async () => {
    let response;

    switch (currentPage) {
      case "allsalesreport":
        response = await getAllSalesReportList(
          searchQuery,
          year,
          month,
          startDate,
          endDate,
          true,
          {
            responseType: "blob",
          }
        );
        break;
      case "daywisesalesreport":
        response = await getDailySalesReportList(
          1,
          20,
          searchQuery,
          year,
          month,
          startDate,
          endDate,
          true,
          {
            responseType: "blob",
          }
        );
        break;
      case "discountreport":
        response = await getDiscountReportList(
          searchQuery,
          year,
          month,
          startDate,
          endDate,
          true,
          {
            responseType: "blob",
          }
        );
        break;
      case "refundreport":
        response = await getRefundReportList(
          searchQuery,
          year,
          month,
          startDate,
          endDate,
          true,
          {
            responseType: "blob",
          }
        );
        break;
      default:
        return; // No valid report type selected
    }

    // Check for a successful response
    if (response.status === 200) {
      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers["content-disposition"];
      const filename = contentDisposition
        ? contentDisposition.split("filename=")[1]?.replace(/"/g, "") // Parse the filename from the header
        : `${currentPage}.xlsx`; // Fallback filename

      // Create a blob from the response data
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a temporary download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename; // Use the filename from the header or fallback

      // Append the link to the body and trigger a click to download
      document.body.appendChild(a);
      a.click();

      // Clean up by revoking the object URL
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } else {
      // Handle error
      console.error("Download failed:", response.statusText);
    }
  };

  return (
    <div className="dropdown-filter-status d-flex align-items-end ms-3">
      <button
        className="btn text-white btn-success btn-sm d-flex align-items-center cursor-pointer"
        onClick={handleDownload}
      >
        {isMobile ? (
          <BiExport style={{ fontSize: "20px" }} />
        ) : (
          <>
            <BiExport style={{ fontSize: "20px" }} />
            Export
          </>
        )}
      </button>
    </div>
  );
};

export default DownloadExcel;
