import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import DynamicSelectInput from "../../constant/SelectInput";
import { LiaCircleSolid } from "react-icons/lia";
import { format } from "date-fns";
import "./Settings.css";
import { getCouponList, createCoupon, deleteCoupon, updateCoupon } from "../../services/SettingService";
import { FaTrashAlt } from "react-icons/fa";

function Coupon() {
  const couponColors = [
    "gold",
    "silver",
    "#d7c6cf",
    "orange",
    "	#cee9ea",
    "pink",
    "#e6c79c",
    "#6fd08c",
    "#b7d6d5",
  ];

  const [copied, setCopied] = useState({});
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [couponInput, setCouponInput] = useState({
    coupon_code: "",
    discount_type: "",
    discount_value: "",
    valid_from: "",
    valid_to: "",
    coupon_type: "",
    specific_member: "",
    specific_membershiptype: "",
    user_limit: "",
    minimum_spend: "",
    maximum_discount_value: 0,
  });
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleCloseOffCanvas = () => {
    setIsOffCanvasOpen(false);
  };

  const isExpired = (validTo) => {
    const today = new Date();
    const expirationDate = new Date(validTo);
    return expirationDate < today;
  };

  const handleOpenOffCanvas = (couponData = null) => {
    if (couponData) {
      setCouponInput({
        coupon_code: couponData.code,
        discount_type: couponData.discount_type,
        discount_value: couponData.discount_value,
        valid_from: couponData.valid_from,
        valid_to: couponData.valid_to,
        coupon_type: couponData.coupon_type,
        specific_member: couponData.specific_member,
        specific_membershiptype: couponData.specific_membershiptype,
        user_limit: couponData.user_limit,
        minimum_spend: couponData.minimum_spend,
        maximum_discount_value: couponData.maximum_discount_value || 0,
        id: couponData.id,
      });
    } else {
      // Reset the form for adding a new coupon
      setCouponInput({
        coupon_code: "",
        discount_type: "",
        discount_value: "",
        valid_from: "",
        valid_to: "",
        coupon_type: "",
        specific_member: "",
        specific_membershiptype: "",
        user_limit: "",
        minimum_spend: "",
        maximum_discount_value: 0,
        id: null,
      });
    }

    setIsOffCanvasOpen(true);
  };



  useEffect(() => {
    setIsLoading(true);
    getCouponList()
      .then((resp) => {
        const coupons = resp.data.results;
        // Map through each coupon and add a "disabled" property if expired
        const updatedCoupons = coupons.map((coupon) => {
          if (isExpired(coupon.valid_to)) {
            return {
              ...coupon,
              disabled: true, // Mark as disabled instead of deleting
            };
          }
          return coupon;
        });

        setData(updatedCoupons); // Set the updated coupons with disabled status
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.response ? error.response.data.message : error);
        setIsLoading(false);
      });
  }, []);

  const copyToClipboard = (code, index) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopied((prev) => ({ ...prev, [index]: true }));
        setTimeout(() => {
          setCopied((prev) => ({ ...prev, [index]: false }));
        }, 2000);
      })
      .catch((err) => console.error("Failed to copy: ", err));
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setCouponInput({
      ...couponInput,
      valid_from: start ? format(start, "yyyy-MM-dd") : "",
      valid_to: end ? format(end, "yyyy-MM-dd") : "",
    });
  };

  const handleSelectChange = (name, value) => {
    setCouponInput((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setCouponInput((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const validateInputs = () => {
    const validationErrors = {};
    if (!couponInput.coupon_code)
      validationErrors.coupon_code = "Coupon code is required";
    if (!couponInput.discount_type)
      validationErrors.discount_type = "Discount type is required";
    if (!couponInput.discount_value)
      validationErrors.discount_value = "Discount value is required";
    if (!couponInput.valid_from || !couponInput.valid_to)
      validationErrors.valid_from = "Valid date range is required";
    if (!couponInput.coupon_type)
      validationErrors.coupon_type = "Coupon type is required";
    if (!couponInput.minimum_spend)
      validationErrors.minimum_spend = "Minimum spend is required";
    if (couponInput.discount_type === "Percentage" && !couponInput.maximum_discount_value) {
      validationErrors.maximum_discount_value = "Max discount value is required for percentage discount";
    }

    return validationErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setIsSubmitLoading(true);

      let resp;
      if (couponInput.id) {
        // Edit existing coupon
        resp = await updateCoupon(couponInput);  // Call updateCoupon with the data
        toast.success(`Coupon updated successfully!`);
        // Update the existing coupon in the data list
        setData((prevData) =>
          prevData.map((item) =>
            item.id === couponInput.id ? { ...item, ...couponInput } : item
          )
        );
      } else {
        // Create new coupon
        resp = await createCoupon(couponInput);
        toast.success(`Coupon created successfully!`);
        // Add new coupon to the list
        setData([resp.data.results, ...data]);
      }

      // Close the form
      setIsOffCanvasOpen(false);

      // Reset form input after submission
      setCouponInput({
        coupon_code: "",
        discount_type: "",
        discount_value: "",
        valid_from: "",
        valid_to: "",
        coupon_type: "",
        specific_member: "",
        specific_membershiptype: "",
        user_limit: "",
        minimum_spend: "",
        maximum_discount_value: "",
        id: null, // Reset id after submission
      });

    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setIsSubmitLoading(false);
    }
  };


  const confirmDelete = () => {
    if (deletingId !== null) {
      deleteCoupon(deletingId)
        .then(() => {
          const newData = data.filter((_, i) => i !== deleteIndex);
          setData(newData); // Assuming setData is available to update the data state.
          setDeleteIndex(null);
          setDeletingId(null);
        })
        .catch((error) => {
          toast.error("Coupon is active, can't delete");
  
        })
        .finally(() => {
          setShowModal(false);
        });
    }
  };

  // Handle delete click (opens modal)
  const handleDeleteClick = (index, id) => {
    setDeleteIndex(index);
    setDeletingId(id);
    setShowModal(true);
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-between p-2 bg-white">
        <h5 className="heading mb-3 mb-md-0">Coupons</h5>
        <button
          className="btn ms-3 btn-primary"
          onClick={handleOpenOffCanvas}
        >
          Add Coupon
        </button>
      </div>
      <div className="card rounded-0 p-sm-3 p-2">
        <div className="row g-4 mb-4">
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 mb-4 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div
                  className={`coupon ${item.disabled ? "disabled" : ""}`}
                  style={{
                    "--coupon-color": couponColors[index % couponColors.length],
                    position: "relative",
                    opacity: item.disabled ? 0.5 : 1, // Make expired coupons semi-transparent
                  }}
                >
                  {/* Delete Icon */}
                  {!item.disabled && ( // Hide delete icon if disabled
                    <span
                      key={item.id}
                      className="delete-icon text-black"
                      onClick={() => handleDeleteClick(index, item.id)}
                    >
                      <FaTrashAlt />
                    </span>
                  )}

                  {item.status === "Available" && !item.disabled && (
                    <span
                      className="top-right-button"
                      onClick={() => handleOpenOffCanvas(item)}
                    >
                      <LiaCircleSolid />
                    </span>
                  )}

                  <div className="coupon-center">
                    <div>
                      <h2>
                        {item.discount_type === "Flat" && (
                          <span className="me-2 text-sm">
                            {item.discount_type}
                          </span>
                        )}
                        {item.discount_value}
                        {item.discount_type === "Percentage" && <span>%</span>} OFF
                      </h2>
                      <small
                        className="mb-3"
                        style={{
                          fontSize: "0.65rem",
                          color: "black",
                        }}
                      >
                        On Minimum Spend of {item.minimum_spend}
                      </small>
                      <div className="coupon-box">
                        <span className="coupon-code px-3">{item.code}</span>
                        <button
                          className="copy-button"
                          onClick={() => copyToClipboard(item.code, index)}
                          disabled={item.disabled} // Disable copy button if expired
                        >
                          {item.disabled ? "Expired" : copied[index] ? "Copied!" : "Copy"}
                        </button>
                      </div>
                      <small
                        style={{
                          fontSize: "0.55rem",
                          color: "black",
                        }}
                      >
                        Valid till {item.valid_from} to {item.valid_to}
                      </small>
                      {item.disabled && (
                        <div className="expired-overlay">
                          <span className="expired-text">Expired</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              className="card d-flex justify-content-center align-items-center"
              style={{ height: "80vh", width: "100%" }}
            >
              <div className="text-center">
                <i
                  className="fa fa-bed fa-3x mb-2"
                  style={{ fontSize: "2rem", opacity: 0.5 }}
                ></i>
                <h3 style={{ opacity: 0.5 }}>Coupons not available</h3>
              </div>
            </div>
          )}
        </div>


        <Modal show={showModal} size="sm" onHide={() => setShowModal(false)} centered>
          <Modal.Body className="p-3">
            <p className="text-center mb-3">Are you sure you want to delete this coupon?</p>
            <div className="d-flex align-items-center justify-content-center">
              <button
                type="submit"
                className="btn btn-danger btn-sm me-1"
                onClick={confirmDelete} // API called only on this click
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="btn btn-secondary btn-sm mx-2"
              >
                No
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      {isOffCanvasOpen && (
        <div
          className={`offcanvas-container ${isOffCanvasOpen ? "open" : ""
            } mt-sm-0 mt-5`}
        >
          <div className="offcanvas-content">
            <div className="d-flex align-items-center justify-content-between">
              <h1>
                <strong> {couponInput.id ? "Update Coupon" : "Add Coupon"}</strong>
              </h1>
              <button className="btn text-xl" onClick={handleCloseOffCanvas}>
                &times;
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-1">
                <label htmlFor="coupon_code">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Coupon Code
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="coupon_code"
                  className="form-control"
                  placeholder="Enter Coupon Code"
                  value={couponInput.coupon_code}
                  onChange={handleInputChange}
                />
                {errors.coupon_code && (
                  <div className="text-danger text-xs">
                    {errors.coupon_code}
                  </div>
                )}
              </div>
              <div className="mb-1">
                <label htmlFor="discount_type">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Discount Type
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <div className="custom-select-wrapper">
                  <select
                    id="discount_type"
                    className="form-control custom-select"
                    value={couponInput.discount_type}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Discount Type</option>
                    <option value="Flat">Flat</option>
                    <option value="Percentage">Percentage</option>
                  </select>
                </div>
                {errors.discount_type && (
                  <div className="text-danger text-xs">{errors.discount_type}</div>
                )}
              </div>

              <div className="mb-1">
                <label htmlFor="discount_value">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    {
                      couponInput.discount_type === "Percentage"
                        ? "Discount Percentage"
                        : "Discount Value"
                    }
                    {/* Conditionally show the percentage message if the selected discount type is "Percentage" */}
                    {couponInput.discount_type === "Percentage" && (
                      <span style={{ fontSize: "0.75em", marginLeft: "2px", color: "#666" }}>
                        (Enter in Percentage %)
                      </span>
                    )}
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="discount_value"
                  className="form-control"
                  placeholder={
                    couponInput.discount_type === "Percentage"
                      ? "Enter Discount Value (%)"
                      : "Enter Discount Value"
                  }
                  value={couponInput.discount_value}
                  onChange={handleInputChange}
                />
                {errors.discount_value && (
                  <div className="text-danger text-xs">{errors.discount_value}</div>
                )}
              </div>

              {couponInput.discount_type === "Percentage" && (
                <div className="mb-1">
                  <label htmlFor="maximum_discount_value">
                    <strong style={{ fontSize: "0.85em" }} className="me-1">
                      Max Discount Value
                    </strong>
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="maximum_discount_value"
                    className="form-control"
                    placeholder="Enter Max Discount Value"
                    value={couponInput.maximum_discount_value}
                    onChange={handleInputChange}
                  />
                  {errors.maximum_discount_value && (
                    <div className="text-danger text-xs">{errors.maximum_discount_value}</div>
                  )}
                </div>
              )}

              <div className="mb-1">
                <label htmlFor="valid_from">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Valid Date Range
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <Flatpickr
                  id="valid_from"
                  options={{ mode: "range", dateFormat: "Y-m-d" }}
                  onChange={handleDateChange}
                  placeholder="Select date range"
                  value={[couponInput.valid_from, couponInput.valid_to]}
                  className="form-control custom-flatpickr w-full p-2 mx-auto border border-gray-800 bg-transparent rounded-md shadow-sm focus:outline-none focus:ring-2"
                />
                {errors.valid_from && (
                  <div className="text-danger text-xs">{errors.valid_from}</div>
                )}
              </div>
              <div className="mb-1">
                <label htmlFor="coupon_type">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Coupon Type
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <div className="custom-select-wrapper">
                  <select
                    id="coupon_type"
                    className="form-control custom-select"
                    value={couponInput.coupon_type}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Coupon Type</option>
                    <option value="General">General</option>
                    <option value="Specific MembershipType">
                      Specific MembershipType
                    </option>
                    <option value="Specific Member">Specific Member</option>
                  </select>
                </div>
                {errors.coupon_type && (
                  <div className="text-danger text-xs">
                    {errors.coupon_type}
                  </div>
                )}
              </div>
              {couponInput.coupon_type === "Specific Member" && (
                <div className="mb-1">
                  <label htmlFor="specific_member">
                    <strong style={{ fontSize: "0.85em" }} className="me-1">
                      Choose Specific Member
                    </strong>
                    <span className="text-danger">*</span>
                  </label>
                  <DynamicSelectInput
                    parentClassName="mb-0 order"
                    setParentInputValue={handleSelectChange}
                    endpoint_name="onbehalf_member"
                    name={"specific_member"}
                    id={"specific_member"}
                    isClearable="true"
                    is_multi="true"
                    isRequired="true"
                    placeholder={"Select Member"}
                    style={{ padding: "0.13rem" }}
                  />
                </div>
              )}
              {couponInput.coupon_type === "Specific MembershipType" && (
                <div className="mb-1">
                  <label htmlFor="specific_membershiptype">
                    <strong style={{ fontSize: "0.85em" }} className="me-1">
                      Choose MembershipType
                    </strong>
                    <span className="text-danger">*</span>
                  </label>
                  <div className="custom-select-wrapper">
                    <select
                      id="specific_membershiptype"
                      className="form-control custom-select"
                      value={couponInput.specific_membershiptype}
                      onChange={handleInputChange}
                    >
                      <option value="">Choose Membership Type</option>
                      <option value="SAIL RSP Member">SAIL RSP Member</option>
                      <option value="Government Member">
                        Government Member
                      </option>
                      <option value="Private Member">Private Member</option>
                      <option value="Honorary Member">Honorary Member</option>
                    </select>
                  </div>
                  {errors.coupon_type && (
                    <div className="text-danger text-xs">
                      {errors.coupon_type}
                    </div>
                  )}
                </div>
              )}
              {couponInput.coupon_type !== "Specific Member" && (
                <div className="mb-1">
                  <label htmlFor="user_limit">
                    <strong style={{ fontSize: "0.85em" }} className="me-1">
                      User Limit
                    </strong>
                  </label>
                  <input
                    type="number"
                    id="user_limit"
                    className="form-control"
                    value={couponInput.user_limit}
                    onChange={handleInputChange}
                  />
                </div>
              )}

              <div className="mb-4">
                <label htmlFor="minimum_spend">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Minimum Spend
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  id="minimum_spend"
                  className="form-control"
                  value={couponInput.minimum_spend}
                  onChange={handleInputChange}
                />
                {errors.minimum_spend && (
                  <div className="text-danger text-xs">
                    {errors.minimum_spend}
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-center mb-sm-0 mb-6">
                <button type="submit" className="btn bg-black text-white">
                  {couponInput.id ? "Update Coupon" : "Create Coupon"}
                </button>

                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={handleCloseOffCanvas}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Coupon;
