import React, { useState, useEffect } from "react";
import TableNoResultFound from "../../constant/NoResultFound/TableNoResultFound.js";
import { Card, CardFooter, Table, Row, Col } from "reactstrap";
import { getAllSalesReportList } from "../../services/ReportService.js";

const AllSalesReport = ({ searchQuery, year, month, start_date, end_date }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({ payment_source_sales: [], overall_totals: {} });

    useEffect(() => {
        setIsLoading(true);
        getAllSalesReportList(searchQuery, year, month, start_date, end_date,false)
          .then((resp) => {
            setData(resp.data.results);
            setIsLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
            } else {
              console.log(error);
            }
            setIsLoading(false);
          });
      }, [searchQuery, year, month, start_date, end_date]);

    return (
        <div className="px-0" id="table-to-download">
            <Card className="shadow">
                <Table className="table-component__table align-items-center table-flush" responsive>
                    <thead className="booking-table-head disable-select">
                        <tr className="booking-table-header">
                            <th scope="col">Payment Source</th>
                            <th scope="col">Taxable Price</th>
                            <th scope="col">Total GST</th>
                            <th scope="col">Total Discount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td className="text-center my-7" colSpan="4">
                                    <div style={{ width: "100%", height: "100%" }}>
                                        <div className="spinner-grow text-black me-2" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ) : data.payment_source_sales?.length > 0 ? (
                            data.payment_source_sales.map((item, index) => (
                                <tr key={index} className="booking-table-row">
                                    <td>{item?.payment_source}</td>
                                    <td>{item?.total_taxable_value}</td>
                                    <td>{item?.total_gst}</td>
                                    <td>{item?.total_discount}</td>
                                </tr>
                            ))
                        ) : (
                            <TableNoResultFound />
                        )}
                    </tbody>
                </Table>

                <CardFooter className="py-4">
                    <Row>
                        <Col>
                        <strong>Total </strong>
                        </Col>
                        <Col>
                            <strong>Sales: </strong> {data.overall_totals?.total_taxable_value || 0}
                        </Col>
                        <Col>
                            <strong>GST: </strong> {data.overall_totals?.total_gst || 0}
                        </Col>
                        <Col>
                            <strong>Discount: </strong> {data.overall_totals?.total_discount || 0}
                        </Col>
                    </Row>
                </CardFooter>
            </Card>
        </div>
    );
};

export default AllSalesReport;
