import React, { useEffect } from "react";
import Hero from "../Component/Hero/Hero";
import NatureVid from "../assets/video/main.mp4";
import Testimonial from "../Component/Testimonial/Testimonial";
import Banner from "../Component/Banner/Banner";
import BannerPic from "../Component/BannerPic/BannerPic";
import BannerImg from "../assets/RC-Lawn-01.gif";
import Banner2 from "../assets/RC-Pool-03.gif";
import Rooms from "../Component/Rooms/Rooms";
import Events from "../Component/Events/Events";

const Home = () => {
  const [orderPopup, setOrderPopup] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOrderPopup = () => {
    setOrderPopup(!orderPopup);
  };

  return (
    <div>
      {/* Hero Section with Video Background */}
      <div className="relative h-[700px] overflow-hidden">
        <video
          autoPlay
          loop
          muted
          className="absolute inset-0 h-full w-full object-cover z-[-1]"
        >
          <source src={NatureVid} type="video/mp4" />
        </video>
        <Hero />
      </div>

      {/* Rooms Section */}
        <Rooms handleOrderPopup={handleOrderPopup} />

      {/* First Banner Section */}
        <BannerPic img={BannerImg} />

      {/* Events Section */}
        <Events />

      {/* Second Banner Section */}
        <Banner />

      {/* Second Banner Picture */}
        <BannerPic img={Banner2} />

      {/* Testimonials Section */}
        <Testimonial />
    </div>
  );
};

export default Home;
