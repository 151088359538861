import React, { useState } from "react";
import { toast } from "react-toastify";
import TermsOfServiceModal from "./TOSModal"
import { Link, useNavigate } from "react-router-dom";
import { signupApi } from "../../services/AuthService";
import useWindowSize from "../../constant/WindowSize";
import Logo from "../../assets/img/brand/logo.png"
import "./Login.css";

const INPUT_FIELD_LIST = [
  {
    label: "First Name",
    name: "first_name",
    type: "text",
    placeholder: "Enter your first name",
  },
  {
    label: "Last Name",
    name: "last_name",
    type: "text",
    placeholder: "Enter your last name",
  },
  {
    label: "Membership Type",
    name: "membership_type",
    type: "select",
    placeholder: "Select membership type",
    options: [
      { label: "SAIL RSP Member", value: "SAIL RSP Member" },
      { label: "GOVERNMENT Member", value: "GOVERNMENT Member" },
      { label: "PRIVATE Member", value: "PRIVATE Member" },
      { label: "Honorary Member", value: "Honorary Member" },
    ],
  },
  {
    label: "Email ID",
    name: "email",
    type: "email",
    placeholder: "Enter your Email ID",
  },
  {
    label: "Phone Number",
    name: "phone_number",
    type: "tel",
    placeholder: "Enter your 10-digit Phone No.",
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    placeholder: "Enter your password",
  },
  {
    label: "Confirm Password",
    name: "confirm_password",
    type: "password",
    placeholder: "Confirm your password",
  },
];


const initialFormState = {
  first_name: "",
  last_name: "",
  membership_type: "",
  email: "",
  phone_number: "",
  password: "",
  confirm_password: "",
  personnel_number: "",
};


function Register() {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialFormState);
  const navigate = useNavigate();
  const size = useWindowSize();
  const isMobile = size.width <= 768;
  const [showModal, setShowModal] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleValidate = (name, value) => {
    if (name === "email") {
      if (!value) {
        return "Email is required";
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        return "Invalid Email format.";
      }
    } else if (name === "phone_number") {
      if (!value) {
        return "Phone number is required";
      } else if (!/^\d{10}$/.test(value)) {
        return "Invalid phone number. It should be 10 digits.";
      }
    } else if (name === "password") {
      if (!value) {
        return "Password is required";
      } else if (!/(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}/.test(value)) {
        return "Password must be at least 8 characters long, include one uppercase, one lowercase, one number, and one special character.";
      }
    } else if (name === "confirm_password") {
      if (value !== formData.password) {
        return "Passwords do not match.";
      }
    } else if (name === "personnel_number" && formData.membership_type === "SAIL RSP Member") {
      if (!/^\d{6}$/.test(value)) {
        return "Personnel Number must be a 6-digit number.";
      }
    } else {
      if (!value) {
        const errorMsgObj = {
          first_name: "First name",
          last_name: "Last name",
          membership_type: "Membership type",
        };
        return `${errorMsgObj[name]} is required`;
      }
    }
    return "";
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: handleValidate(name, value) });
  };

  const handleCheckboxChange = (e) => {
    setHasAgreed(e.target.checked);
    setSubmitAttempted(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitAttempted(true);

    if (isSubmitLoading) return;

    let formErrors = {};
    let isValid = true;

    INPUT_FIELD_LIST.forEach(({ name }) => {
      const error = handleValidate(name, formData[name]);
      formErrors[name] = error;
      if (error) isValid = false;
    });

    if (!hasAgreed) {
      formErrors.hasAgreed = "You must agree to the terms and conditions";
      isValid = false;
    }

    if (!isValid) {
      setErrors(formErrors);
      return;
    }

    setIsSubmitLoading(true);
    signupApi(formData)
      .then((resp) => {
        const { message } = resp.data;
        toast.success(message);
        setFormData(initialFormState); // Reset form
        setHasAgreed(false); // Reset checkbox
        navigate("/login");
      })
      .catch((error) => {
        if (error.response) toast.error(error.response.data.message);
        console.error(error);
      })
      .finally(() => setIsSubmitLoading(false));
  };

  return (
    <>
      {isMobile ? (
        <div className="mobile-login-signup">
          <div className="background"></div>
          <div
            className="content"
            style={{ width: "95%" }}
          >
            <div>
              {" "}
              <Link to="/" className="text-white">
                <i className="fa-solid fa-house ms-1"></i>
              </Link>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={Logo}
                alt="Logo"
                className="logo"
                style={{
                  width: "70px",
                  height: "70px",
                  boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                  borderRadius: "50%",
                }}
              />
            </div>
            <div className="club-title-container d-flex align-items-center justify-content-center">
              <h1
                className="club-title"
                style={{
                  fontFamily: "'Raleway', sans-serif",
                  fontSize: "2em",
                  color: "white",
                  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                  margin: "0",
                }}
              >
                Rourkela Club
              </h1>
            </div>
            <small
              className="mb-3 d-flex justify-content-center"
              style={{ fontSize: "0.8em", color: "white" }}
            >
              Sign Up with credentials
            </small>

            <div className="formTitle">
              <Link to="/login" className="formTitleLink">
                Sign In
              </Link>
              <span className="me-3">or</span>
              <Link to="/signup" className="formTitleLink-active">
                Sign Up
              </Link>
            </div>

            <div className="formCenter">
              <form className="formFields" onSubmit={handleSubmit}>
                {INPUT_FIELD_LIST.map(({ label, name, type, placeholder, options }) => (
                  <div className="formField row mb-3" key={name}>
                    <label className="formFieldLabel col-4" htmlFor={name}>
                      {label}:
                    </label>

                    <div className="signUpInput-group col-8">
                      {type === "select" ? (
                        <select
                          name={name}
                          value={formData[name]}
                          className="formFieldInput"
                          onChange={handleChange}
                        >
                          <option value="">-- {placeholder} --</option>
                          {options.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type={type}
                          name={name}
                          value={formData[name]}
                          className="formFieldInput"
                          onChange={handleChange}
                          placeholder={placeholder}
                        />
                      )}
                      {errors[name] && <div className="form-error">{errors[name]}</div>}
                    </div>

                    {/* Conditionally render Personnel Number field if 'SAIL RSP Member' is selected */}
                    {name === "membership_type" && formData.membership_type === "SAIL RSP Member" && (
                      <div className="formField row mt-3 ">
                        <label className="formFieldLabel ml-2 col-3" htmlFor="personnel_number">
                          Personnel Number:
                        </label>
                        <div className="signUpInput-group pl-11 pt-2 col-8">
                          <input
                            type="text"
                            name="personnel_number"
                            value={formData.personnel_number}
                            className="formFieldInput"
                            onChange={handleChange}
                            placeholder="Enter Personnel No."
                          />
                          {errors.personnel_number && <div className="form-error">{errors.personnel_number}</div>}
                        </div>
                      </div>
                    )}
                  </div>
                ))}

                {/* Terms and Conditions */}
                <div>
                  <label className="formFieldCheckboxLabel mt-2">
                    <input
                      className="formFieldCheckbox"
                      type="checkbox"
                      name="hasAgreed"
                      checked={hasAgreed}
                      onChange={handleCheckboxChange}
                    />
                    <span className="text-white">I agree to all{" "}</span>
                    <a href="#" className="formFieldTermsLink" onClick={handleShow}>
                      terms of service
                    </a>
                  </label>
                  {submitAttempted && !hasAgreed && <div className="form-error">Please accept the terms and conditions</div>}
                  <TermsOfServiceModal show={showModal} handleClose={handleClose} />
                </div>

                <button className="formFieldButton w-100 mx-1 mb-3">Sign Up</button>
                <Link to="/login" className="d-flex justify-content-center align-items-center" style={{ color: "#ffffffcf" }}>
                  <span style={{ borderBottom: "1.5px solid #6b5b95" }}>I'm already a member</span>
                </Link>
              </form>

            </div>
          </div>
        </div>
      ) : (
        <div className="login-signup row">
          <div className="col-lg-7 bg-image"></div>

          <div className="col-lg-5 appForm d-flex flex-column align-items-center justify-content-center">
            <img
              src={Logo}
              alt="Logo"
              className="logo"
              style={{
                width: "70px",
                height: "70px",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                borderRadius: "50%",
              }}
            />

            <h1
              className="club-title"
              style={{
                fontFamily: "'Raleway', sans-serif",
                fontSize: "2em",
                color: "white",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
                margin: "0",
              }}
            >
              Rourkela Club
            </h1>

            <small style={{ fontSize: "0.8em", color: "white", marginBottom: "15px" }}>
              Sign Up with credentials
            </small>

            <div className="formTitle">
              <Link to="/login" className="formTitleLink">
                Sign In
              </Link>
              <span className="me-3">or</span>
              <Link to="/signup" className="formTitleLink-active">
                Sign Up
              </Link>
            </div>

            <div className="formCenter">
              <form className="formFields" onSubmit={handleSubmit}>
                {INPUT_FIELD_LIST.map(({ label, name, type, placeholder, options }) => (
                  <div className="formField row mb-3" key={name}>
                    <label className="formFieldLabel col-lg-4 d-flex align-items-center" htmlFor={name}>
                      {label}:
                      {name === "phone_number" && (
                        <div className="input-group-text mx-6 py-2" style={{color : "black", padding: "0.375rem 0.75rem", borderRadius: "4px", backgroundColor: "#e9ecef", border: "1px solid #ced4da" }}>
                          +91
                        </div>
                      )}
                    </label>

                    <div className="signUpInput-group col-lg-8">
                      {type === "select" ? (
                        <select
                          name={name}
                          value={formData[name]}
                          className="formFieldInput"
                          onChange={handleChange}
                        >
                          <option value="">-- {placeholder} --</option>
                          {options.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type={type}
                          name={name}
                          value={formData[name]}
                          className="formFieldInput"
                          onChange={handleChange}
                          placeholder={placeholder}
                          autoComplete={type === "password" ? "new-password" : "off"}
                        />
                      )}
                      {errors[name] && <div className="form-error">{errors[name]}</div>}
                    </div>

                    {name === "membership_type" && formData.membership_type === "SAIL RSP Member" && (
                      <>
                        <label className="formFieldLabel col-4 mt-3" htmlFor="personnel_number">
                          Personnel Number:
                        </label>

                        <div className="signUpInput-group col-8">
                          <input
                            type="text"
                            name="personnel_number"
                            value={formData.personnel_number}
                            className="formFieldInput"
                            onChange={handleChange}
                            placeholder="Enter 6-digit Personnel Number"
                          />
                          {errors.personnel_number && (
                            <div className="form-error">{errors.personnel_number}</div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ))}

                <label className="formFieldCheckboxLabel mt-2">
                  <input
                    className="formFieldCheckbox"
                    type="checkbox"
                    name="hasAgreed"
                    checked={hasAgreed}
                    onChange={handleCheckboxChange}
                  />
                  <span className="text-white">I agree to all{" "}</span>
                  <a href="#" className="formFieldTermsLink" onClick={handleShow}>
                    terms of service
                  </a>
                </label>
                {submitAttempted && !hasAgreed && (
                  <div className="form-error" style={{ padding:"0rem 3rem" }}>Please accept the terms and conditions</div>
                )}
                <TermsOfServiceModal show={showModal} handleClose={handleClose} />
                <div className="container d-flex align-items-center justify-content-center">
                  <button className="formFieldButton me-3" type="submit">
                    Sign Up
                  </button>
                  <Link to="/login" className="formFieldLink" style={{ color: "#ffffffcf" }}>
                    I'm already a member
                  </Link>
                </div>
              </form>
            </div>




            <Link to="/" className="d-flex justify-content-center">
              Back to Home <i className="fa-solid fa-house ms-2 my-1"></i>
            </Link>
          </div>
        </div>

      )}
    </>
  );
}

export default Register;
