import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { addDays, format } from "date-fns";
import useWindowSize from "../../../../constant/WindowSize";

const Hero = () => {
  const [dates, setDates] = useState([new Date(), addDays(new Date(), 7)]);
  const [roomType, setRoomType] = useState("All");
  const size = useWindowSize();
  const isMobile = size.width <= 768;
  const navigate = useNavigate();

  const handleDateChange = (selectedDates) => {
    setDates(selectedDates);
  };

  const handleSearch = () => {
    const startDate = format(dates[0], "yyyy-MM-dd");
    const endDate = format(dates[1], "yyyy-MM-dd");
    navigate("/filter", {
      state: { searchQuery: "", roomType, startDate, endDate, slotType: "" },
    });
  };

  return (
    <div className="hero bg-black/20 h-full">
      <style jsx>{`
        .custom-select-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .custom-select-label {
          margin-bottom: 0.5rem;
          opacity: 0.7;
        }
        .custom-select-wrapper {
          display: flex;
          align-items: center;
          width: 100%;
          border-radius: 0.375rem;
        }
        .custom-select {
          appearance: none;
          background: url("data:image/svg+xml;charset=US-ASCII,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='%23999' d='M2 5L0 3h4z'/></svg>")
            no-repeat right 1rem center;
          background-size: 12px 15px;
          padding-right: 2rem;
          width: 100%;
          color: black;
          padding: 0.5rem 1rem;
          border: 1px solid #ccc;
          border-radius: 0.375rem;
          transition: border-color 0.3s, box-shadow 0.3s;
        }
        .custom-select:focus {
          border-color: #000000;
        }
        .custom-flatpickr {
          appearance: none;
          background: url("data:image/svg+xml;charset=US-ASCII,<svg xmlns='http://www.w3.org/2000/svg' fill='%23999' viewBox='0 0 24 24'><path d='M19 3h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H5V9h14v12zm0-14H5V5h14v2z'/></svg>")
            no-repeat right 1rem center;
          background-size: 15px 15px;
          padding-right: 3rem;
          width: 100%;
          color: black;
          padding: 0.5rem 1rem;
          border: 1px solid #ccc;
          border-radius: 0.375rem;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          transition: border-color 0.3s, box-shadow 0.3s;
        }
        .custom-flatpickr:focus {
          border-color: #007bff;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      `}</style>
      <div className="h-full flex justify-center items-center p-2 bg-black/10">
        <div className="container grid grid-cols-1 gap-4">
          <div className="text-white text-center">
          
            <p
              data-aos="fade-up"
              data-aos-delay="300"
              className="font-bold text-3xl"
            >
              Search Room
            </p>
          </div>
          <div
            data-aos="fade-up"
            data-aos-delay="600"
            className="search-container space-y-4 bg-white rounded-md py-4 px-4 relative mx-auto w-full sm:w-auto"
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 py-2">
              <div className="custom-select-container">
                <label htmlFor="roomType" className="custom-select-label">
                  Room Type
                </label>

                <div className="custom-select-wrapper">
                  <select
                    value={roomType}
                    onChange={(e) => setRoomType(e.target.value)}
                    className="custom-select"
                  >
                    <option value="">All</option>
                    <option value="Room">Room</option>
                    <option value="Hall">Hall</option>
                    <option value="Mandap">Mandap</option>
                    <option value="Lounge">Lounge</option>
                  </select>
                </div>
              </div>
              <div className="custom-select-container">
                <label htmlFor="date" className="custom-select-label">
                  Date
                </label>
                <div className="custom-select-wrapper border border-black">
                  <Flatpickr
                    value={dates}
                    onChange={handleDateChange}
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                      minDate: "today",
                    }}
                    className="custom-flatpickr form-control bg-transparent w-full px-5 py-2 mx-auto rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-yellow focus:border-primary"
                    placeholder="Select date range"
                  />
                </div>
              </div>
            </div>
            {isMobile ? (
              <button
                className="search-button bg-black text-white hover:scale-105 px-2 py-1 rounded-full duration-200 absolute -bottom-5 left-1/2 transform -translate-x-1/2 w-8/12 text-base"
                onClick={handleSearch}
              >
                Search Now
              </button>
            ) : (
              <button
                className="search-button bg-black text-white hover:scale-105 px-4 py-2 rounded-full duration-200 absolute -bottom-5 left-1/2 transform -translate-x-1/2 w-10/12 sm:w-auto text-base"
                onClick={handleSearch}
              >
                Search Now
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
