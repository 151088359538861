import React from "react";
import AllRooms from "../Component/Rooms/AllRooms";


const MandapRooms = () => {

  return (
    <div className="min-h-screen pt-14 bg-gray-100">
      <AllRooms />
    </div>
  );
};

export default MandapRooms;


