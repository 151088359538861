import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal, Form, Button } from "react-bootstrap";
import { updateUserProfilePassword } from "../../services/ProfileService";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing eye icons

function ChangePassword() {
  const initialValues = {
    current_password: "",
    new_password: "",
    confirmPassword: "",
  };

  const [showPassword, setShowPassword] = useState({
    cPass: false,
    newPass: false,
    newCPass: false,
  });

  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(initialValues);
  const navigate = useNavigate();

  const handleTogglePassword = (name) => {
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!values.current_password) {
      validationErrors.current_password = "Current password is required";
    }
    if (!values.new_password) {
      validationErrors.new_password = "New password is required";
    }
    if (values.new_password !== values.confirmPassword) {
      validationErrors.confirmPassword = "Passwords do not match";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const resp = await updateUserProfilePassword(values);
      toast.success("Password successfully changed!");
      navigate("/");
      toggleModal(false);
      setValues(initialValues);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error updating password");
    }
  };

  const toggleModal = (show) => {
    if (!show) {
      setValues(initialValues);
    }
    setShowModal(show);
  };

  return (
    <>
      <button
        className="change-password-btn btn btn-sm btn-danger"
        onClick={() => toggleModal(true)}
      >
        Change Password
      </button>
      <Modal
        id="changePasswordModal"
        show={showModal}
        centered
        onHide={() => toggleModal(false)}
      >
        <Modal.Header className="bg-black py-3 d-flex align-items-center">
          <Modal.Title className="text-white">Change Password</Modal.Title>
          <span onClick={() => toggleModal(false)} style={{ cursor: "pointer" }}>
            <i className="fa-solid fa-xmark text-lg text-white"></i>
          </span>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form onSubmit={handleSubmit}>
            {["current_password", "new_password", "confirmPassword"].map(
              (field, index) => (
                <Form.Group className="mb-3" key={index}>
                  <Form.Label htmlFor={field}>
                    {field.replace("_", " ").replace(/\b\w/g, (l) => l.toUpperCase())}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="input-group">
                    <Form.Control
                      type={showPassword[field === "current_password"
                        ? "cPass"
                        : field === "new_password"
                        ? "newPass"
                        : "newCPass"
                      ]
                        ? "text"
                        : "password"}
                      id={field}
                      placeholder={`Enter ${field.replace("_", " ")}`}
                      name={field}
                      autoComplete="new-password"
                      value={values[field]}
                      onChange={handleChange}
                      className="form-control"
                    />
                    <span
                      className="input-group-text"
                      onClick={() =>
                        handleTogglePassword(
                          field === "current_password"
                            ? "cPass"
                            : field === "new_password"
                            ? "newPass"
                            : "newCPass"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword[
                        field === "current_password"
                          ? "cPass"
                          : field === "new_password"
                          ? "newPass"
                          : "newCPass"
                      ] ? (
                        <FaEyeSlash />
                      ) : (
                        <FaEye />
                      )}
                    </span>
                  </div>
                  {errors[field] && (
                    <div className="text-danger small mt-1">
                      {errors[field]}
                    </div>
                  )}
                </Form.Group>
              )
            )}

            <div className="d-flex justify-content-center mt-2">
              <Button
                type="button"
                className="btn btn-secondary me-2"
                onClick={() => toggleModal(false)}
              >
                Cancel
              </Button>
              <Button type="submit" className="btn btn-primary">
                Update
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChangePassword;
