import React from 'react';

const PriceTable = ({ room, selectedCoupon, price }) => {
  let discountAmount = 0;

  if (selectedCoupon) {
    if (selectedCoupon.discount_type === "Flat") {
      discountAmount = Math.ceil(selectedCoupon.discount_value);
    } else if (selectedCoupon.discount_type === "Percentage") {
      discountAmount = Math.ceil((price * selectedCoupon.discount_value) / 100);

      if (selectedCoupon.maximum_discount_value && discountAmount > selectedCoupon.maximum_discount_value) {
        discountAmount = selectedCoupon.maximum_discount_value;
      }
    }
  }

  const totalAfterDiscount = Math.ceil(price - discountAmount);
  const netPayableTotal = Math.ceil(totalAfterDiscount * 1.18);

  return (
    <div className="card">
      <div className="table-responsive">
        <table className="table table-hover payment-summary-table">
          <thead className="table-header">
            <tr>
              <th>Room</th>
              <th className="text-center">Price</th>
              <th className="text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="col-md-9 text-center">
                <em>{room?.name}</em>
              </td>
              <td className="col-md-1 text-center">
                ₹{Math.ceil(price)}
              </td>
              <td className="col-md-1 text-center">
                ₹{Math.ceil(price)}
              </td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right">
                <p>
                  <strong>Subtotal: </strong>
                </p>
                {selectedCoupon && (
                  <p>
                    <strong>Coupon Discount: </strong>
                  </p>
                )}
                <p>
                  <strong>GST (18%): </strong>
                </p>
              </td>
              <td className="text-center">
                <p>
                  <strong>₹{Math.ceil(price)}</strong>
                </p>
                {selectedCoupon && (
                  <p>
                    <strong>- ₹{discountAmount}</strong>
                  </p>
                )}
                <p>
                  <strong>
                    + ₹{selectedCoupon
                      ? Math.ceil((price - discountAmount) * 0.18)
                      : Math.ceil(price * 0.18)}                 
                  </strong>
                </p>
              </td>
            </tr>
            <tr>
              <td>
                {selectedCoupon && (
                  <>
                    <p className="coupon-applied-text" style={{ fontSize: "0.5rem" }}>
                      Coupon Applied
                    </p>
                    <p className="coupon-code" style={{ fontSize: "0.5rem" }}>
                      <em>{selectedCoupon.code}</em>
                    </p>
                  </>
                )}
              </td>
              <td className="text-right">
                <p>
                  <strong>Total Amount: </strong>
                </p>
              </td>
              <td className="text-center">
                <p>
                  <strong>₹{netPayableTotal}</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right total-payable-label">
                <h4>
                  <strong>Net Payable Total: </strong>
                </h4>
              </td>
              <td className="text-center text-danger total-payable-amount">
                <h4>
                  <strong>₹{netPayableTotal}</strong>
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PriceTable;
