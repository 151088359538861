import React, { useState, useEffect } from "react";
import TableNoResultFound from "../../constant/NoResultFound/TableNoResultFound.js";
import Pagination from "../../constant/Pagination.jsx";
import { Card, CardFooter, Table } from "reactstrap";
import { getDiscountReportList } from "../../services/ReportService.js";
import DiscountDetailPopup from "./DiscountDetailPopup";

const recordsPage = 20;

const DiscountReport = ({ searchQuery, year, month, start_date, end_date }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    getDiscountReportList(searchQuery, year, month, start_date, end_date, false)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [searchQuery, year, month, start_date, end_date]);

  const handleBookingCountClick = (date) => {
    setSelectedDate(date);
    setIsPopupOpen(true);
  };

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  return (
    <div className="px-0" id="table-to-download">
      <Card className="shadow">
        <Table className="table-component__table align-items-center table-flush" responsive>
          <thead className="booking-table-head disable-select">
            <tr className="booking-table-header">
              <th scope="col">Date</th>
              <th scope="col">Booking Count</th>
              <th scope="col">Total Discount</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center my-7" colSpan="4">
                  <div style={{ width: "100%", height: "100%" }}>
                    <div className="spinner-grow text-black me-2" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            ) : data.discounts?.length > 0 ? (
              data.discounts.map((item, index) => (
                <tr key={index} className="booking-table-row">
                  <td>{item?.date}</td>
                  <td>
                    <span onClick={() => handleBookingCountClick(item.date)} style={{ cursor: 'pointer', color: 'blue' }}>
                      {item?.booking_count}
                    </span>
                  </td>
                  <td>{item?.total_discount}</td>
                </tr>
              ))
            ) : (
              <TableNoResultFound />
            )}
          </tbody>
        </Table>
        <CardFooter className="py-4">
          <Pagination
            currentPage={currentPage}
            recordsPage={recordsPage}
            dataLength={total}
            handlePagination={handlePagination}
          />
        </CardFooter>
      </Card>
      <DiscountDetailPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        date={selectedDate}
      />
    </div>
  );
};

export default DiscountReport;
