import React, { useState, useEffect } from 'react';
import { getDetailDiscountReport } from "../../services/ReportService.js"; // Import the API function
import './DiscountDetailPopup.css'; // Import your new CSS file

function DiscountDetailPopup({ isOpen, onClose, date }) {
    const [transactions, setTransactions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!isOpen || !date) return; // Don't fetch if the popup is not open or date is not available

        setIsLoading(true);
        getDetailDiscountReport(date)
            .then((resp) => {
                setTransactions(resp.data.transactions);
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data.message);
                } else {
                    console.log(error);
                }
                setIsLoading(false);
            });
    }, [isOpen, date]);

    if (!isOpen) return null;

    return (
        <div className="discount-popup-overlay">
            <div className="discount-popup-content">
                <h2 className="discount-popup-header">Discount for {date}</h2>
                <button onClick={onClose} className="discount-popup-close-button">✖</button>
                
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <table className="discount-popup-table">
                        <thead>
                            <tr>
                                <th className="discount-popup-th">Name</th>
                               
                                <th className="discount-popup-th">Room</th>
                                <th className="discount-popup-th">Coupon Code</th>
                                <th className="discount-popup-th">Discount Amount</th>
                                <th className="discount-popup-th">Net Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((transaction, index) => (
                                <tr key={index}>
                                    <td className="discount-popup-td">{transaction.user.member_name}</td>
                                    
                                    <td className="discount-popup-td">{transaction.room}</td>
                                    <td className="discount-popup-td">{transaction?.coupon || "NA"}</td>
                                    <td className="discount-popup-td">{transaction.discount_amount}</td>
                                    <td className="discount-popup-td">{transaction.net_amount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

export default DiscountDetailPopup;
