import React from "react";
import Slider from "react-slick";
import team1 from "../../assets/Aniket.gif"
import team2 from "../../assets/K-K-Verma.gif"
import team3 from "../../assets/M-Sharma.gif"
import team4 from "../../assets/Manas-01.gif"
import team5 from "../../assets/Sampad-01.gif"

const testimonialData = [
  {
    id: 1,
    name: "Aniket",
    text: "Joining the Rourkela Club has been an incredible experience! The community is welcoming and the activities are engaging.",
    img: team1,
  },
  {
    id: 2,
    name: "K.K. Verma",
    text: "I love the events organized by the club! They are always well-planned and fun, offering something for everyone.",
    img: team2,
  },
  {
    id: 3,
    name: "M. Sharma",
    text: "The friendships I have formed at the Rourkela Club are priceless. It's a fantastic place to meet new people.",
    img: team3,
  },
  {
    id: 4,
    name: "Manas",
    text: "I appreciate the variety of activities offered. Whether it's a party or a quiet gathering, there's always something to do!",
    img: team4,
  },
  {
    id: 5,
    name: "Sampad",
    text: "The club is a home away from home for me. I highly recommend it to anyone looking to expand their social circle.",
    img: team5,
  },
];

const Testimonial = () => {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
      {
        breakpoint: 10000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div data-aos="fade-up" data-aos-duration="300" className="py-2 my-3">
        <div className="container">
          {/* Header section */}
          <div className="text-center mb-15 max-w-[400px] mx-auto">
            <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
              Teams
            </p>
            <h1 className="text-3xl font-bold">Team Members</h1>
            <p className="text-xs text-gray-400">
              {" "}
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vero
              nesciunt explicabo a! Laborum delectus aliquam labore, earum rerum
              quam! Nulla?
            </p>
          </div>
          {/* testimonial section */}
          <div
            data-aos="zoom-in"
            data-aos-duration="300"
            className="grid grid-cols-1 max-w-[800px] mx-auto gap-6"
          >
            <Slider {...settings}>
              {testimonialData.map(({ id, name, text, img }) => {
                return (
                  <div key={id} className="my-3">
                    <div className="flex flex-col justify-center items-center gap-4 text-center p-4 mx-2 rounded-xl dark:bg-gray-800 bg-black/10 relative">
                      <img
                        src={img}
                        style={{ height:"80%", width:"60%" }}
                        alt=""
                        className="rounded-full block mx-auto"
                      />
                      <h1 className="text-xl font-bold">{name}</h1>
                      <p className="text-gray-500 text-sm">{text}</p>
                      <p className="text-black/20 text-9xl font-serif absolute top-0 right-0">
                        ,,
                      </p>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;