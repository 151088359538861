import React, { useState, useEffect, useRef } from "react";
import { Container, Spinner, Button } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { createRoom, getPurpose } from "../../services/RoomService.js";
import Header from "../../components/Headers/Header.js";
import { FaMinus, FaPlus, FaTrash } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { StaticSelectInput } from "../../constant/SelectInput.js";

const AddRoom = () => {
  const [photos, setPhotos] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [purposeData, setPurposeData] = useState(null);
  const [roomInput, setRoomInput] = useState({
    name: "",
    room_type: "Room",
    room_slot_type: "Time Slot",
    booking_purpose: [{ purpose_id: "", price_id: "" }],
    description: "",
  });
  const [errors, setErrors] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [carouselKey, setCarouselKey] = useState(0);
  const [fileName, setFileName] = useState("No file chosen...");
  const [isActive, setIsActive] = useState(false);
  const [selectedPurposes, setSelectedPurposes] = useState(new Set());
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPurpose = async () => {
      try {
        const resp = await getPurpose();
        setPurposeData(resp.data.results);
      } catch (error) {
        console.error("Error fetching purposes:", error);
      }
    };

    fetchPurpose();
  }, []);

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    if (name === "room_type") {
      const roomSlotType = value === "Mandap" ? "Full Day" : "Time Slot";
      setRoomInput({
        ...roomInput,
        [name]: value,
        room_slot_type: roomSlotType,
      });
    } else {
      setRoomInput({ ...roomInput, [name]: value });
    }
  };

  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files);
    setErrors({ ...errors, photos: "" });
    const validFile = files.filter((file) => file.size > 0);
    setPhotos((prevPhotos) => [...prevPhotos, ...validFile]);
    setFileName(files.map((file) => file.name).join(", "));
    setCarouselKey((prevKey) => prevKey + 1);
    setActiveIndex(0);
  };

  const handleDeletePhoto = (index) => {
    const newPhotos = [...photos];
    newPhotos.splice(index, 1);
    setPhotos(newPhotos);
    setCarouselKey((prevKey) => prevKey + 1);
    setActiveIndex(Math.max(0, activeIndex - 1));
  };

  const validateInputs = () => {
    let validationErrors = {};
    if (!roomInput.name) validationErrors.name = "Room Name is required";
    roomInput.booking_purpose.forEach((purpose, index) => {
      if (!purpose.purpose_id) {
        validationErrors[`purpose_id_${index}`] = "Purpose is required";
      }
      if (!purpose.price_id) {
        validationErrors[`price_${index}`] = "Price is required";
      }
    });
    if (roomInput.booking_purpose.length === 0)
      validationErrors.booking_purpose =
        "At least one booking purpose is required";
    if (!roomInput.description)
      validationErrors.description = "Description is required";
    if (photos.length === 0)
      validationErrors.photos = "At least one photo is required";
    return validationErrors;
  };

  const handleAddPurpose = () => {
    const updatedPurposes = [...roomInput.booking_purpose];
    const lastPurpose = updatedPurposes[updatedPurposes.length - 1];
    if (!lastPurpose.purpose_id && !lastPurpose.price_id) {
      setErrors({
        ...errors,
        booking_purpose:
          "Both purpose and price are required before adding another.",
      });
      return;
    }
    setErrors({ ...errors, booking_purpose: "" });
    updatedPurposes.push({ purpose_id: "", price_id: "" });
    setRoomInput({ ...roomInput, booking_purpose: updatedPurposes });
  };

  const handleRemovePurpose = (index) => {
    if (roomInput.booking_purpose.length > 1) {
      const updatedPurposes = [...roomInput.booking_purpose];
      const removedPurposeId = updatedPurposes[index]?.purpose_id;
      updatedPurposes.splice(index, 1);
      setRoomInput({ ...roomInput, booking_purpose: updatedPurposes });

    if (removedPurposeId) {
      setSelectedPurposes((prev) => {
        const newSet = new Set(prev);
        newSet.delete(removedPurposeId);
        return newSet;
      });
    }
    }
  };

  const handleSelectChange = (index, name, value) => {
    const updatedPurposes = [...roomInput.booking_purpose];
    const previousValue = updatedPurposes[index][name];

    updatedPurposes[index][name] = value;

    if (name === "purpose_id") {
      setSelectedPurposes((prev) => {
        const newSelected = new Set(prev);

        if (previousValue) {
          newSelected.delete(previousValue);
        }

        if (value) {
          newSelected.add(value);
        }

        return newSelected;
      });
    }
    setRoomInput({ ...roomInput, booking_purpose: updatedPurposes });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setIsSubmitLoading(true);
      const formData = new FormData();

      Object.entries(roomInput).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item, index) => {
            Object.keys(item).forEach((subKey) => {
              const formKey = `${subKey}_${index}`;
              formData.append(formKey, item[subKey]);
            });
          });
        } else {
          formData.append(key, value);
        }
      });
      photos.forEach((photo) => formData.append("photos", photo));
      const resp = await createRoom(formData);
      toast.success(resp.data.message);
      navigate("/rooms");
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsActive(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsActive(false);
    const files = Array.from(e.dataTransfer.files);
    setErrors({ ...errors, photos: "" });
    const validFiles = files.filter((file) => file.size > 0);
    setPhotos((prevPhotos) => [...prevPhotos, ...validFiles]);
    setFileName(validFiles.map((file) => file.name).join(", "));
    setCarouselKey((prevKey) => prevKey + 1);
    setActiveIndex(0);
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleBackToAddRoom = () => {
    navigate("/rooms");
  };

  return (
    <>
      <Header />
      <Container className="mt-sm-3 mt-7 mb-sm-0 mb-7 p-sm-2 p-1" fluid>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="mb-0 text-dark fw-bold pb-2">
            <i className="fas fa-plus me-2"></i>
            Add Rooms
          </h3>
          <Button
            className="btn ms-3 bg-black text-white"
            type="button"
            onClick={handleBackToAddRoom}
          >
            Back to Rooms
          </Button>
        </div>

        <div className="card px-2 py-3">
          <div className="row">
            <div className="col-md-6">
              {photos.length === 0 ? (
                <div
                  className={`file-drop-area ${isActive ? "active" : ""}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  onClick={handleClick}
                  style={{
                    border: "2px dashed #ccc",
                    borderRadius: "10px",
                    background: "#f9f9f9",
                    height: "500px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                >
                  <div className="file-select">
                    <p>Drag & Drop Images Here</p>
                    <p>Click Here to Upload Multiple Photos</p>
                    <input
                      type="file"
                      id="roomPhotos"
                      multiple
                      accept="image/*"
                      onChange={handleFileInputChange}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                  </div>
                  {errors.photos && (
                    <div className="text-danger">{errors.photos}</div>
                  )}
                </div>
              ) : (
                <div
                  className="row mt-3"
                  style={{
                    maxHeight: "500px",
                    overflowY: "auto",
                    paddingRight: "10px",
                  }}
                >
                  {photos.map((photo, index) => (
                    <div key={index} className="col-md-6 mb-3">
                      <div className="position-relative">
                        <img
                          src={URL.createObjectURL(photo)}
                          alt={`Room ${index}`}
                          className="img-fluid"
                          style={{
                            height: "200px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            transition:
                              "transform 0.3s ease, box-shadow 0.3s ease",
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.transform = "scale(1.05)";
                            e.target.style.boxShadow =
                              "0 8px 16px rgba(0, 0, 0, 0.2)";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.transform = "scale(1)";
                            e.target.style.boxShadow =
                              "0 4px 8px rgba(0, 0, 0, 0.1)";
                          }}
                        />
                        <button
                          type="button"
                          className="btn bg-white text-black btn-sm mt-1 rounded-full position-absolute"
                          style={{ top: "6px", right: "10px" }}
                          onClick={() => handleDeletePhoto(index)}
                        >
                          <FaTrash style={{ fontSize: "13px" }} />
                        </button>
                      </div>
                    </div>
                  ))}
                  <div
                    className="col-md-6 mb-3 d-flex align-items-center justify-content-center cursor-pointer border border-dashed border-gray-700 rounded"
                    style={{ height: "200px" }}
                    onClick={handleClick}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                  >
                    <span className="fw-bold">+ Add More</span>
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={handleFileInputChange}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-6">
              <form className="row p-3" onSubmit={handleSubmit}>
                <div className="col-md-12 p-0 mb-3">
                  <label htmlFor="name" className="form-label">
                    Room Name:
                  </label>
                  <input
                    id="name"
                    type="text"
                    name="name"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    placeholder="Room Name"
                    value={roomInput.name}
                    onChange={handleChangeAdd}
                  />
                  {errors.name && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>
                <div className="col-12 px-2">
                  <label className="form-label">Booking Purposes:</label>
                  {roomInput.booking_purpose.map((purpose, index) => (
                    <div key={index} className="row mb-2 align-items-center">
                      <div className="col-md-6 col-5 mb-1">
                        <StaticSelectInput
                          parentClassName={`mb-0 me-3 order ${
                            errors.booking_purpose ||
                            errors[`purpose_id_${index}`]
                              ? "border border-danger rounded"
                              : ""
                          }`}
                          setParentInputValue={(name, value) =>
                            handleSelectChange(index, name, value)
                          }
                          optionsList={purposeData?.map((purpose) => ({
                            value: purpose.value,
                            label: purpose.label,
                            disabled:
                              selectedPurposes.has(purpose.value) &&
                              purpose.value !==
                                roomInput.booking_purpose[index]?.purpose_id,
                          }))}
                          name="purpose_id"
                          id={`purpose_${index}`}
                          placeholder="Select Purpose"
                          defaultValue={{
                            value: purpose.purpose_id,
                            label: purpose.purpose_name || "Select Purpose",
                          }}
                          selectedValues={Array.from(selectedPurposes)}
                        />
                        {errors[`purpose_id_${index}`] && (
                          <div className="text-danger text-xs">
                            {errors[`purpose_id_${index}`]}
                          </div>
                        )}
                      </div>
                      <div className="col-md-5 col-6 mb-2">
                        <input
                          type="number"
                          placeholder="Price"
                          className={`form-control room-price ${
                            errors.booking_purpose || errors[`price_${index}`]
                              ? "is-invalid"
                              : ""
                          }`}
                          value={purpose.price_id}
                          onChange={(e) =>
                            handleSelectChange(
                              index,
                              "price_id",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="col-md-1 col-1 d-flex justify-content-center">
                      <div className="col-md-1 col-sm-2 d-flex justify-content-center align-items-center custom-add-remove">
                            {index === roomInput.booking_purpose.length - 1 ? (
                              <button type="button" onClick={handleAddPurpose} className="btn btn-sm btn-primary">
                                <FaPlus />
                              </button>
                            ) : (
                              <button type="button" onClick={() => handleRemovePurpose(index + 1)} className="btn btn-sm btn-danger">
                                <FaMinus />
                              </button>
                            )}
                          </div>
                      </div>
                      {errors.booking_purpose && (
                        <div className="invalid-feedback">
                          {errors.booking_purpose}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Room Type:</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="room_type"
                      id="room"
                      value="Room"
                      checked={roomInput.room_type === "Room"}
                      onChange={handleChangeAdd}
                    />
                    <label className="form-check-label" htmlFor="room">
                      Room
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="room_type"
                      id="lounge"
                      value="Lounge"
                      checked={roomInput.room_type === "Lounge"}
                      onChange={handleChangeAdd}
                    />
                    <label className="form-check-label" htmlFor="lounge">
                      Lounge
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="room_type"
                      id="hall"
                      value="Hall"
                      checked={roomInput.room_type === "Hall"}
                      onChange={handleChangeAdd}
                    />
                    <label className="form-check-label" htmlFor="hall">
                      Hall
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="room_type"
                      id="mandap"
                      value="Mandap"
                      checked={roomInput.room_type === "Mandap"}
                      onChange={handleChangeAdd}
                    />
                    <label className="form-check-label" htmlFor="mandap">
                      Mandap
                    </label>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Room Slot Time:</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="room_slot_type"
                      id="wholeday"
                      value="Full Day"
                      checked={roomInput.room_slot_type === "Full Day"}
                      onChange={handleChangeAdd}
                    />
                    <label className="form-check-label" htmlFor="wholeday">
                      Full Day
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="room_slot_type"
                      id="slot"
                      value="Time Slot"
                      checked={roomInput.room_slot_type === "Time Slot"}
                      onChange={handleChangeAdd}
                    />
                    <label className="form-check-label" htmlFor="slot">
                      Specific Time
                    </label>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label htmlFor="description" className="form-label">
                    Description:
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    className={`form-control ${
                      errors.description ? "is-invalid" : ""
                    }`}
                    placeholder="Description"
                    rows="4"
                    value={roomInput.description}
                    onChange={handleChangeAdd}
                  ></textarea>
                  {errors.description && (
                    <div className="invalid-feedback">{errors.description}</div>
                  )}
                </div>
                <div className="col-md-12">
                  <button
                    type="submit"
                    className="btn bg-black text-white mt-4 w-100"
                    disabled={isSubmitLoading}
                  >
                    {isSubmitLoading ? (
                      <span>
                        <Spinner animation="border" size="sm" /> Submitting...
                      </span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default AddRoom;
