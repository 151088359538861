import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { IoCloseOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import {
  getBookingPurposeList,
  createBookingPurpose,
  updateBookingPurpose,
  deleteBookingPurpose,
  isActiveBookingPurpose,
} from "../../services/SettingService";
import { LiaCircleSolid } from "react-icons/lia";
import Loader from "../Loader/Loader";

function BookingPurposePage() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [bookingPurposeError, setBookingPurposeError] = useState(false);
  const [bookingPurpose, setBookingPurpose] = useState("");
  const [isModalOpen, setIsModalOpen] = useState({ open: false, type: "Add" });
  const [currentBookingPurposeId, setCurrentBookingPurposeId] = useState(null);
  const [isActiveConfirmationModalOpen, setIsActiveConfirmationModalOpen] =
    useState(false);
  const [currentActiveState, setCurrentActiveState] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);

  useEffect(() => {
    loadBookingPurposes();
  }, []);

  const loadBookingPurposes = async () => {
    try {
      const resp = await getBookingPurposeList();
      setData(resp.data.results);
    } catch (error) {
      console.log("Failed to load booking reasons.");
    } finally {
      setIsLoading(false);
    }
  };

  const toggleModal = (type = "Add", item = null) => {
    setBookingPurposeError(false);
    setBookingPurpose("");

    if (type === "Edit" && item) {
      setCurrentBookingPurposeId(item.id);
      setBookingPurpose(item.purpose);
      setIsEnabled(item.is_active);
      setIsModalOpen({ type, open: true });
    } else if (type === "Delete" && item) {
      setCurrentBookingPurposeId(item.id);
      setBookingPurpose(item.purpose);
      setIsModalOpen({ type, open: true });
    } else {
      setCurrentBookingPurposeId(null);
      setIsEnabled(true);
      setIsModalOpen({ type, open: !isModalOpen.open });
    }
  };

  const handleChange = (e) => {
    setBookingPurpose(e.target.value);
    setBookingPurposeError(e.target.value.trim() === "");
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (bookingPurpose.trim() === "") {
      setBookingPurposeError(true);
      return;
    }

    setIsSubmitLoading(true);
    try {
      const payload = {
        purpose: bookingPurpose,
        is_active: isEnabled ? "YES" : "NO",
      };
      if (isModalOpen.type === "Add") {
        const resp = await createBookingPurpose(payload);
        setData([resp.data.results, ...data]);
        toast.success(resp.data.message);
      } else {
        const resp = await updateBookingPurpose({
          id: currentBookingPurposeId,
          ...payload,
        });
        setData(
          data.map((item) =>
            item.id === resp.data.results.id ? resp.data.results : item
          )
        );
        toast.success(resp.data.message);
      }
      toggleModal();
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred.");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const handleActiveChange = (e) => {
    setIsEnabled(e.target.value === "yes");
  };

  const onDeleteSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitLoading(true);

    try {
      await deleteBookingPurpose(currentBookingPurposeId);
      setData(data.filter((item) => item.id !== currentBookingPurposeId));
      toast.success("Booking reason deleted successfully.");
      toggleModal();
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred.");
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const toggleActiveState = (item) => {
    setCurrentBookingPurposeId(item.id);
    setCurrentActiveState(item.is_active);
    setBookingPurpose(item.purpose);
    setIsActiveConfirmationModalOpen(true);
  };

  const confirmToggleActiveState = async () => {
    setIsSubmitLoading(true);
    try {
      const newActiveState = !currentActiveState; // Calculate the new active state
      const resp = await isActiveBookingPurpose({
        id: currentBookingPurposeId,
        is_active: newActiveState ? "YES" : "NO", // Send the correct value
      });

      // Update the data with the new active state
      setData(
        data.map((item) =>
          item.id === resp.data.results.id ? resp.data.results : item
        )
      );

      // Update currentActiveState to reflect the change
      setCurrentActiveState(newActiveState);

      toast.success(resp.data.message);
      setIsActiveConfirmationModalOpen(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred.");
    } finally {
      setIsSubmitLoading(false);
    }
  };


  return (
    <>
      <div className="card rounded-0">
        <div className="d-flex justify-content-between text-wrap flex-column flex-sm-row align-items-start align-items-sm-center p-3">
          <h5 className="heading mb-3 mb-md-0">Booking Purposes</h5>
          <button
            className="btn btn-sm btn-primary mt-3 mt-md-0"
            onClick={() => toggleModal()}
          >
            Add
          </button>
        </div>
        <div className="card-body px-0">
          <div className="widget-media dz-scroll" style={{ height: "50vh", overflowY: "auto", overflowX: "auto" }}>
            <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
              <div className="dataTables_wrapper no-footer">
                <table className="table table-striped table-bordered mb-0">
                  <thead>
                    <tr>
                      <th className="text-left px-3 py-2">Status</th>
                      <th className="text-left px-3 py-2">Booking Purpose</th>
                      <th className="text-left px-3 py-2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="3" className="text-center my-7">
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      data.map((item) => (
                        <tr key={item.id}>
                          <td className="text-left px-3 py-2">
                            <button
                              className={`btn btn-sm px-0 py-0 ${item.is_active ? "btn-success" : "btn-danger"
                                }`}
                              onClick={() => toggleActiveState(item)}
                            >
                              <LiaCircleSolid />
                            </button>
                          </td>
                          <td className="text-left px-3 py-2">
                            <b>{item?.purpose}</b>
                          </td>
                          <td className="text-left px-3 py-2">
                            <div className="d-flex align-items-center justify-content-start">
                              <Button
                                onClick={() => toggleModal("Edit", item)}
                                variant="btn btn-sm btn-circle"
                              >
                                <i className="fa-regular fa-pen-to-square text-primary"></i>
                              </Button>
                              <Button
                                onClick={() => toggleModal("Delete", item)}
                                variant="btn btn-sm btn-circle ms-2"
                                size="sm"
                              >
                                <i className="fa-regular fa-trash-can text-danger"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>


      {/* Confirmation Modal for Enabling/Disabling */}
      <Modal
        centered
        show={isActiveConfirmationModalOpen}
        size="sm"
        onHide={() => setIsActiveConfirmationModalOpen(false)}
      >
        <Modal.Header className="border-bottom">
          <Modal.Title>
            {currentActiveState ? "Disable" : "Enable"} Booking Purpose
          </Modal.Title>
          <div onClick={() => setIsActiveConfirmationModalOpen(false)}>
            <IoCloseOutline className="text-lg font-bolder" />
          </div>
        </Modal.Header>
        <Modal.Body className="p-4">
          <p>
            Are you sure you want to {currentActiveState ? "disable" : "enable"}{" "}
            the booking reason "<strong>{bookingPurpose}</strong>"?
          </p>
          <div className="d-flex justify-content-end">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => setIsActiveConfirmationModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant={currentActiveState ? "warning" : "success"}
              size="sm"
              className="ms-2"
              onClick={confirmToggleActiveState}
              disabled={isSubmitLoading}
            >
              {isSubmitLoading ? (
                <Spinner animation="border" size="sm" />
              ) : currentActiveState ? (
                "Disable"
              ) : (
                "Enable"
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Add Booking Purpose Modal */}
      <Modal
        centered
        show={isModalOpen.open && isModalOpen.type === "Add"}
        size="sm"
        onHide={() => toggleModal()}
      >
        <Modal.Header className="border-bottom">
          <Modal.Title>
            <span>Add Booking Purpose</span>
          </Modal.Title>
          <div onClick={() => toggleModal()}>
            <IoCloseOutline className="text-lg font-bolder" />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 py-3">
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Booking Purpose</Form.Label>
              <Form.Control
                type="text"
                value={bookingPurpose}
                onChange={handleChange}
                isInvalid={bookingPurposeError}
                placeholder="Enter booking purpose"
                maxLength={50} // You can control the maximum length if needed
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid booking purpose.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Active Status</Form.Label>
              <Form.Check
                type="radio"
                label="Yes"
                value="yes"
                checked={isEnabled}
                onChange={handleActiveChange}
                className="mb-2"
              />
              <Form.Check
                type="radio"
                label="No"
                value="no"
                checked={!isEnabled}
                onChange={handleActiveChange}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button variant="secondary" size="sm" onClick={() => toggleModal()}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="sm"
                className="ms-2"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : currentBookingPurposeId ? (
                  "Update"
                ) : (
                  "Add"
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>


      {/* Edit Booking Purpose Modal */}
      <Modal
        centered
        show={isModalOpen.open && isModalOpen.type === "Edit"}
        size="sm"
        onHide={() => toggleModal()}
      >
        <Modal.Header className="border-bottom">
          <Modal.Title>
            <span>Edit Booking Purpose</span>
          </Modal.Title>
          <div onClick={() => toggleModal()}>
            <IoCloseOutline className="text-lg font-bolder" />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 py-3">
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Booking Purpose</Form.Label>
              <Form.Control
                type="text"
                value={bookingPurpose}
                onChange={handleChange}
                isInvalid={bookingPurposeError}
                placeholder="Enter booking purpose"
                maxLength={50} 
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid booking purpose.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Active Status</Form.Label>
              <Form.Check
                type="radio"
                label="Yes"
                value="yes"
                checked={isEnabled}
                onChange={handleActiveChange}
                className="mb-2"
              />
              <Form.Check
                type="radio"
                label="No"
                value="no"
                checked={!isEnabled}
                onChange={handleActiveChange}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button
                variant="secondary"
                size="sm"
                onClick={() => toggleModal()}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                size="sm"
                className="ms-2"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : currentBookingPurposeId ? (
                  "Update"
                ) : (
                  "Add"
                )}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Delete Booking Purpose Modal */}
      <Modal
        centered
        show={isModalOpen.open && isModalOpen.type === "Delete"}
        size="sm"
        onHide={() => toggleModal()}
      >
        <Modal.Header>
          <Modal.Title>
            <span>Delete Booking Purpose</span>
          </Modal.Title>
          <div onClick={() => toggleModal()}>
            <IoCloseOutline className="text-lg font-bolder" />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 py-3">
          <p>
            Are you sure you want to delete the booking purpose "
            <strong>{bookingPurpose.purpose}</strong>"?
          </p>

          {bookingPurpose.is_assigned && (
            <div className="alert alert-warning mt-3">
              <strong>Warning:</strong> This purpose is assigned to rooms. Deleting it will cause issues in audit records.
            </div>
          )}

          <div className="d-flex justify-content-end">
            <Button variant="secondary" size="sm" onClick={() => toggleModal()}>
              Cancel
            </Button>
            <Button
              variant="danger"
              className="ms-2"
              size="sm"
              onClick={onDeleteSubmit}
              disabled={bookingPurpose.is_assigned || isSubmitLoading}
            >
              {isSubmitLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Delete"
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
}

export default BookingPurposePage;