import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, Row } from "reactstrap";
import { Bar } from "react-chartjs-2";
import { getMostRoomBookingGraph } from "../../services/DashBoardService";
import useWindowSize from "../../constant/WindowSize";
import classnames from "classnames";
import Loader from "../../components/Loader/Loader";
import "./Dashboard.css";

const RoomTypeGraph = () => {
  const [dataset, setDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState("weekly");

  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  const toggleTimePeriod = (tp) => {
    setTimePeriod(tp);
  };

  useEffect(() => {
    setIsLoading(true);
    getMostRoomBookingGraph(timePeriod)
      .then((resp) => {
        setDataSet(resp.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [timePeriod]);

  const wrapLabel = (label, maxLength) => {
    if (!label) return ""; // Check for null or undefined
    const words = label.split(" ");
    let wrapped = "";
    let currentLine = "";
    for (let word of words) {
      if (currentLine.length + word.length > maxLength) {
        wrapped += currentLine + "\n";
        currentLine = "";
      }
      currentLine += word + " ";
    }
    return wrapped + currentLine.trim();
  };

  const data = {
    labels: dataset.map((item) => wrapLabel(item?.room_name, 10)),
    datasets: [
      {
        label: "Bookings",
        data: dataset.map((item) => item?.booking_count),
        backgroundColor: "#74b9ff",
        borderWidth: 1,
        hoverBackgroundColor: "#0984e3",
        hoverBorderColor: "#81ecec",
      },
    ],
  };

  // Calculate the maximum value for the Y-axis
  const maxBookingCount = dataset.length > 0 ? Math.max(...dataset.map(item => item.booking_count)) : 0;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: "top",
      labels: {
        fontColor: "#2d3436",
        fontSize: 14,
        boxWidth: 20,
      },
    },
    tooltips: {
      backgroundColor: "#55efc4",
      titleFontColor: "#2d3436",
      bodyFontColor: "#2d3436", // Set body text color to match title
      bodyFontSize: 14,
      xPadding: 10,
      yPadding: 10,
      cornerRadius: 8,
      displayColors: false,
      callbacks: {
        title: function (tooltipItem) {
          return `Room: ${tooltipItem[0].label}`; // Show the room name
        },
        label: function (tooltipItem) {
          return `Total Bookings: ${tooltipItem.yLabel}`; // Show the booking count
        },
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "#dfe6e9",
          },
          ticks: {
            fontColor: "#636e72",
            fontSize: 12,
            callback: function (value) {
              return value.split("\n");
            },
          },
          scaleLabel: {
            display: isMobile ? false : true,
            labelString: "Room Name",
            fontColor: "#2d3436",
          },
          barThickness: isMobile ? 15 : 40,
          maxBarThickness: isMobile ? 15 : 40,
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "#dfe6e9",
          },
          ticks: {
            beginAtZero: true,
            stepSize: 1, // Increment by 1
            min: 0,      // Start from 0
            max: Math.max(maxBookingCount + 1, 1), // Ensure max is at least 1
            fontColor: "#636e72",
            fontSize: 12,
            callback: function (value) {
              return value; // Ensures values show up as 0, 1, 2, etc.
            },
          },
          scaleLabel: {
            display: true,
            labelString: "Number of Bookings",
            fontColor: "#2d3436",
          },
        },
      ],
    },
    animation: {
      duration: 1500,
      easing: "easeInOutQuart",
      onComplete: function () {
        const chartInstance = this.chart;
        const ctx = chartInstance.ctx;
        ctx.textAlign = "center";
        ctx.font = "bold 12px Arial";
        ctx.fillStyle = "#2d3436";

        this.data.datasets.forEach((dataset, i) => {
          const meta = chartInstance.getDatasetMeta(i);
          meta.data.forEach((bar, index) => {
            const data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);
          });
        });
      },
    },
  };

  return (
    <Card className="shadow-sm p-4" style={{ borderRadius: "12px" }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="mb-0" style={{ color: "#2c3e50" }}>
          <strong>Venue Booking Frequency</strong>
        </h2>
        <Nav pills>
          <NavItem>
            <NavLink
              className={classnames("py-1 px-3", { active: timePeriod === "yearly" })}
              style={{
                backgroundColor: timePeriod === "yearly" ? "#d63031" : "transparent",
                color: "#2c3e50",
                borderRadius: "10px",
                transition: "0.3s ease",
              }}
              onClick={() => toggleTimePeriod("yearly")}
            >
              Y
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("py-1 px-3", { active: timePeriod === "monthly" })}
              style={{
                backgroundColor: timePeriod === "monthly" ? "#6c5ce7" : "transparent",
                color: "#2c3e50",
                borderRadius: "10px",
                transition: "0.3s ease",
              }}
              onClick={() => toggleTimePeriod("monthly")}
            >
              M
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("py-1 px-3", { active: timePeriod === "weekly" })}
              style={{
                backgroundColor: timePeriod === "weekly" ? "#00b894" : "transparent",
                color: "#2c3e50",
                borderRadius: "10px",
                transition: "0.3s ease",
              }}
              onClick={() => toggleTimePeriod("weekly")}
            >
              W
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div style={{
        height: "1px",
        background: "linear-gradient(90deg, black, white)",
        marginBottom: "15px",
        borderRadius: "2px",
        transition: "width 0.5s ease",
        width: "100%",
      }}></div>

      <div style={{ height: "350px" }}>
        {isLoading ? (
          <div className="text-center my-5">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : dataset ? (
          <div style={{ height: "350px" }}>
            <Bar data={data} options={options} />
          </div>
        ) : (
          <div className="text-center py-8">
            <i className="fas fa-search-minus" style={{ fontSize: "1.5rem", opacity: 0.8, color: "#17a2b8" }}></i>
            <h3 style={{ opacity: 0.5 }}>No Booking Occurs</h3>
          </div>

        )}
      </div>
    </Card>
  );
};

export default RoomTypeGraph;
