import React, { useRef } from "react";
import html2pdf from "html2pdf.js";
import { Container } from "reactstrap";
import logo from "../../../../assets/img/brand/logo.png";
import useWindowSize from "../../../../constant/WindowSize";
import "./modalStyles.css";

const BookingReceipt = ({ booking, onClose }) => {
  const receiptRef = useRef();
  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  const downloadPDF = () => {
    const element = receiptRef.current;
    const buttons = document.querySelectorAll(".hide-when-printing");
    buttons.forEach((button) => (button.style.display = "none"));

    const options = {
      margin: 0.5,
      filename: ` Booking_Receipt_${booking?.booking_number}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
    };

    html2pdf().set(options).from(element).save();
  };

  return (
    <Container className="mt-4">
      <div class="receipt-container" ref={receiptRef}>
        <div className="text-center">
          <div class="d-flex align-items-center justify-content-center company-logo">
            <img src={logo} alt="Company Logo" />
          </div>
          <div class="receipt-title">Receipt</div>
          <div class="company-info">
            <p>Rourkela Club</p>
            <p>Sector-20, Rourkela-769005</p>
            <p>Email: info@rourkelaclub.com | Phone: +91-661-2952118</p>
          </div>
        </div>

        <div class="details-section mb-3">
          <div>
            <p class="details-title">Customer Details:</p>
            <p>
              Name: {booking?.user?.first_name} {booking?.user?.last_name}
            </p>
            <p>Email: {booking?.user?.email}</p>
            <p>Phone: {booking?.user?.phone_number}</p>
          </div>
          <div>
            <p class="details-title">Booking Details:</p>
            <p>Booking Number : {booking?.booking_number}</p>
            <p>Date: {booking?.transaction?.created_at}</p>
            <p>Payment Mode : {booking?.transaction?.payment_source}</p>
          </div>
        </div>

        <table className="table table-striped">
          <thead className="bg-dark text-white">
            <tr>
              <th>Room Name</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{booking?.booking_slot?.room.name}</td>
              <td>
                ₹{" "}
                {(booking?.booking_net_amount - booking?.transaction?.gst_amount).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="text-right">
                <strong>CGST:</strong> <br />
                <strong>SGST:</strong>
              </td>
              <td>
                ₹ {((booking?.transaction?.gst_amount / 2) || 0).toFixed(2)} <br />
                ₹ {((booking?.transaction?.gst_amount / 2) || 0).toFixed(2)}
              </td>
            </tr>

            <tr>
              <td className="text-right text-danger">
                <h4>
                  <strong>Total:</strong>
                </h4>
              </td>
              <td className="text-danger">
                <h4>
                  <strong>₹ {booking?.booking_net_amount.toFixed(2)}</strong>
                </h4>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p>
              <strong>Date:</strong> 15 Aug 2016
            </p>
            <h5 className="text-muted">Thanks for Booking!</h5>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <button
              className="btn btn-danger btn-sm px-3 hide-when-printing"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary download-btn btn-sm hide-when-printing"
              onClick={downloadPDF}
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default BookingReceipt;