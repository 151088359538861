

import axiosInstance from "./AxiosInstance";
const apiUrl = "api/member";
const apiMemberHistory = "api/member_bookinghistory";

// Existing API functions for member operations
export function getMemberListAPI(page, page_size, query="", status="", sort_by) {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}&status=${status}&sort_by=${sort_by}`
  );
}

export function getMemberDetails(id) {
  return axiosInstance.get(`api/member_details/${id}`);
}

export function createMemberAPI(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateMemberAPI(data) {
  return axiosInstance.put(apiUrl, data);
}

export function verifyMemberAPI(data) {
  return axiosInstance.patch(apiUrl, data);
}

export function getMemberBookingHistory(user_id) {
  return axiosInstance.get(`${apiMemberHistory}?user_id=${user_id}`);
}

// New API function to generate membership ID
export function generateMembershipIdAPI(membershipType) {
  return axiosInstance.post("api/auth/generate_membership_id", { membership_type: membershipType });
}

export function updateMembershipIdAPI(customMembershipId) {
  return axiosInstance.patch("api/auth/generate_membership_id", { membership_id: customMembershipId });
}

// New API function to get available membership IDs
export function getAvailableMembershipIdsAPI() {
  return axiosInstance.get("api/auth/generate_membership_id");
}
