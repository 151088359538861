import React, { useState, useEffect } from "react";
import TableNoResultFound from "../../constant/NoResultFound/TableNoResultFound.js";
import { Card, CardFooter, Table, Row, Col } from "reactstrap";
import { getDailySalesReportList } from "../../services/ReportService.js";

const recordsPage = 20;

const DailySalesReport = ({
  searchQuery,
  year,
  month,
  start_date,
  end_date,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getDailySalesReportList(
      currentPage,
      recordsPage,
      searchQuery,
      year,
      month,
      start_date,
      end_date,
      false
    )
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [currentPage, searchQuery, year, month, start_date, end_date]);

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  return (
    <div className="px-0" id="table-to-download">
      <Card className="shadow">
        <Table
          className="table-component__table align-items-center table-flush"
          responsive
        >
          <thead className="booking-table-head disable-select">
            <tr className="booking-table-header">
              <th scope="col">Date</th>
              <th scope="col">Razorpay</th>
              <th scope="col">PhonePe</th>
              <th scope="col">Paytm</th>
              <th scope="col">DebitCard</th>
              <th scope="col">IMPS</th>
              <th scope="col">Cash</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td className="text-center my-7" colSpan="7">
                  <div style={{ width: "100%", height: "100%" }}>
                    <div className="spinner-grow text-black me-2" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </td>
              </tr>
            ) : data.day_wise_payments?.length > 0 ? (
              data.day_wise_payments.map((item, index) => (
                <tr key={index} className="booking-table-row">
                  <td>{item.date}</td>
                  <td>
                    {item.payments.Razorpay
                      ? item.payments.Razorpay.toLocaleString()
                      : 0}
                  </td>
                  <td>
                    {item.payments.PhonePe
                      ? item.payments.PhonePe.toLocaleString()
                      : 0}
                  </td>
                  <td>
                    {item.payments.Paytm
                      ? item.payments.Paytm.toLocaleString()
                      : 0}
                  </td>
                  <td>
                    {item.payments?.Debitcard
                      ? item.payments?.Debitcard.toLocaleString()
                      : 0}
                  </td>
                  <td>
                    {item.payments.IMPS
                      ? item.payments.IMPS.toLocaleString()
                      : 0}
                  </td>
                  <td>
                    {item.payments.Cash
                      ? item.payments.Cash.toLocaleString()
                      : 0}
                  </td>
                  <td>{item?.total}</td>
                </tr>
              ))
            ) : (
              <TableNoResultFound />
            )}
          </tbody>
        </Table>

        <CardFooter className="py-4">
          <Row>
            <Col>
              <strong>Total </strong>
            </Col>
            <Col>
              {data.totals?.Razorpay || 0}
            </Col>
            <Col className="text-center">
              {" "}
              {data.totals?.PhonePe || 0}
            </Col>
            <Col className="text-center">
             {data.totals?.Paytm || 0}
            </Col>
             <Col className="text-center">
               {data.totals?.Debitcard || 0}
            </Col> 
            <Col className="text-center">
              {data.totals?.IMPS || 0}
            </Col>
            <Col className="text-center">
              {data.totals?.Cash || 0}
            </Col>
            <Col className="text-center">
              {data.totals?.total || 0}
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </div>
  );
};

export default DailySalesReport;
