import React, { useState } from 'react';
import Header from '../Headers/Header';
import { Container, Nav, Tab, Row, Col } from "react-bootstrap"; 
import Coupon from './Coupon';
import BookingReasonPage from './BookingReason';
import CancelReasonPage from './CancelReason';
import { motion } from 'framer-motion';
import './Settings.css';

function Settings() {
  const [activeTab, setActiveTab] = useState("coupon");

  return (
    <>
      <Header />
      <Container className="mt-sm-0 mt-2 px-3" fluid>
        <Tab.Container activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
          <Row>
            <Nav variant="tabs" className="w-100 bg-white">
              <Nav.Item>
                <Nav.Link eventKey="coupon" className='p-4 text-primary'>
                  <i className="fa-solid fa-percent clr-fa me-2 text-primary"></i>Coupon
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="booking" className='p-4 text-primary'>
                  <i className="fa-solid clr-fa fa-calendar-check me-2 text-primary"></i>Booking Reason
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="cancel" className='p-4 text-primary'>
                  <i className="fa-solid clr-fa fa-times-circle me-2 text-primary"></i>Cancel Reason
                </Nav.Link>
              </Nav.Item>
            </Nav>

            {/* Tab Content */}
            <Tab.Content className='w-100'>
              <Tab.Pane eventKey="coupon">
                <Coupon />
              </Tab.Pane>
              <Tab.Pane eventKey="booking">
                <BookingReasonPage />
              </Tab.Pane>
              <Tab.Pane eventKey="cancel">
                <CancelReasonPage />
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
}

export default Settings;
