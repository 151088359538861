import axiosInstance from "./AxiosInstance";

const apiCoupon = "api/coupon";
const apiApplyCoupon = "api/apply_coupon";
const apiBookingPurpose = "api/admin_booking_purpose";
const apiCancelPurpose = "api/admin_cancel_purpose";

export function getCouponList() {
  return axiosInstance.get(apiCoupon);
}

export function createCoupon(data) {
  return axiosInstance.post(apiCoupon, data);
}

export function updateCoupon(data) {
  return axiosInstance.put(apiCoupon, data);
}

export function deleteCoupon(id) {
  return axiosInstance.delete(`${apiCoupon}/${id}`);
}

export function getApplyCouponList() {
  return axiosInstance.get(apiApplyCoupon);
}

export function getBookingPurposeList() {
  return axiosInstance.get(apiBookingPurpose);
}

export function createBookingPurpose(data) {
  return axiosInstance.post(apiBookingPurpose, data);
}

export function updateBookingPurpose(data) {
  return axiosInstance.put(apiBookingPurpose, data);
}

export function deleteBookingPurpose(id) {
  return axiosInstance.delete(`${apiBookingPurpose}/${id}`);
}

export function isActiveBookingPurpose(data) {
  return axiosInstance.patch(apiBookingPurpose, data);
}

  export function getCancelPurposeList() {
  return axiosInstance.get(apiCancelPurpose);
}

export function createCancelPurpose(data) {
  return axiosInstance.post(apiCancelPurpose, data);
}

export function updateCancelPurpose(data) {
  return axiosInstance.put(apiCancelPurpose, data);
}

export function deleteCancelPurpose(id) {
  return axiosInstance.delete(`${apiCancelPurpose}/${id}`);
}

export function isActiveCancelPurpose(data) {
  return axiosInstance.patch(apiCancelPurpose, data);
}

export function getIsPendingBooking() {
  return axiosInstance.get("api/check_pending_booking");
}
