import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import "./BookingPage.css";
import Header from "../../../Headers/Header";
import useWindowSize from "../../../../constant/WindowSize";
import AdminPaymentDetails from "./AdminPaymentDetails";
import { FaAngleRight } from "react-icons/fa";
import CouponPage from "./CouponPage";
import UserPaymentMode from "./UserPaymentMode";
import { Container } from "react-bootstrap";
import PriceTable from "./PriceTable";
import BookingForm from "./BookingForm";
import { BsExclamationTriangle } from 'react-icons/bs';
import { getIsPendingBooking } from "../../../../services/SettingService";
import { getOpenRoom } from "../../../../services/RoomService";

const BookingPage = ({ userRole, userId }) => {
  const [isBookingLoading, setIsBookingLoading] = useState(false);
  const [data, setData] = useState(null);
  const [bookInput, setBookInput] = useState({
    booking_time: "",
    booking_date: "",
    on_behalf_id: "",
    booking_purpose_id: "",
  });
  const [bookedDisableDateList, setBookedDisableDateList] = useState([]);
  const [showConfirmPage, setShowConfirmPage] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [purposeError, setPurposeError] = useState(false);
  const [showCouponPage, setShowCouponPage] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [isApplyDisabled, setIsApplyDisabled] = useState(true);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [isPendingBooking, setIsPendingBooking] = useState(false);
  // socket state var

  const [socketBookingProgress, setSocketBookingProgress] = useState([]);
  const [disableDateList, setDisableDateList] = useState([]);
  const calendarRef = useRef(null);
  const socket = useRef(null);
  const scrollRef = useRef(0);
  const hasURLBookingTime = useRef(false);
  const defaultBookingTime = useRef("");
  const couponCardRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const paymentMethodCardRef = useRef(null);
  const selectOptions = data?.room_pricing?.map((item) => ({
    value: item.id,
    label: item.purpose.name,
  }));
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const booking_date = queryParams.get("date") || "";
    const booking_time = queryParams.get("slotType") || "";
  
    if (booking_date && booking_time) {
      setBookInput((prev) => ({
        ...prev,
        booking_date,
        booking_time,
      }));
      hasURLBookingTime.current = true; // Mark that URL provided booking_time
      navigate(location.pathname, { replace: true }); // Clear URL params after setting
    }
  }, [location.search, navigate]);

  useEffect(() => {
  getOpenRoom(id)
    .then((resp) => {
      const { result } = resp.data;
      setData(result);

      // Only set booking_time if it was not provided in URL parameters
      if (!hasURLBookingTime.current) {
        const booking_time =
          result.room_slot_type === "Full Day"
            ? "09:00 AM to 11:00 PM"
            : "09:00 AM to 03:00 PM";
        setBookInput((prev) => ({
          ...prev,
          booking_time,
        }));
      }
    })
    .catch((error) => {
      console.error("Error fetching room data:", error);
      setData(null);
    });
}, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchPendingBookingStatus = async () => {
      if (userRole === "User") {
        try {
          const response = await getIsPendingBooking();
          if (response.data.pending_booking) {
            setIsPendingBooking(true);
          } else {
            setIsPendingBooking(false);
          }
        } catch (error) {
          console.error("Failed to fetch pending booking status:", error);
        }
      }
    };

    fetchPendingBookingStatus();
  }, [userRole]);

  const initializePrice = useCallback(() => {
    if (data?.room_pricing) {
      const lowestPrice = data.room_pricing?.reduce(
        (min, pricing) => Math.min(min, pricing.price),
        Infinity
      );
      setSelectedPrice(lowestPrice);
    }
  }, [data]);

  useEffect(() => {
    initializePrice();
  }, [initializePrice]);

  const socketConnection = useCallback(() => {
    if (bookInput.booking_time) {
      let socketURL
      if (process.env.REACT_APP_HEADER_TOKEN === "LOCALSTORAGE"){
        const token = localStorage.getItem("TOKEN")
        socketURL = `${process.env.REACT_APP_WS_BASE_URL}/ws/bookings?token=${token}`
      } else {
        socketURL = `${process.env.REACT_APP_WS_BASE_URL}/ws/bookings`
      }
      socket.current = new WebSocket(socketURL);

      socket.current.onopen = () => {
        const { booking_time } = bookInput;
        const socketPayload = {
          event_type: "BOOKING_INIT",
          user_id: userId,
          room_id: id,
          booking_time,
        };
        sendMessage(socketPayload);
        console.log("WebSocket connection opened");
      };

      socket.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleSocketData(data.message);
      };

      socket.current.onclose = (event) => {
        console.log("Socket Disconnected ", event);
        // if (!event.wasClean) {
        //   console.log("Reconnecting WebSocket...");
        //   setTimeout(socketConnection, 1000); // Attempt to reconnect after a delay
        // }
      };

      socket.current.onerror = (error) => {
        console.error("WebSocket Error: ", error);
        socket.current.close();
      };
    }
  }, [bookInput.booking_time]);

  useEffect(() => {
    socketConnection();
    return () => {
      if (socket.current) {
        socket.current.close();
      }
    };
  }, [socketConnection]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (bookInput.booking_date && showConfirmPage) {
        const socketPayload = {
          event_type: "BOOKING_CANCEL",
          user_id: userId,
          room_id: id,
          booking_date: bookInput.booking_date
            ? format(bookInput.booking_date, "yyyy-MM-dd")
            : "",
          booking_time: bookInput.booking_time,
        };
        sendMessage(socketPayload);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [bookInput.booking_date, showConfirmPage]);

  const handleUpdateSocketData = (booking_time, booking_dates) => {
    let updateSocketData = socketBookingProgress.map((item) => {
      if (item.booking_time === booking_time) {
        return {
          booking_time,
          booking_date: [...item.booking_date, ...booking_dates],
        };
      }
      return { booking_time, booking_date: booking_dates };
    });

    if (updateSocketData.length === 0) {
      updateSocketData = [{ booking_time, booking_date: booking_dates }];
    }
    setSocketBookingProgress(updateSocketData);
  };

  const handleSocketData = (data) => {
    if (data.room_id === id) {
      if (data.action === "BOOKING_INIT" && data.user_id === userId) {
        if (data.booking_dates.length > 0) {
          handleUpdateSocketData(data.booking_time, [...data.booking_dates]);
          if (bookInput.booking_time === data.booking_time) {
            setDisableDateList((prevDates) => {
              const updatedDates = [
                ...new Set([...prevDates, ...data.booking_dates]),
              ];
              return updatedDates;
            });
          }
        }
      } else if (data.action === "BOOKING_START") {
        const newDisabledDates = [...disableDateList, data.booking_date];
        if (bookInput.booking_time === data.booking_time) {
          setDisableDateList((prevDates) => {
            const updatedDates = [
              ...new Set([...prevDates, ...newDisabledDates]),
            ];
            return updatedDates;
          });
        }
        handleUpdateSocketData(data.booking_time, newDisabledDates);
      } else if (data.action === "BOOKING_CANCEL") {
        setDisableDateList((prevDates) => {
          const updatedDisableDateList = prevDates.filter(
            (item) => item !== data.booking_date
          );
          handleUpdateSocketData(data.booking_time, updatedDisableDateList);
          return [...new Set([...updatedDisableDateList])];
        });
      }
    }
  };

  const sendMessage = (data) => {
    if (socket.current?.readyState === WebSocket.OPEN) {
      socket.current.send(JSON.stringify(data));
    }
  };

  useEffect(() => {
    const handleBackNavigation = (event) => {
      if (showConfirmPage) {
        event.preventDefault();
        handleCancelBook(); // Call your function when back button is pressed
        window.history.pushState(null, '', window.location.href); // Keep user on the same page
      }
    };

    if (showConfirmPage) {
      window.addEventListener('popstate', handleBackNavigation);
      window.history.pushState(null, '', window.location.href);
    }

    return () => {
      window.removeEventListener('popstate', handleBackNavigation);
    };
  }, [showConfirmPage]);

  const handlePurposeChange = (purposeId) => {
    const purposePricing = data.room_pricing.find(
      (pricing) => pricing.id === purposeId
    );
    if (purposePricing) {
      setSelectedPrice(purposePricing.price);
      setBookInput((prev) => ({ ...prev, booking_purpose_id: purposeId }));
    }
  };

  const handleBook = (e) => {
    e.preventDefault();
    const { booking_date, booking_purpose_id } = bookInput;
    let hasError = false;

    if (!booking_date) {
      setDateError(true);
      hasError = true;
    } else {
      setDateError(false);
    }

    if (!booking_purpose_id) {
      setPurposeError(true);
      hasError = true;
    } else {
      setPurposeError(false);
    }

    if (hasError) {
      return;
    }
    setShowConfirmPage(true);

    const { booking_time } = bookInput;
    const socketPayload = {
      event_type: "BOOKING_START",
      user_id: userId,
      room_id: id,
      booking_date: format(booking_date, "yyyy-MM-dd"),
      booking_time,
    };

    sendMessage(socketPayload);
  };

  const handleCancelBook = (isSendingSocketMessage=true) => {
    setShowConfirmPage(false);
    const { booking_date, booking_time } = bookInput;
    const bookingTime = data.room_slot_type === "Full Day" ? "09:00 AM to 11:00 PM" : "09:00 AM to 03:00 PM"

    setBookInput((prev) => { 
      return { ...prev, booking_date:"", booking_time:bookingTime,  booking_purpose_id: "",};
    })
    if(isSendingSocketMessage){
      const socketPayload = {
        event_type: "BOOKING_CANCEL",
        user_id: userId,
        room_id: id,
        booking_date: format(booking_date, "yyyy-MM-dd"),
        booking_time,
      };
      sendMessage(socketPayload);
    }
  };

  const handleShowCoupons = (e) => {
    e.preventDefault();
    scrollRef.current = window.scrollY;
    setShowCouponPage(!showCouponPage);
    setTimeout(() => {
      couponCardRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 0);
  };

  const handleCouponSelect = (coupon) => {
    setSelectedCoupon(coupon);
    setIsApplyDisabled(false);
  };

  return (
    <>
      {userRole !== "User" && <Header />}
      <Container
        fluid
        className={`booking-container ${
          userRole === "User" ? "my-sm-7 my-6 px-sm-5 px-2" : "my-sm-3 my-7"
        }`}
      >
        <div className="row p-2 mb-3">
          <div className="col-md-8 col-sm-12 mb-3">
            <div className="card p-3">
              <div className="table-responsive">
                <table className="table table-borderless table-shopping-cart">
                  <thead className="text-muted">
                    <tr className="small text-uppercase">
                      <th scope="col">Room</th>
                      <th scope="col" width="200">
                        Details
                      </th>
                      {!isMobile && (
                        <th scope="col" width="150">
                          Price
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {data && (
                      <tr>
                        <td>
                          <div
                            className="mb-3"
                            style={{
                              height: "auto",
                              width: "100%",
                            }}
                          >
                            <img
                              src={`${process.env.REACT_APP_BASE_URL}${data?.roomphoto[0]?.photo}`}
                              alt={data.name}
                              className="img-fluid"
                              style={{ maxHeight: "200px", objectFit: "cover" }}
                            />
                          </div>
                        </td>
                        <td>
                          <figcaption className="info">
                            <Link
                              to={`/room-details/${data.id}`}
                              className="title text-dark"
                              data-abc="true"
                            >
                              {data?.name}
                            </Link>
                            <p className="text-muted small">
                              Type: {data?.room_type}
                            </p>
                          </figcaption>
                        </td>
                        {!isMobile && (
                          <td>
                            <div className="price-wrap">
                              <var className="price">
                                ₹{selectedPrice?.toFixed(2)}
                              </var>
                            </div>
                          </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {showConfirmPage ? (
                !isMobile && (
                  <div className="payment-methods-container p-4 rounded shadow">
                    <h3 className="payment-title text-center mb-4">
                      Select Payment Mode
                    </h3>
                    {userRole !== "User" ? (
                      <AdminPaymentDetails
                        roomId={data?.id}
                        price={selectedPrice}
                        room={bookInput}
                        coupon={selectedCoupon}
                        purpose_id={bookInput.booking_purpose_id}
                        isMobile={isMobile}
                        handleCancelBook={handleCancelBook}
                      />
                    ) : (
                      <UserPaymentMode
                        handleCancelBook={handleCancelBook}
                        price={selectedPrice}
                        purpose_id={bookInput.booking_purpose_id}
                        coupon={selectedCoupon}
                        setShowConfirmPage={setShowConfirmPage}
                        isMobile={isMobile}
                        bookInput={bookInput}
                        room={data}
                      />
                    )}
                  </div>
                )
              ) : (
                !isPendingBooking ?
                (<BookingForm
                  setBookInput={setBookInput}
                  bookedDisableDateList={bookedDisableDateList}
                  setBookedDisableDateList={setBookedDisableDateList}
                  dateError={dateError}
                  setDateError={setDateError}
                  purposeError={purposeError}
                  setPurposeError={setPurposeError}
                  calendarRef={calendarRef}
                  disabledDates={[...disableDateList, ...bookedDisableDateList]}
                  handleBook={handleBook}
                  onPurposeChange={handlePurposeChange}
                  bookInput={bookInput}
                  room={data}
                  isMobile={isMobile}
                  userRole={userRole}
                  selectOptions={selectOptions}
                />) : (<div className="d-flex align-items-center p-4 m-3 border border-danger rounded">
                  <BsExclamationTriangle className="text-danger me-2" size={20} />
                  <span className="text-danger text-wrap" style={{ fontSize: "0.9rem" }}>
                    Your Previous Booking is Pending. Contact Admin to complete that then proceed to another booking.
                  </span>
                </div>)
              )}
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div ref={couponCardRef} className="card shadow mb-3">
              <div className={`card-body px-4 ${showCouponPage ? "mb--4" : "mb-0"}`}>
                <label htmlFor="coupon_code" className="d-block mt-2">
                  <strong className="me-1">Do you have a coupon code?</strong>
                  <span className="text-gray-500">(Optional)</span>
                </label>
                <div className="d-flex mt-1 mb-3">
                  <input
                    type="text"
                    id="coupon_code"
                    className="form-control bg-transparent text-black flex-grow-1"
                    value={selectedCoupon?.code || ""}
                    readOnly
                  />
                  {selectedCoupon && (
                    <button
                      type="button"
                      className="ml-2 btn btn-danger"
                      onClick={() => {
                        setSelectedCoupon(null);
                        setIsApplyDisabled(true);
                      }}
                    >
                      &times;
                    </button>
                  )}
                </div>
                <span
                  className="d-flex text-sm cursor-pointer text-blue align-items-center"
                  onClick={handleShowCoupons}
                >
                  {showCouponPage ? "Hide All Coupons" : "View All Coupons"}
                  <FaAngleRight className="ms-2" />
                </span>
              </div>
              {showCouponPage && (
                <CouponPage
                  showApply={selectedCoupon}
                  setShowCouponPage={setShowCouponPage}
                  onCouponSelect={handleCouponSelect}
                  price={selectedPrice.toFixed(2)}
                  userRole={userRole}
                />
              )}
            </div>

            <PriceTable
              room={data}
              price={selectedPrice}
              selectedCoupon={selectedCoupon}
            />
            {isMobile && showConfirmPage && (
              <div className="card shadow mt-3" ref={paymentMethodCardRef}>
                <div className="payment-methods-container p-4 rounded shadow">
                  <h3 className="payment-title text-center mb-4">
                    Select Payment Method
                  </h3>
                  {!isMobile && userRole !== "User" ? (
                    <AdminPaymentDetails
                      roomId={data?.id}
                      room={bookInput}
                      coupon={selectedCoupon}
                      purpose_id={bookInput.booking_purpose_id}
                      isMobile={isMobile}
                      handleCancelBook={handleCancelBook}
                    />
                  ) : (
                    <UserPaymentMode
                      price={selectedPrice.toFixed(2)}
                      handleCancelBook={handleCancelBook}
                      purpose_id={bookInput.booking_purpose_id}
                      coupon={selectedCoupon}
                      setShowConfirmPage={setShowConfirmPage}
                      isMobile={isMobile}
                      bookInput={bookInput}
                      room={data}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {isMobile && !showConfirmPage && (
          <button
            type="submit"
            className="btn bg-black text-white w-full py-2"
            disabled={isBookingLoading}
            onClick={(e) => handleBook(e)}
          >
            Proceed For Payment
          </button>
        )}
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "User",
  userId: state.auth.user?.id || "",
});

export default connect(mapStateToProps)(BookingPage);
