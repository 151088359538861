import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useWindowSize from "../../../../constant/WindowSize";
import AllRooms from "./AllRooms";
import { getOpenRoom, getRoomDetails } from "../../../../services/RoomService";
import CalendarPopUp from "../PopUpPage/CalendarPopUp";
import Loader from "../../../Loader/Loader";

const UserRoomDetails = ({ user }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();
  const size = useWindowSize();
  const isMobile = size.width <= 1024;
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);

    const apiCall = user ? getRoomDetails : getOpenRoom;

    apiCall(id)
      .then((resp) => {
        setData(resp.data.result);
        setMessage(resp.data.message);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching room details:", error);
        setData(null);
        setIsLoading(false);
      });
  }, [id, user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.state]);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const handleBookNow = (room) => {
    if (room?.id) {
      navigate(`/booking/${room.id}`);
    } else {
      console.error("Room ID is missing");
    }
  };

  return (
    <>
      <div className="container-fluid mt-7 pb-6">
        <div className="d-flex align-items-center px-3 border-primary/50">
          <button
            type="button"
            className="btn btn-sm btn-outline-dark rounded-circle"
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </button>
          <h3 className="py-2 pl-2 text-xl font-bold">Room Details</h3>
        </div>
        <div className="mt-2">
          <div
            className="p-4 border rounded shadow mb--3"
            style={{ backgroundColor: "#ffffff", marginBottom: "20px" }}
          >
            {isLoading ? (
              <div
                className="text-center my-7"
                style={{ width: "100%", height: "100%" }}
              >
                <Loader />
              </div>
            ) : data ? (
              <div className="row">
                <div className="col-md-5">
                  <Carousel
                    showArrows
                    autoPlay={false}
                    infiniteLoop
                    interval={3000}
                    transitionTime={1000}
                    className="w-100"
                    showThumbs={isMobile ? false : true}
                  >
                    {data?.roomphoto?.map((photo, index) => (
                      <div key={photo.id}>
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}${photo?.photo}`}
                          alt={data?.name}
                          className="img-fluid"
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
                <div className="col-md-7">
                  <div>
                    <h3
                      className="room-name"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "5px",
                        fontSize: "2.2rem",
                        color: "black",
                        animation: "fadeInUp 1s ease-out",
                      }}
                    >
                      {data?.name}
                    </h3>
                  </div>
                  <div className="mb-2">
                    <strong style={{ color: "#333", fontSize: "0.9rem" }}>Pricing:</strong>
                    <ul className="list-unstyled mt-1">
                      {data?.room_pricing?.map((pricing) => (
                        <li
                          key={pricing.id}
                          className="d-flex justify-content-between border-bottom py-1"
                          style={{ fontSize: "0.85rem" }} // Smaller font size for the list items
                        >
                          <span style={{ color: "#333", fontWeight: "bold" }}>
                            {pricing.purpose.name}
                          </span>
                          <span style={{ color: "#555" }}>
                            ₹{pricing.price.toLocaleString()}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>


                  <div style={{ marginBottom: "10px", color: "#555" }}>
                    {isMobile ? (
                      <>
                        {isReadMore
                          ? data?.description
                          : `${data?.description?.substring(0, 25)}...`}
                        <span
                          onClick={toggleReadMore}
                          style={{
                            color: "blue",
                            cursor: "pointer",
                            marginLeft: "5px",
                          }}
                        >
                          {isReadMore ? "Show Less" : "Read More"}
                        </span>
                      </>
                    ) : (
                      data?.description
                    )}
                  </div>
                  <div className="mb-4">
                    <small
                      className="border-end me-3 pe-3"
                      style={{ color: "#777" }}
                    >
                      <i className="fa-regular fa-clock"></i>{" "}
                      {data?.room_slot_type}
                    </small>
                    <small
                      className="border-end me-3 pe-3"
                      style={{ color: "#777" }}
                    >
                      <i className="fa-solid fa-bed"></i> {data?.room_type}
                    </small>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    {user ? (
                      <div>
                        <button
                          className="text-white bg-black px-3 py-1 rounded duration-200"
                          onClick={() => handleBookNow(data)}
                        >
                          Book Now
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button
                          className="text-white bg-black px-3 py-1 rounded duration-200"
                          onClick={() =>
                            navigate(
                              `/login?next_url=${`/booking/${data?.id}`}`
                            )
                          }
                        >
                          Book Now
                        </button>
                      </div>
                    )}
                    <CalendarPopUp
                      roomId={data ? data.id : null}
                      roomSlotType={
                        data ? data.room_slot_type : null
                      }
                      room={data}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {message ? (
                  <div
                    className="card d-flex justify-content-center align-items-center"
                    style={{ height: "80vh", width: "100%" }}
                  >
                    <div className="text-center">
                      <i
                        className="fa fa-bed fa-3x mb-2"
                        style={{ fontSize: "2rem", opacity: 0.5 }}
                      ></i>
                      <h3 style={{ opacity: 0.5 }}>{message}</h3>
                    </div>
                  </div>
                ) : (
                  <div
                    className="card d-flex justify-content-center align-items-center"
                    style={{ height: "80vh", width: "100%" }}
                  >
                    <div className="text-center">
                      <i
                        className="fa fa-bed fa-3x mb-2"
                        style={{ fontSize: "2rem", opacity: 0.5 }}
                      ></i>
                      <h3 style={{ opacity: 0.5 }}>Room details not available</h3>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <AllRooms />
      </div>

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(UserRoomDetails);