import React from "react";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import ReactPaginate from "react-paginate";

const Pagination = (props) => {
  const { currentPage, recordsPage, dataLength, handlePagination } = props;
  const lastIndex = currentPage * recordsPage;
  const count = Number(Math.ceil(dataLength / recordsPage));


  const styles = {
    pageLink: {
      color: "#007bff",
      cursor: "pointer",
      padding: "5px 10px",
      textDecoration: "none",
    },
    pageLinkHover: {
      color: "#0056b3",
    },
    activePage: {
      backgroundColor: "grey",
      color: "white",
      borderRadius: "5px",
      padding: "5px 10px",
    },
  };

  return (
    <div className="d-sm-flex text-center justify-content-between align-items-center">
      <div className="dataTables_info ps-2">
        Showing {lastIndex - recordsPage + 1} to{" "}
        {dataLength < lastIndex ? dataLength : lastIndex} of {dataLength}{" "}
        entries
      </div>
      <div
        className="dataTables_paginate paging_simple_numbers justify-content-center"
        id="example2_paginate"
      >
        <ReactPaginate
          previousLabel={<GrFormPrevious />}
          nextLabel={<GrFormNext />}
          pageCount={count || 1}
          activeClassName="active"
          forcePage={currentPage !== 0 ? currentPage - 1 : 0}
          onPageChange={(page) => handlePagination(page)}
          pageClassName="page-item"
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          nextLinkClassName="page-link"
          nextClassName="page-item next"
          previousClassName="page-item prev"
          previousLinkClassName="page-link"
          pageLinkClassName="page-link"
          containerClassName="pagination pagination-xs react-paginate justify-content-end my-2 pe-1"
          renderOnZeroPageCount={null}
          render={(props) => (
            <span
              className="page-link"
              style={
                props.selected
                  ? { ...styles.pageLink, ...styles.activePage }
                  : styles.pageLink
              }
            >
              {props.children}
            </span>
          )}
        />
      </div>
    </div>
  );
};

export default Pagination;
