import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useWindowSize from "../../../../constant/WindowSize";
import { getOpenEvent } from "../../../../services/EventService";
import AllEvents from "./AllEvents";

const UserEventDetails = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  useEffect(() => {
    setIsLoading(true);
    getOpenEvent(id)
      .then((resp) => {
        setData(resp.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Event:", error);
        setData(null);
      });
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.state]);

  return (
    <>
      <div className="container-fluid mt-7 pb-6">
        <div className="d-flex align-items-center px-3 border-primary/50">
          <button
            type="button"
            className="btn btn-sm btn-outline-dark rounded-circle"
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </button>
          <h3 className="py-2 pl-2 text-xl font-bold">Event Details</h3>
        </div>
        <div className="mt-2">
          {isLoading ? (
            <div
              className="text-center my-7"
              style={{ width: "100%", height: "100%" }}
            >
              <div className="spinner-grow text-black me-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-black me-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-black me-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-black" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : data ? (
            <>
              <div className="h-[300px] overflow-hidden">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${data?.photo}`}
                  alt="No image"
                  className="mx-auto px-3 h-[300px] w-full object-cover transition duration-700 hover:scale-110"
                />
              </div>
              <div className="container ">
                <p className="text-slate-600 text-sm py-3">
                  {" "}
                  written by {data?.upload_by} on {data?.created_at}
                </p>
                <h1 className="text-2xl font-semibold">{data?.title}</h1>
                <p>{data?.description}</p>
              </div>
            </>
          ) : (
            <div
              className="card d-flex justify-content-center align-datas-center"
              style={{ height: "80vh", width: "100%" }}
            >
              <div className="text-center">
                <i
                  className="fa fa-bed fa-3x mb-2"
                  style={{ fontSize: "2rem", opacity: 0.5 }}
                ></i>
                <h3 style={{ opacity: 0.5 }}>Event details not available</h3>
              </div>
            </div>
          )}

          {/* All Blogs Section */}
          <AllEvents />
        </div>
      </div>
    </>
  );
};

export default UserEventDetails;