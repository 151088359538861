import React from "react";
import Location from "../Component/Location/Location";
import AllEvents from "../Component/Events/AllEvents";
import { useNavigate } from "react-router-dom";

const About = ({ isLoggedIn }) => {
  const navigate = useNavigate();

  const handleMembershipClick = () => {
    if (isLoggedIn) {
      navigate("/home");
    } else {
      navigate("/signup");
    }
  };
  return (
    <>
      <div className="container pt-6" style={{ fontFamily: "Georgia, serif", color: "#333" }}>
        <div className="py-3">
          <h1
            className="my-2 border-l-8 border-primary/50 py-2 pl-2 text-3xl font-bold"
            style={{
              fontSize: "2.5rem",
              borderLeft: "8px solid #ffcc00",
              paddingLeft: "15px",
              color: "#2c3e50",
              letterSpacing: "1px",
            }}
          >
            About Us
          </h1>
          <p
            style={{
              lineHeight: "1.8",
              fontSize: "1.1rem",
              textAlign: "justify",
              margin: "15px 0",
            }}
          >
            <strong>Rourkela Club</strong>, located within the vibrant premises of{" "}
            <strong>Rourkela Steel Plant</strong>, is a pristine oasis of relaxation and
            recreation, designed to provide an exceptional experience for employees and their families.{" "}
            <span style={{ color: "#5C1212", fontWeight: "bold" }}>Cleanliness</span> and{" "}
            <span style={{ color: "#5C1212", fontWeight: "bold" }}>impeccable service</span> lie at the
            heart of this club's philosophy, ensuring every visitor feels welcomed into an environment
            that balances modern comfort with nature’s serenity.
          </p>
          <p
            style={{
              lineHeight: "1.8",
              fontSize: "1.1rem",
              textAlign: "justify",
              margin: "15px 0",
              fontStyle: "italic",
            }}
          >
            What sets <strong>Rourkela Club</strong> apart is its{" "}
            <span style={{ color: "#5C1212", fontWeight: "bold" }}>eco-conscious approach</span>,
            seamlessly blending leisure with environmental stewardship. Surrounded by lush greenery, the club offers
            a tranquil atmosphere that rejuvenates the mind and body. Every corner of the club has been
            thoughtfully curated to minimize environmental impact through{" "}
            <span style={{ color: "#5C1212", fontWeight: "bold" }}>sustainable landscaping</span>,{" "}
            <span style={{ color: "#5C1212", fontWeight: "bold" }}>energy-efficient lighting</span>, and{" "}
            <span style={{ color: "#5C1212", fontWeight: "bold" }}>eco-friendly practices</span>.

          </p>
          <div
            style={{
              backgroundColor: "#f5f5f5",
              padding: "20px",
              borderRadius: "8px",
              marginBottom: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <p
              style={{
                lineHeight: "1.8",
                fontSize: "1.1rem",
                textAlign: "justify",
              }}
            >
              Welcome to Rourkela Club, where history, community, and a dash of luxury collide! 🌟 Born from the heart of Rourkela Steel Plant (SAIL), we’ve transformed from a modest social club into an epicenter of culture, fun, and creativity.Think we’re just another club? Think again! We’ve redefined what it means to be a social hub. From unforgettable events to vibrant community activities, we bring together the power of steel with the soul of Rourkela. It’s not just a club, it’s a movement. With lavish parties, sports, and more – we’re your ticket to experience everything bold, energetic, and out-of-the-box. Join us, and let’s keep shaping tomorrow’s legacies together!

              Where tradition meets tomorrow. 
              The club boasts a <strong style={{ color: "#2980b9" }}>picturesque view</strong>, with the sprawling
              expanse of the steel plant visible in the distance, contrasting beautifully with manicured
              lawns and vibrant flower beds. The gentle hum of the plant fades into the background, creating
              a sense of harmony between industrial progress and nature.{" "}
              <strong style={{ color: "#5C1212" }}>Members</strong> can enjoy these views from the club's elegantly
              designed patios and seating areas, where the fresh air and open skies evoke a sense of peace.
              🌍 🎉 #RourkelaClub #PowerOfSteel #SAILingToNewHeights
            </p>
          </div>
          <p
            style={{
              lineHeight: "1.8",
              fontSize: "1.1rem",
              textAlign: "justify",
            }}
          >
            Inside, Rourkela Club offers a variety of recreational activities, from{" "}
            <strong style={{ color: "#2980b9" }}>sports facilities</strong> to{" "}
            <strong style={{ color: "#2980b9" }}>social gatherings</strong>, all while upholding its dedication to{" "}
            <span style={{ color: "#5C1212", fontWeight: "bold" }}>cleanliness</span> and top-tier{" "}
            <span style={{ color: "#5C1212", fontWeight: "bold" }}>service</span>. Whether it’s a quiet evening by
            the garden or a lively event with colleagues, Rourkela Club ensures an experience that is not
            only enjoyable but also aligned with a commitment to{" "}
            <span style={{ color: "#5C1212", fontWeight: "bold" }}>sustainability</span> and{" "}
            <span style={{ color: "#5C1212", fontWeight: "bold" }}>well-being</span>.
          </p>
        </div>
      </div>

      {/* Adding a beautiful call to action */}
      <div
        style={{
          backgroundColor: "#5C1212", // Theme color
          color: "#fff",
          padding: "20px", // Reduced padding for a more compact design
          textAlign: "center",
          margin: "20px 0", // Reduced margin for compactness
          borderRadius: "8px", // Added border radius for a smoother look
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)", // Added shadow for depth
        }}
      >
        <h2
          style={{
            fontSize: "1.8rem", 
            marginBottom: "10px",
            fontWeight: "bold",
            textTransform: "uppercase",
            letterSpacing: "1px",
          }}
          className="text-white "
        >
          Join Us at Rourkela Club
        </h2>
        <p
          style={{
            fontSize: "1rem", 
            marginBottom: "15px",
            fontStyle: "italic", 
          }}
        >
          Experience the perfect blend of leisure, nature, and world-class service.
        </p>
        <button
          style={{
            backgroundColor: "#fff",
            color: "#5C1212",
            padding: "10px 20px",
            borderRadius: "5px",
            border: "none",
            fontSize: "1rem", 
            fontWeight: "bold",
            cursor: "pointer",
            transition: "background-color 0.3s ease, transform 0.2s",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
          }}
          onClick={handleMembershipClick}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "#f0f0f0"; 
            e.target.style.transform = "scale(1.05)"; 
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "#fff"; 
            e.target.style.transform = "scale(1)";
          }}
        >
          Explore Membership Options
        </button>
      </div>


      {/* Adding Location and Events section */}
      <Location />
      <AllEvents />
    </>
  );
};

export default About;
