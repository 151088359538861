import React, { useState } from "react";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { createMemberAPI } from "../../services/VerifyMemberService";
import { Modal } from "react-bootstrap";

const INPUT_FIELD_LIST = [
  {
    label: "First Name",
    name: "first_name",
    type: "text",
    placeholder: "Enter your first name",
  },
  {
    label: "Last Name",
    name: "last_name",
    type: "text",
    placeholder: "Enter your last name",
  },
  {
    label: "Membership Type",
    name: "membership_type",
    type: "select",
    placeholder: "Select membership type",
    options: [
      { label: "SAIL RSP Member", value: "SAIL RSP Member" },
      { label: "GOVERNMENT Member", value: "GOVERNMENT Member" },
      { label: "PRIVATE Member", value: "PRIVATE Member" },
      { label: "Honorary Member", value: "Honorary Member" },
    ],
  },
  {
    label: "Email ID",
    name: "email",
    type: "email",
    placeholder: "Enter your email address",
  },
  {
    label: "Phone Number",
    name: "phone_number",
    type: "number",
    placeholder: "Enter your phone number",
  },
  {
    label: "Password",
    name: "password",
    type: "password",
    placeholder: "Enter your password",
  },
];

const initialFormState = {
  first_name: "",
  last_name: "",
  membership_type: "",
  personnel_number: "",
  email: "",
  phone_number: "",
  password: "",
};

const AddMemberPopup = ({ data, setData }) => {
  const [addMemberButtonHover, setAddMemberButtonHover] = useState(false);
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState(initialFormState);
  const [showPersonalNo, setShowPersonalNo] = useState(false);

  const toggleForm = () => {
    if (isOffCanvasOpen) {
      setFormData(initialFormState);
      setErrors(initialFormState);
    }
    setIsOffCanvasOpen(!isOffCanvasOpen);
  };

  const handleValidate = (name, value) => {
    if (name === "email") {
      if (!value) {
        return "Email is required";
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        return "Invalid Email format.";
      }
    } else if (name === "phone_number") {
      if (!value) {
        return "Phone number is required";
      } else if (!/^\d{10}$/.test(value)) {
        return "Invalid phone number. It should be 10 digits.";
      }
    } else if (name === "password") {
      if (!value) {
        return "Password is required";
      } else if (
        !/(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}/.test(
          value
        )
      ) {
        return "Password must be at least 8 characters long, include one uppercase, one lowercase, one number, and one special character.";
      }
    } else if (
      name === "personnel_number" &&
      formData.membership_type === "SAIL RSP Member"
    ) {
      if (!value) {
        return "Personnel Number is required.";
      } else if (!/^\d{6}$/.test(value)) {
        return "Personnel Number must be a 6-digit number.";
      }
    } else {
      if (!value) {
        const errorMsgObj = {
          first_name: "First name",
          last_name: "Last name",
          membership_type: "Membership type",
        };
        return `${errorMsgObj[name]} is required`;
      }
    }
    return "";
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSubmitLoading) {
      return;
    }

    const isValidInput = Object.keys(formData).every((key) => {
      if (
        key === "personnel_number" &&
        formData.membership_type !== "SAIL RSP Member"
      ) {
        return true;
      }
      const error = handleValidate(key, formData[key]);
      if (error) {
        setErrors((prevErrors) => ({ ...prevErrors, [key]: error }));
        return false;
      }
      return true;
    });

    // Only submit if all validations pass
    if (isValidInput) {
      // Exclude personnel_number from the formData if membership type is not "SAIL RSP Member"
      const dataToSubmit = { ...formData };
      if (formData.membership_type !== "SAIL RSP Member") {
        delete dataToSubmit.personnel_number;
      }

      console.log("Submitting form data:", dataToSubmit); // Log form data for debugging
      setIsSubmitLoading(true);

      createMemberAPI(dataToSubmit)
        .then((resp) => {
          const { results, message } = resp.data;
          setData([results, ...data]);
          toast.success(message);
          toggleForm();
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsSubmitLoading(false);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "membership_type") {
      setShowPersonalNo(value === "SAIL RSP Member");
    }
    const error = handleValidate(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error })); // Update errors on change
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <Button
        className="px-3"
        onClick={toggleForm}
        style={{
          color: addMemberButtonHover ? "#d9cf4e" : "white",
          backgroundColor: addMemberButtonHover ? "#d9cf4e" : "rgb(19 19 ,19)",
          border: "none",
          transition: "background-color 0.3s ease, color 0.3s ease",
        }}
        onMouseEnter={() => setAddMemberButtonHover(true)}
        onMouseLeave={() => setAddMemberButtonHover(false)}
      >
        <i className="fa fa-user-plus"></i>
      </Button>
      {isOffCanvasOpen && (
        <div
          className={`offcanvas-container ${isOffCanvasOpen ? "open" : ""
            } mt-sm-0 mt-5`}
        >
          <div className="offcanvas-content">
            <div className="d-flex align-items-center justify-content-between">
              <h1>
                <strong>
                  {" "}
                  Add Member
                </strong>
              </h1>
              <button className="btn text-xl" onClick={toggleForm} >
                &times;
              </button>
            </div>
            {INPUT_FIELD_LIST.map(({ label, name, type, placeholder, options }) => (
              <React.Fragment key={name}>
                <div className="form-group px-2 py-1">
                  <label htmlFor={name} className="d-flex align-items-center">
                    {label}:
                    {name === "phone_number" && (
                      <div
                        className="input-group-text ms-2"
                        style={{
                          color: "black",
                          padding: "0.2rem 0.5rem",
                          fontSize: "0.85rem",
                          borderRadius: "4px",
                          backgroundColor: "#e9ecef",
                          border: "1px solid #ced4da",
                          marginLeft: "8px"  // Ensures there's space between label and +91
                        }}>
                        +91
                      </div>
                    )}
                  </label>

                  {type === "select" ? (
                    <div className="input-group">
                      <select
                        id={name}
                        name={name}
                        value={formData[name]}
                        onChange={handleChange}
                        className="custom-select"
                        onBlur={(e) => handleValidate(e.target.name, e.target.value)}
                      >
                        <option value="" disabled>
                          {placeholder}
                        </option>
                        {options.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                      {errors[name] && <div className="form-error">{errors[name]}</div>}
                    </div>
                  ) : (
                    <div className="input-group">
                      <input
                        type={type}
                        name={name}
                        value={formData[name]}
                        className={`form-control ${errors[name] ? "is-invalid" : ""}`}
                        onChange={handleChange}
                        onBlur={(e) => handleValidate(e.target.name, e.target.value)}
                        placeholder={placeholder}
                      />
                      {errors[name] && <div className="form-error">{errors[name]}</div>}
                    </div>
                  )}
                </div>

                {/* Insert Personnel Number directly after Membership Type */}
                {name === "membership_type" &&
                  formData.membership_type === "SAIL RSP Member" && (
                    <div className="form-group px-2 py-1">
                      <label htmlFor="personnel_number">Personnel Number:</label>
                      <div className="input-group mb-1">
                        <input
                          type="text"
                          name="personnel_number"
                          value={formData.personnel_number}
                          className={`form-control ${errors.personnel_number ? "is-invalid" : ""}`}
                          onChange={handleChange}
                          onBlur={(e) => handleValidate(e.target.name, e.target.value)}
                          placeholder="Enter Personnel Number"
                        />
                        {errors.personnel_number && (
                          <div className="form-error">{errors.personnel_number}</div>
                        )}
                      </div>
                    </div>
                  )}
              </React.Fragment>
            ))}


            <div className="btn-container">
              <button
                type="submit"
                className="btn text-white bg-black"
                disabled={isSubmitLoading}
                onClick={handleSubmit}
              >
                Create
              </button>
              <button type="button" onClick={toggleForm} className="btn btn-danger">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddMemberPopup;
