import { connect } from "react-redux";

const UserHeader = ({ userRole }) => {
  // Set background image based on user role
  const backgroundImage =
    userRole === "User"
      ? require("../../assets/img/UserPage/about3.gif")
      : require("../../assets/img/UserPage/about2.gif");

  return (
    <>
      <div
        className="header pb-3 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "300px",
          backgroundImage: `url(${backgroundImage})`, // Correct backgroundImage syntax
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        {/* Mask */}
        <span className={`mask bg-gradient-default ${userRole === "User" ? "opacity-1" :"opacity-6"} `} />
        {/* Header container */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  userRole: state.auth.user?.role || "User",
});

export default connect(mapStateToProps)(UserHeader);
