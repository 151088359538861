import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { Table, Modal, Button } from "react-bootstrap";
import LoadingSkeleton from "../Member/LoadingSkeleton";
import useWindowSize from "../../constant/WindowSize";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { updateMaintenanceRoom, checkRoomStatus, deleteRoom, futureStopBooking } from "../../services/RoomService";
import MemberDetailsPopup from "./MemberDetailsPopup";
import "./Room.css";
import TooltipComponent from "./TooltipComponent";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import EditBookingModal from "../BookingStatus/EditBooking";


function Details({ handleEditClick, data, historyData, isLoading }) {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [addToMaintenance, setAddToMaintenance] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const size = useWindowSize();
  const isMobile = size.width <= 1024;

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const roomId = data.id;
  const roomName = data.name;

  const [isFutureBookingEnabled, setIsFutureBookingEnabled] = useState(
    JSON.parse(localStorage.getItem(`room-${roomId}-isFutureBookingEnabled`)) || false
  );
  const [selectedDate, setSelectedDate] = useState(
    localStorage.getItem(`room-${roomId}-selectedDate`)
      ? new Date(localStorage.getItem(`room-${roomId}-selectedDate`))
      : null
  );
  const [showCalendar, setShowCalendar] = useState(false);


  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem(`room-${roomId}-booking`));
    if (savedState) {
      setIsFutureBookingEnabled(savedState.isEnabled);
      setSelectedDate(savedState.date ? new Date(savedState.date) : null);
    }
  }, [roomId]);

  // Save state when the future booking or selected date changes
  useEffect(() => {
    localStorage.setItem(
      `room-${roomId}-booking`,
      JSON.stringify({
        isEnabled: isFutureBookingEnabled,
        date: selectedDate ? selectedDate.toISOString() : null,
      })
    );
    // Store individual states for isEnabled and selectedDate too
    localStorage.setItem(`room-${roomId}-isFutureBookingEnabled`, JSON.stringify(isFutureBookingEnabled));
    if (selectedDate) {
      localStorage.setItem(`room-${roomId}-selectedDate`, selectedDate.toISOString());
    }
  }, [isFutureBookingEnabled, selectedDate, roomId]);

  // Update booking API call based on toggle or date selection
  const updateFutureBooking = async (isEnabled, date) => {
    try {
      if (isEnabled && date) {
        await futureStopBooking(roomId, true, date);
        toast.success(`Room Booking blocked successfully from date: ${date.toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })}`);
      } else if (!isEnabled) {
        await futureStopBooking(roomId, false);
        toast.info(`Future bookings disabled for room: ${roomName}`);
      }
    } catch (error) {
      toast.error(`Error updating room: ${error.message}`);
    }
  };


  const toggleBookingState = () => {
    const newState = !isFutureBookingEnabled;
    setIsFutureBookingEnabled(newState);

    if (newState) {
      setShowCalendar(true);
    } else {
      setSelectedDate(null);
      setShowCalendar(false);
      updateFutureBooking(newState);
    }
  };

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length > 0) {
      const newDate = selectedDates[0];
      setSelectedDate(newDate);
      setShowCalendar(false);

      if (isFutureBookingEnabled) {
        updateFutureBooking(true, newDate);
      }
    }
  };


  const handleCheckboxChange = async (event) => {
    const isChecked = event.target.checked;
    setAddToMaintenance(isChecked);
  
    if (isChecked) {
      setIsSubmitLoading(true);
      try {
        const response = await checkRoomStatus(data.id);
        if (response.data.results && response.data.results.length > 0) {
          setPopupData(response.data);  // Update the data only when necessary
          setShowPopup(true);
        } else {
          setShowPopup(false);
          setPopupData(null);
        }
      } catch (error) {
        console.error("Error fetching room status:", error);
        toast.error(error.response?.data?.message || "An error occurred");
      } finally {
        setIsSubmitLoading(false);
      }
    } else {
      setShowPopup(false);
      setPopupData(null);
    }
  };
  
const handlePopupClose = () => {
  setAddToMaintenance(false);
  setShowPopup(false);
  setPopupData(null);
};

const handleConfirmMaintenance = async (roomId, isMaintenance) => {
  setIsSubmitLoading(true);
  try {
    const maintenanceData = isMaintenance ? "YES" : "NO";
    const resp = await updateMaintenanceRoom(roomId, maintenanceData);

    if (resp.data.success) {
      toast.success(resp.data.message);
      setAddToMaintenance(false);
      navigate("/rooms");
    } else {
      setShowPopup(true);
      const bookings = resp.data.results;

      setPopupData({
        message: resp.data.message,
        bookings: bookings,
      });
    }
  } catch (error) {
    toast.error(error.response?.data?.message || "An error occurred");
  } finally {
    setIsSubmitLoading(false);
  }
};



const updateBookingData = (updatedBooking) => {
  setPopupData((prevData) => ({
    ...prevData,
    results: prevData.results.map((booking) =>
      booking.id === updatedBooking.id ? updatedBooking : booking
    ),
  }));
};

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };


  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleBookNow = (room) => {
    if (room?.id) {
      navigate(`/booking/${room.id}`, { state: { room } });
    } else {
      console.error("Room ID is missing");
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    setShowDeleteModal(false);
    try {
      const response = await deleteRoom(data.id);
      toast.success(response.data.message);
      navigate("/rooms");
    } catch (error) {

      toast.error(error.response?.data?.message || "Error deleting the room. Please try again.");
    }
  };


  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mx-sm-3 mx-0 mb-3">
        <div className="d-flex align-items-center">
          <button
            type="button"
            className="btn btn-sm border-black text-black rounded-circle"
            onClick={() => navigate(-1)}
          >
            <i className="fa-solid fa-arrow-left"></i>
          </button>
          <h3 className="primary">Room Details</h3>
        </div>
        <div className="d-flex justify-content-end">
          <button
            className="btn bg-black text-white px-3 me-2"
            onClick={handleEditClick}
          >
            <i className="fas fa-pencil-alt me-2"></i> Edit
          </button>
          <button
            className="btn btn-danger px-3"
            onClick={handleDeleteClick}
          >
            <i className="fas fa-trash-alt me-2"></i> Delete
          </button>
        </div>
      </div>
      <div
        className="p-sm-4 px-1 py-2 border rounded shadow"
        style={{ backgroundColor: "#ffffff", marginBottom: "20px" }}
      >
        <div className="row">
          <div className="col-md-5">
            <div className="d-flex">
              <Carousel
                showArrows
                autoPlay={false}
                infiniteLoop
                interval={3000}
                transitionTime={1000}
                className="w-100"
                showThumbs={isMobile ? false : true}
              >
                {data.roomphoto.map((photo) => (
                  <div key={photo.id}>
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${photo.photo}`}
                      alt={data.name}
                      className="img-fluid"
                    />
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-1">
              <h3
                className="room-name"
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                  fontSize: "2.2rem",
                  color: "black",
                  animation: "fadeInUp 1s ease-out",
                }}
              >
                {data?.name}

              </h3>

              <button
                className="text-white bg-black px-3 py-1 rounded duration-200"
                onClick={() => handleBookNow(data)}
              >
                Book Now
              </button>
            </div>


            <div className="mb-2">
              <strong style={{ color: "#333", fontSize: "0.9rem" }}>Pricing: <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                <TooltipComponent roomId={data?.id} roomName={data?.name} />
              </span></strong>
              <ul className="list-unstyled mt-1">
                {data?.room_pricing?.map((pricing) => (
                  <li
                    key={pricing.id}
                    className="d-flex justify-content-between border-bottom py-1"
                    style={{ fontSize: "0.85rem" }}
                  >
                    <span style={{ color: "#333", fontWeight: "bold" }}>
                      {pricing.purpose.name}
                    </span>
                    <span style={{ color: "#555" }}>
                      ₹{pricing.price.toLocaleString()}
                    </span>
                  </li>
                ))}
              </ul>
            </div>



            <div>
              <strong style={{ color: "#333" }}>Description: </strong>
              {isReadMore
                ? data?.description
                : `${data?.description?.substring(0, 35)}...`}
              <span
                onClick={toggleReadMore}
                style={{
                  color: "blue",
                  cursor: "pointer",
                  marginLeft: "5px",
                }}
              >
                {isReadMore ? "Show Less" : "Read More"}
              </span>
            </div>

            <div className="mb-3">
              <small className="border-end me-3 pe-3" style={{ color: "#777" }}>
                <i className="fa-regular fa-clock"></i> {data?.room_slot_type}
              </small>
              <small className="border-end me-3 pe-3" style={{ color: "#777" }}>
                <i className="fa-solid fa-bed"></i> {data?.room_type}
              </small>
            </div>
          </div>
          <div className="col-md-7">
            <div className="form-check form-check-inline mb-3" style={{ display: "flex", alignItems: "center" }}>
              <div>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="maintenance"
                  id="maintenance"
                  value="Maintenance"
                  checked={addToMaintenance}
                  onChange={handleCheckboxChange}
                />
                <label className="form-check-label" htmlFor="maintenance" style={{ marginRight: "10px", fontSize: "14px" }}>
                  Add To Maintenance
                </label>

                {addToMaintenance && (
                  popupData && popupData.results.length > 0 ? (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger ms-2"
                      onClick={handlePopupClose}
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-sm btn-success ms-2"
                      disabled={isSubmitLoading}
                      onClick={() => handleConfirmMaintenance(data.id, true)}
                    >
                      {isSubmitLoading ? (
                        <span>
                          <Spinner animation="border" size="sm" /> Confirming...
                        </span>
                      ) : (
                        "Confirm"
                      )}
                    </button>
                  )
                )}

                {popupData && popupData.results.length > 0 && (
                  <div className="text-danger mb-1" style={{ fontSize: "12px" }}>{popupData.message}</div>
                )}
              </div>


              <div style={{ position: "relative", display: "inline-block", marginLeft: "20px" }}>
                <label className="switch" style={{ marginRight: "10px", verticalAlign: "middle" }}>
                  <input
                    type="checkbox"
                    checked={isFutureBookingEnabled}
                    onChange={toggleBookingState}
                    style={{ display: "none" }}
                  />
                  <span className="slider"></span>
                </label>
                <span style={{ fontSize: "14px" }}>Stop Future Booking</span>

                {isFutureBookingEnabled && (
                  <>
                    <div className="calendar-box" onClick={() => setShowCalendar(true)}>
                      <div className="selected-date">
                        {selectedDate
                          ? `Selected Date: ${selectedDate.toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })}`
                          : "Choose a Date"}
                      </div>

                      {showCalendar && (
                        <Flatpickr
                          options={{
                            inline: true,
                            minDate: "today",
                            maxDate: new Date().fp_incr(90),
                            dateFormat: "Y-m-d",
                          }}
                          onChange={handleDateChange}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>

            </div>

            {addToMaintenance && popupData && popupData.results.length > 0 ? (
              <>
                <h4 className="upcoming-bookings-title" style={{ fontSize: "16px" }}>
                  Upcoming Bookings for: <b>{popupData.results[0].booking_slot.room.name}</b>
                </h4>
                <table className="booking-table">
                  <thead>
                    <tr>
                      <th>Booking ID</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Booking Date</th>
                      <th>Booking Time</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {popupData.results.map((booking) => (
                      <tr key={booking.id}>
                        <td>{booking.booking_number}</td>
                        <td>{`${booking.user.first_name} ${booking.user.last_name}`}</td>
                        <td>{booking.user.phone_number}</td>
                        <td>{booking.booking_slot.booking_date}</td>
                        <td>{booking.booking_slot.booking_time}</td>
                        <td>{booking.booking_net_amount}</td>
                        <td><EditBookingModal booking={booking} updateBookingData={updateBookingData} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                {historyData && historyData.length > 0 ? (
                  <>
                    <h6 className="mb-2 text-center">Booking History</h6>
                    <div className="history-table-wrapper">
                      <Table className="booking-table table align-items-center table-flush table-compact" responsive>
                        <thead className="booking-table-head">
                          <tr className="booking-table-header">
                            <th scope="col" className="py-1">Book ID</th>
                            <th scope="col" className="py-1">Name</th>
                            <th scope="col" className="py-1">Phone</th>
                            <th scope="col" className="py-1">BookDate</th>
                            <th scope="col" className="py-1">BookTime</th>
                            <th scope="col" className="py-1">Amount</th>

                          </tr>
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <LoadingSkeleton />
                          ) : (
                            historyData.map((item, index) => (
                              <tr key={index}>
                                <td className="text-left">{item.booking_number}</td>
                                <td className="text-left">
                                  {item.user?.first_name} {item.user?.last_name}
                                </td>

                                <td className="text-left">{item.user?.phone_number}</td>
                                <td className="text-left">{item.booking_slot?.booking_date}</td>
                                <td className="text-left">{item.booking_slot?.booking_time}</td>
                                <td className="text-left">{item.booking_net_amount}</td>

                              </tr>
                            ))
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </>
                ) : (
                  <p className="text-center">
                    <i className="fas fa-history" style={{ marginTop: "15rem" }}></i>
                    No booking history available.
                  </p>
                )}
              </>
            )}


          </div>
        </div>
      </div>

      <Modal show={showDeleteModal} size="sm" onHide={handleDeleteCancel}>
        <Modal.Body className="px-4 py-3">
          Are you sure you want to delete this room? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={handleDeleteCancel}>
            Cancel
          </Button>
          <Button variant="danger" size="sm" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {isPopupOpen && (
        <MemberDetailsPopup member={selectedMember} togglePopup={togglePopup} />
      )}
    </>
  );
}

export default connect(null, {})(Details);
