import axiosInstance from "./AxiosInstance";

export function getAllSalesReportList(query, year, month, start_date, end_date, exportReport) {
  return axiosInstance.get(
    `api/all_sales_report?query=${query}&year=${year}&month=${month}&start_date=${start_date}&end_date=${end_date}&export=${exportReport}`,
    {
      responseType: exportReport ? 'blob' : 'json'
    }
  );
}

export function getDailySalesReportList(page, page_size, query, year, month, start_date, end_date, exportReport) {
  return axiosInstance.get(
    `api/daily_sales_report?page=${page}&page_size=${page_size}&query=${query}&year=${year}&month=${month}&start_date=${start_date}&end_date=${end_date}&export=${exportReport}`,
    {
      responseType: exportReport ? 'blob' : 'json'
    }
  );
}

export function getDiscountReportList(query, year, month, start_date, end_date, exportReport) {
  return axiosInstance.get(
    `api/all_discount_report?query=${query}&year=${year}&month=${month}&start_date=${start_date}&end_date=${end_date}&export=${exportReport}`,
    {
      responseType: exportReport ? 'blob' : 'json'
    }
  );
}

export function getDetailDiscountReport(date) {
  return axiosInstance.get(`api/detail_discount_report?date=${date}`, {
    responseType: 'json',
  });
}
export function getRefundReportList(query, year, month, start_date, end_date, exportReport) {
  return axiosInstance.get(
    `api/all_refund_report?query=${query}&year=${year}&month=${month}&start_date=${start_date}&end_date=${end_date}&export=${exportReport}`,
    {
      responseType: exportReport ? 'blob' : 'json'
    }
  );
}
