// import React from "react";
// import TravelImg from "../../assets/travelbox.png";
// import { LuPartyPopper } from "react-icons/lu";
// import { FaHotel } from "react-icons/fa";
// import { FaWifi } from "react-icons/fa6";
// import { MdFastfood } from "react-icons/md";

// const Banner = () => {
//   return (
//     <div className="banner-container">
//       <div className="container">
//         <div className="banner-content">
//           {/* Image section */}
//           <div data-aos="flip-up">
//             <img
//               src={TravelImg}
//               alt="Travel"
//               className="banner-image hover:scale-105"
//             />
//           </div>
//           {/* Text content section */}
//           <div className="text-content">
//             <h1 data-aos="fade-up" className="banner-title">
//               Explore all corners of the world with Rourkela Club
//             </h1>
//             <p data-aos="fade-up" className="banner-description">
//               Join us in discovering the beauty of travel and creating unforgettable memories. Our club offers a plethora of activities, from exciting parties to relaxing getaways, ensuring every member finds joy and adventure.
//             </p>
//             <div data-aos="zoom-in" className="banner-features">
//               <div className="features-column">
//                 <div className="feature-item">
//                   <LuPartyPopper className="text-2xl rounded-full" style={{ color: "#ff6347" }} />
//                   <p className="feature-text">Party</p>
//                 </div>
//                 <div className="feature-item">
//                   <FaHotel className="text-2xl rounded-full" style={{ color: "#4682b4" }} />
//                   <p className="feature-text">Hotel</p>
//                 </div>
//               </div>
//               <div className="features-column">
//                 <div className="feature-item">
//                   <FaWifi className="text-2xl rounded-full" style={{ color: "#32cd32" }} />
//                   <p className="feature-text">Wi-fi</p>
//                 </div>
//                 <div className="feature-item">
//                   <MdFastfood className="text-2xl rounded-full" style={{ color: "#ff8c00" }} />
//                   <p className="feature-text">Foods</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Banner;

import React from "react";
import TravelImg from "../../assets/travelbox.png";
import { LuPartyPopper } from "react-icons/lu";
import { FaHotel } from "react-icons/fa";
import { FaWifi } from "react-icons/fa6";
import { MdFastfood } from "react-icons/md";

const Banner = () => {
  return (
    <>
      <div className="min-h-[550px] bg-gray-100">
        <div className="min-h-[550px] flex justify-center items-center backdrop-blur-xl py-12 sm:py-0 ">
          <div className="container">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 items-center">
              {/* Image section */}
              <div data-aos="flip-up">
                <img
                  src={TravelImg}
                  alt="biryani img"
                  className="max-w-[450px] h-[350px] w-full mx-auto drop-shadow-[5px_5px_12px_rgba(0,0,0,0.7)] object-cover"
                />
              </div>
              {/* text content section */}
              <div className="flex flex-col justify-center gap-6 sm:pt-0 lg:px-16">
                <h1
                  data-aos="fade-up"
                  className="text-3xl sm:text-4xl font-bold"
                >
                  Discover exclusive experiences at Rourkela Club!
                </h1>
                <p
                  data-aos="fade-up"
                  className="text-sm text-gray-500 tracking-wide leading-8"
                >
                  Rourkela Club combines luxury, leisure, and community,
                  offering exclusive experiences for members. With top-notch
                  amenities and events, it's the perfect place to relax,
                  socialize, and enjoy life to the fullest
                  <br />
                </p>
                <div data-aos="zoom-in" className="grid grid-cols-2 gap-6">
                  <div className="space-y-6">
                    <div className="flex items-center gap-4">
                      <LuPartyPopper className="text-2xl rounded-full" style={{ color: "#ff6347" }} />
                      <p>Party</p>
                    </div>
                    <div className="flex items-center gap-4">
                      <FaHotel className="text-2xl rounded-full " style={{ color: "#4682b4" }} />
                      <p>Hotel</p>
                    </div>
                  </div>
                  <div className="space-y-6">
                    <div className="flex items-center gap-4">
                      <FaWifi className="text-2xl rounded-full"style={{ color: "#32cd32" }} />
                      <p>Wi-fi</p>
                    </div>
                    <div className="flex items-center gap-4">
                      <MdFastfood className="text-2xl rounded-full" style={{ color: "#ff8c00" }} />
                      <p>Foods</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
