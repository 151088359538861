import React, { useState } from "react";
import Flatpickr from "react-flatpickr";
import { format } from "date-fns";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "react-bootstrap";
import { FaSyncAlt } from "react-icons/fa";
import "flatpickr/dist/themes/material_blue.css";

const DateRangeDropdown = ({ filter, setFilter, setSearchQuery }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [customDates, setCustomDates] = useState([null, null]);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const getDateRestrictions = () => {
    const path = window.location.pathname;
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
  
    if (path.includes("booking-list")) {
      return { maxDate: today };
    } else if (path.includes("booking-status")) {
      return { minDate: yesterday };
    }
    return {};
  };
  

  const path = window.location.pathname;
  const isBookingStatus = path.includes("booking-status");
  const predefinedFilters = [
    { label: "Today", value: "today", days: 0 },
    { label: isBookingStatus ? "Tomorrow" : "Yesterday", value: "yesterday", days: 1 },
    { label: isBookingStatus ? "Next 7 Days" : "Last 7 Days", value: isBookingStatus ? "next7days" : "last7days", days: 7 },
    { label: isBookingStatus ? "Next 15 Days" : "Last 15 Days", value: isBookingStatus ? "next15days" : "last15days", days: 15 },
    { label: isBookingStatus ? "Next 30 Days" : "Last 30 Days", value: isBookingStatus ? "next30days" : "last30days", days: 30 },
    { label: "This Month", value: "thisMonth", days: "thisMonth" },
    { label: isBookingStatus ? "Next Month" : "Last Month", value: isBookingStatus ? "nextMonth" : "lastMonth", days: "lastMonth" },
    { label: "Custom Range", value: "custom", days: null },
  ];

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleFilterSelect = (option) => {
    const today = new Date();
    setSelectedFilter(option.value);
    setShowCustomRange(false);

    if (option.value === "custom") {
      setShowCustomRange(true);
    } else {
      setShowCustomRange(false);
      let start, end;

      if (option.value === "today") {
        start = format(today, "yyyy-MM-dd");
        setFilter({ ...filter, start_date: start, end_date: "" });
      } else if (option.value === "yesterday") {
        const targetDate = isBookingStatus ? new Date(today) : new Date(today);
        targetDate.setDate(targetDate.getDate() - (isBookingStatus ? -1 : 1)); // Add one day if "Tomorrow"
        start = format(targetDate, "yyyy-MM-dd");
        setFilter({ ...filter, start_date: start, end_date: "" });
      } else if (option.value === "thisMonth") {
        start = format(new Date(today.getFullYear(), today.getMonth(), 1), "yyyy-MM-dd");
        end = format(new Date(today.getFullYear(), today.getMonth() + 1, 0), "yyyy-MM-dd");
        setFilter({ ...filter, start_date: start, end_date: end });
      } else if (option.value === "lastMonth") {
        start = format(new Date(today.getFullYear(), today.getMonth() - 1, 1), "yyyy-MM-dd");
        end = format(new Date(today.getFullYear(), today.getMonth(), 0), "yyyy-MM-dd");
        setFilter({ ...filter, start_date: start, end_date: end });
      } else if (option.value === "last7days") {
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - 6);
        start = format(startDate, "yyyy-MM-dd");
        end = format(today, "yyyy-MM-dd");
        setFilter({ ...filter, start_date: start, end_date: end });
    } else if (option.value === "last15days") {
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - 14);
        start = format(startDate, "yyyy-MM-dd");
        end = format(today, "yyyy-MM-dd");
        setFilter({ ...filter, start_date: start, end_date: end });
    } else if (option.value === "last30days") {
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - 29);
        start = format(startDate, "yyyy-MM-dd");
        end = format(today, "yyyy-MM-dd");
        setFilter({ ...filter, start_date: start, end_date: end });
    }
      else if (option.value === "next7days") {
        const startDate = new Date(today);
        start = format(today, "yyyy-MM-dd");
        startDate.setDate(today.getDate() + 6);
        end = format(startDate, "yyyy-MM-dd");
        setFilter({ ...filter, start_date: start, end_date: end });
    } else if (option.value === "nextMonth") { // Corrected condition for Next Month
        const startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1); // Start of Next Month
        start = format(startDate, "yyyy-MM-dd");
        const endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0); // End of Next Month
        end = format(endDate, "yyyy-MM-dd");
        setFilter({ ...filter, start_date: start, end_date: end });
    } else if (option.value === "next15days") {
      const startDate = new Date(today);
      start = format(today, "yyyy-MM-dd");
      startDate.setDate(today.getDate() + 14);
      end = format(startDate, "yyyy-MM-dd");
      setFilter({ ...filter, start_date: start, end_date: end });
  }  else if (option.value === "next30days") {
    const startDate = new Date(today);
    start = format(today, "yyyy-MM-dd");
    startDate.setDate(today.getDate() + 29);
    end = format(startDate, "yyyy-MM-dd");
    setFilter({ ...filter, start_date: start, end_date: end });
}
     else {
        const startDate = new Date(today);
        startDate.setDate(today.getDate() - option.days);
        start = format(startDate, "yyyy-MM-dd");
        end = format(today, "yyyy-MM-dd");
        setFilter({ ...filter, start_date: start, end_date: end });
      }
    }
  };

  const handleCustomDateChange = (dates) => {
    setCustomDates(dates);
    const start = dates[0] ? format(dates[0], "yyyy-MM-dd") : "";
    const end = dates[1] ? format(dates[1], "yyyy-MM-dd") : "";
    setFilter({ ...filter, start_date: start, end_date: end });
  };

  const resetToDefault = () => {
    setSelectedFilter(null);
    setFilter({ room_type: "", start_date: "", end_date: "" });
    setSearchQuery("");
    setCustomDates([null, null]);
  };

  const styles = {
    dateRangeFilter: {
      backgroundColor: "white",
      border: "1px solid #cad1d7",
      boxShadow: "none",
      color: "#8898aa",
      textAlign: "left",
    },
    refreshIcon: {
      marginRight: "8px",
      cursor: "pointer",
      fontSize: "15px",
      color: "#6c757d",
      transition: "color 0.3s ease",
    },
    refreshIconHover: {
      color: "#343a40",
    },
    dropdownWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      width:"355px"
    },
  };

  return (
    <div style={styles.dropdownWrapper}>
       {showCustomRange && (
        <Flatpickr
          value={customDates}
          onChange={handleCustomDateChange}
          className="form-control custom-flatpickr text-xs px-1"
          placeholder="Select Date Range"
          options={{
            mode: "range",
            dateFormat: "d-m-Y",
            static: true,
            ...getDateRestrictions(),
          }}
        />
      )}
      <FaSyncAlt
        style={styles.refreshIcon}
        className="ms-3"
        onClick={resetToDefault}
        title="Reset to Default"
        onMouseEnter={(e) =>
          (e.currentTarget.style.color = styles.refreshIconHover.color)
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.color = styles.refreshIcon.color)
        }
      />
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle style={styles.dateRangeFilter} className="text-sm" caret>
          {selectedFilter
            ? predefinedFilters.find(
                (filter) => filter.value === selectedFilter
              )?.label
            : "Select Date Range"}
        </DropdownToggle>
        <DropdownMenu className="text-xs">
          {predefinedFilters.map((filter) => (
            <DropdownItem
              className="text-xs"
              key={filter.value}
              onClick={() => handleFilterSelect(filter)}
            >
              {filter.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default DateRangeDropdown;
