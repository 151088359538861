import React, { useEffect, useState } from "react";
import RenderCalendar from "./RenderCalendar";
import DynamicSelectInput, {
  StaticSelectInput,
} from "../../../../constant/SelectInput";
import { getRoomAvailable } from "../../../../services/RoomService";

const BookingForm = ({
  room,
  bookInput,
  handleBook,
  calendarRef,
  setBookedDisableDateList,
  dateError,
  setPurposeError,
  purposeError,
  setDateError,
  setBookInput,
  onPurposeChange,
  isMobile,
  disabledDates,
  isBookingLoading,
  userRole,
  selectOptions,
}) => {
  const [availableData, setAvailableData] = useState([]);

  useEffect(() => {
    if (room?.id) {
      getRoomAvailable(room.id)
        .then((resp) => {
          const { results } = resp.data;
          setAvailableData(results);
          const bookedDate = results
            .filter(
              (item) =>
                item.status === "Booked" &&
                item.booking_time === bookInput.booking_time
            )
            .map((item) => item.booking_date);
          setBookedDisableDateList([...bookedDate]);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    }
  }, [room?.id, bookInput.booking_time]);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarElement = calendarRef.current;
      if (window.bootstrap && window.bootstrap.Tooltip) {
        const tooltipTriggerList = [].slice.call(
          calendarElement.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
      }
    }
  }, [availableData]);

  const handleTimeChange = (e) => {
    const value = e.target.value;
    setBookInput((prev) => {
      return { ...prev, booking_time: value };
    });
    setBookedDisableDateList(
      availableData
        .filter(
          (item) => item.status === "Booked" && item.booking_time === value
        )
        .map((item) => item.booking_date)
    );
  };

  const getDateStatus = (date, slotType) => {
    const found = availableData.find((item) => {
      const slotDate = new Date(item.booking_date);
      return (
        slotDate.toDateString() === date.toDateString() &&
        item.booking_time === slotType
      );
    });
    return found
      ? {
          status: found.status,
          username: `${found.booking_user?.first_name} ${found.booking_user?.last_name}`,
        }
      : {};
  };

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length > 0) {
      setBookInput((prev) => {
        return { ...prev, booking_date: selectedDates[0] };
      });
      setDateError(false);
    }
  };

  const handleSelectChange = (name, value) => {
    setBookInput((prev) => ({ ...prev, [name]: value }));
    if (name === "booking_purpose_id") {
      onPurposeChange(value);
      if (value) {
        setPurposeError(false);
      }
    }
  };

  return (
    <form onSubmit={(e) => handleBook(e)}>
      <div className="card-data flex-fill flex-vertical">
        {userRole === "User" ? (
          <>
            <div className=" mb-3 mb-sm-0">
              <label htmlFor="booking_time">
                <strong style={{ fontSize: "0.85em" }} className="me-1">
                  Booking time
                </strong>
                <span className="text-danger">*</span>
              </label>
              <div>
                {room?.room_slot_type === "Full Day" && (
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="booking_time"
                      id="wholeday"
                      value="09:00 AM to 11:00 PM"
                      checked={
                        room?.room_slot_type === "Full Day" ||
                        bookInput.booking_time === "09:00 AM to 11:00 PM"
                      }
                      onChange={handleTimeChange}
                    />
                    <label className="form-check-label ml-2" htmlFor="wholeday">
                      Full Day
                    </label>
                  </div>
                )}
                {room?.room_slot_type !== "Full Day" && (
                  <>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="booking_time"
                        id="morning"
                        value="09:00 AM to 03:00 PM"
                        checked={
                          bookInput.booking_time === "09:00 AM to 03:00 PM"
                        }
                        onChange={handleTimeChange}
                      />
                      <label
                        className="form-check-label me-sm-2 me-0"
                        htmlFor="morning"
                      >
                        9 AM - 3 PM
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="booking_time"
                        id="evening"
                        value="06:00 PM to 11:00 PM"
                        checked={
                          bookInput.booking_time === "06:00 PM to 11:00 PM"
                        }
                        onChange={handleTimeChange}
                      />
                      <label className="form-check-label" htmlFor="evening">
                        6 PM - 11 PM
                      </label>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="booking_date">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Booking Date
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <RenderCalendar
                  handleDateChange={handleDateChange}
                  bookingDate={bookInput.booking_date}
                  disableDateList={disabledDates}
                  calendarRef={calendarRef}
                  getDateStatus={getDateStatus}
                  slotType={bookInput.booking_time}
                  userRole={userRole}
                />
                {dateError && (
                  <div
                    className="text-danger mt-1"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please select a date.
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <label htmlFor="booking_purpose_id">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Booking Purpose
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <StaticSelectInput
                  parentClassName="mb-0 order"
                  setParentInputValue={handleSelectChange}
                  optionsList={selectOptions}
                  name={"booking_purpose_id"}
                  id={"booking_purpose_id"}
                  placeholder={"Select Booking Purpose"}
                />
                {purposeError && (
                  <div
                    className="text-danger mt-1"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please select a booking purpose.
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-md-6 mb-sm-0 mb-3">
                <label htmlFor="booking_time">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Booking time
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <div>
                  {room?.room_slot_type === "Full Day" ? (
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="booking_time"
                        id="wholeday"
                        value="09:00 AM to 11:00 PM"
                        checked={
                          room?.room_slot_type === "Full Day" ||
                          bookInput.booking_time === "09:00 AM to 11:00 PM"
                        }
                        onChange={handleTimeChange}
                      />
                      <label
                        className="form-check-label ml-2"
                        htmlFor="wholeday"
                      >
                        Full Day
                      </label>
                    </div>
                  ) : (
                    <>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="booking_time"
                          id="morning"
                          value="09:00 AM to 03:00 PM"
                          checked={
                            bookInput.booking_time === "09:00 AM to 03:00 PM"
                          }
                          onChange={handleTimeChange}
                        />
                        <label
                          className="form-check-label me-sm-2 me-0"
                          htmlFor="morning"
                        >
                          9 AM - 3 PM
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="booking_time"
                          id="evening"
                          value="06:00 PM to 11:00 PM"
                          checked={
                            bookInput.booking_time === "06:00 PM to 11:00 PM"
                          }
                          onChange={handleTimeChange}
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor="evening"
                        >
                          6 PM - 11 PM
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <label htmlFor="booking_date">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Booking Date
                  </strong>
                  <span className="text-danger">*</span>
                </label>

                <RenderCalendar
                  handleDateChange={handleDateChange}
                  bookingDate={bookInput.booking_date}
                  disableDateList={disabledDates}
                  calendarRef={calendarRef}
                  getDateStatus={getDateStatus}
                  slotType={bookInput.booking_time}
                  userRole={userRole}
                />
                {dateError && (
                  <div
                    className="text-danger mt-1"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please select a date.
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3 mb-sm-0">
                <label htmlFor="booking_purpose_id">
                  <strong style={{ fontSize: "0.85em" }} className="me-1">
                    Booking Purpose
                  </strong>
                  <span className="text-danger">*</span>
                </label>
                <StaticSelectInput
                  parentClassName="mb-0 order"
                  setParentInputValue={handleSelectChange}
                  optionsList={selectOptions}
                  name={"booking_purpose_id"}
                  id={"booking_purpose_id"}
                  placeholder={"Select Booking Purpose"}
                />
                {purposeError && (
                  <div
                    className="text-danger mt-1"
                    style={{ fontSize: "0.8rem" }}
                  >
                    Please select a booking purpose.
                  </div>
                )}
              </div>
              {userRole !== "User" && (
                <div className="col-md-6">
                  <label htmlFor="on_behalf_id">
                    <strong style={{ fontSize: "0.85em" }} className="me-1">
                      On Behalf Member
                    </strong>
                    <span className="text-gray-500">(Optional)</span>
                  </label>
                  <DynamicSelectInput
                    parentClassName="mb-0 order"
                    setParentInputValue={handleSelectChange}
                    endpoint_name="onbehalf_member"
                    name={"on_behalf_id"}
                    id={"on_behalf_id"}
                    placeholder={"Select Member"}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {!isMobile && (
        <button
          type="submit"
          className="btn bg-black text-white w-full py-2"
          disabled={isBookingLoading}
        >
          Proceed For Payment
        </button>
      )}
    </form>
  );
};

export default BookingForm;
