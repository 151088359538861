import axios from "axios";
const IS_HEADER_TOKEN = process.env.REACT_APP_HEADER_TOKEN;
const Token = localStorage.getItem("TOKEN") || "";
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL,
  headers:
    IS_HEADER_TOKEN === "LOCALSTORAGE" && Token
      ? {
          Authorization: `Bearer ${Token}`,
        }
      : {},
});

let cancelTokenSource;

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.method === "get") {
      const URL = config.url.split("?")[0];
      let lastUrl = sessionStorage.getItem("last_url");

      if (lastUrl && lastUrl === URL) {
        if (cancelTokenSource) {
          cancelTokenSource.cancel("Request was canceled");
        }
        cancelTokenSource = axios.CancelToken.source();
        config.cancelToken = cancelTokenSource.token;
      } else {
        sessionStorage.setItem("last_url", URL);
        cancelTokenSource = axios.CancelToken.source();
        config.cancelToken = cancelTokenSource.token;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if ("Request was canceled" === error?.message) {
      return;
    }
    
    return Promise.reject(error);
  }
);

export function CancelRequest() {
  if (cancelTokenSource) {
    cancelTokenSource.cancel("Request was canceled");
  }
}

export default axiosInstance;
