import React, { useRef, useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import 'flatpickr/dist/flatpickr.css'; // Import Flatpickr styles

const RenderCalendar = ({
  handleDateChange,
  bookingDate,
  disableDateList,
  getDateStatus,
  slotType,
  userRole
}) => {
  const today = new Date();
  const maxDate = new Date();
  maxDate.setDate(today.getDate() + 90);

  const inputRef = useRef(null);
  const [calendarPosition, setCalendarPosition] = useState('below');

  const checkCalendarPosition = () => {
    if (inputRef.current) {
      const inputElement = inputRef.current.input;
      const inputRect = inputElement?.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const spaceBelow = windowHeight - inputRect?.bottom; // Space below the input
      const spaceAbove = inputRect?.top; // Space above the input

      const calendarHeight = 300; // Approximate height of the calendar

      if (spaceBelow < calendarHeight && spaceAbove >= calendarHeight) {
        setCalendarPosition('above');
      } else {
        setCalendarPosition('below');
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      checkCalendarPosition();
    };

    checkCalendarPosition(); // Initial check
    window.addEventListener('resize', checkCalendarPosition);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', checkCalendarPosition);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="relative">
      <Flatpickr
        ref={inputRef}
        value={bookingDate}
        onChange={handleDateChange}
        className="form-control custom-flatpickr w-full mx-auto border border-gray-800 bg-transparent rounded-md shadow-sm focus:outline-none focus:ring-2"
        placeholder="Select booking date"
        options={{
          dateFormat: "Y-m-d",
          minDate: "today",
          maxDate: maxDate,
          showMonths: 1,
          altInput: true,
          altFormat: "d/m/Y",
          disableMobile: true,
          disable: disableDateList,
          onDayCreate: (dObj, dStr, fp, dayElem) => {
            const { status, username } = getDateStatus(
              dayElem.dateObj,
              slotType
            );

            if (status === "Open") {
              dayElem.classList.add("open"); 
            } else if (status === "Pending") {
              dayElem.classList.add("flatpickr-disabled");
            } else if (status === "Booked") {
              dayElem.classList.add("booked");
              if (userRole === "SuperAdmin" || userRole === "Admin") {
                dayElem.setAttribute("data-bs-toggle", "tooltip");
                dayElem.setAttribute("data-bs-placement", "top");
                dayElem.setAttribute("title", `Booked by: ${username}`);
              }
            }
          },
          onOpen: () => {
            checkCalendarPosition(); 
            const calendarElement = document.querySelector('.flatpickr-calendar');
            if (calendarElement) {
              if (calendarPosition === 'above') {
                calendarElement.classList.add('above');
                calendarElement.classList.remove('below');
              } else {
                calendarElement.classList.add('below');
                calendarElement.classList.remove('above');
              }
            }
          },
          onClose: () => {
            const calendarElement = document.querySelector('.flatpickr-calendar');
            if (calendarElement) {
              calendarElement.classList.remove('above', 'below'); // Clean up classes
            }
          },
        }}
      />
    </div>
  );
};

export default RenderCalendar;
