import axiosInstance from "./AxiosInstance";

export function getSelectOptionList(
  endPoint,
  page = 1,
  page_size = 20,
  query = ""
) {
  return axiosInstance.get(
    `api/${endPoint}`
  );
}

// export function getMemberNameList() {
//   return axiosInstance.get("api/onbehalf_member");
// }