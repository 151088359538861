import React from "react";
import { useNavigate } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa"; 
import "./Footer.css";

const Footer = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="footer py-2 bg-black text-white mb-sm-0 mb-5">
        <div className="footer-content">
          <p className="mb-1">
            © Copyright
            <a
              href="http://intelisparkz.com/"
              target="_blank"
              rel="noreferrer"
              className="ms-1 me-1 text-blue-400 hover:text-blue-600 transition-colors duration-300"
            >
              Intelisparkz
            </a>
            {currentYear}
            <span className="mx-2">|</span>
            All Rights Reserved.
          </p>
          <div className="footer-links">
            <span
              className="text-xs text-muted cursor-pointer hover:underline hover:text-blue-600 transition-all duration-300"
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </span>
            <span className="mx-2">|</span>
            <span
              className="text-xs text-muted cursor-pointer hover:underline hover:text-blue-600 transition-all duration-300"
              onClick={() => navigate("/refund-policy")}
            >
              Refund
            </span>
            <span className="mx-2">|</span>
            <span
              className="text-xs text-muted cursor-pointer hover:underline hover:text-blue-600 transition-all duration-300"
              onClick={() => navigate("/terms")}
            >
              Terms
            </span>
          </div>
          <div className="social-links">
            <a
              href="https://www.facebook.com/SAILsteelofficial"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
            >
              <FaFacebook />
            </a>
            <a
              href="https://x.com/SAILsteel"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
            >
              <FaTwitter />
            </a>
            <a
              href="https://www.instagram.com/steelauthority?igsh=dWZyNXN4dDZvODRy"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/steel-authority-of-india-limited/?originalSubdomain=in"
              target="_blank"
              rel="noreferrer"
              className="social-icon"
            >
              <FaLinkedin />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
