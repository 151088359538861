import axiosInstance from "./AxiosInstance";

const apiNotification = "api/notification";

export function getNotification(page, page_size) {
  return axiosInstance.get(
    `${apiNotification}?page=${page}&page_size=${page_size}`
  );
}

export function markNotificationsAsRead(data) {
  return axiosInstance.put(apiNotification, data);
}

export function markOneNotificationAsRead(data) {
  return axiosInstance.patch(apiNotification, data)
}
