import React, { useState} from "react";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";
import { Tooltip } from "reactstrap";
import {
  verifyMemberAPI,
  generateMembershipIdAPI,
  updateMembershipIdAPI,
  getAvailableMembershipIdsAPI,
} from "../../services/VerifyMemberService";
import "./memberCustom.css";

const VerifyMemberPopup = ({ member, data, setData }) => {
  const [showForm, setShowForm] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [membershipType, setMembershipType] = useState(member.membership_type || "SAIL RSP Member");
  const [customMembershipId, setCustomMembershipId] = useState(member.membership_id || "");
  const [availableMembershipIds, setAvailableMembershipIds] = useState([]);
  const [isEditingMembershipId, setIsEditingMembershipId] = useState(false);
  const [editTooltipOpen, setEditTooltipOpen] = useState(false);
  const [skipTooltipOpen, setSkipTooltipOpen] = useState(false);
  const toggleEditTooltip = () => setEditTooltipOpen(!editTooltipOpen);
  const toggleSkipTooltip = () => setSkipTooltipOpen(!skipTooltipOpen);

  const personnelNumber = member.personnel_number || "";
  const [membershipIdPrefix, setMembershipIdPrefix] = useState("");
  const [membershipIdNumber, setMembershipIdNumber] = useState("");

  // Toggle the form visibility
  const toggleForm = () => {
    setShowForm((prev) => !prev);

    if (!showForm) {
      setMembershipType(member.membership_type || "SAIL RSP Member");
      const membershipTypePayloadMap = {
        "SAIL RSP Member": "RSP",
        "GOVERNMENT Member": "RSP",
        "PRIVATE Member": "RSP",
        "Honorary Member": "HON",
      };
      const mappedMembershipType = membershipTypePayloadMap[membershipType] || membershipType;

      generateMembershipIdAPI(mappedMembershipType)
        .then((resp) => {
          const fullId = resp.data.results;
          setCustomMembershipId(fullId);
          const prefix = fullId.startsWith("HON") ? "HON" : "RSP";
          const number = fullId.slice(prefix.length);
          setMembershipIdPrefix(prefix);
          setMembershipIdNumber(number);
        })
        .catch(() => {
          toast.error("Failed to generate membership ID");
        });

      getAvailableMembershipIdsAPI()
        .then((response) => {
          if (response.data && Array.isArray(response.data.results)) {
            setAvailableMembershipIds(response.data.results);
          } else {
            throw new Error("Unexpected data structure for available IDs");
          }
        })
        .catch(() => {
          toast.error("Failed to fetch available membership IDs");
        });
    }
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    if (isSubmitLoading) return;

    setIsSubmitLoading(true);
    const finalMembershipId = `${membershipIdPrefix}${membershipIdNumber}`;
    const formData = {
      membership_type: membershipType,
      member_id: member.id,
      membership_id: finalMembershipId,
    };

    verifyMemberAPI(formData)
      .then((resp) => {
        const { results, message } = resp.data;
        const updatedData = data.map((d) => (d.id === results.id ? results : d));
        setData(updatedData);
        toast.success(message);
        toggleForm();
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error.response ? error.response.data.message : "Verification failed");
      })
      .finally(() => {
        setIsSubmitLoading(false);
      });
  };

  // Handle skip action
  const handleSkipClick = () => {
    const finalMembershipId = `${membershipIdPrefix}${membershipIdNumber}`;
    updateMembershipIdAPI(finalMembershipId)
      .then(() => {
        toast.success("Membership ID skipped and updated successfully!");

        const membershipTypePayloadMap = {
          "SAIL RSP Member": "RSP",
          "GOVERNMENT Member": "RSP",
          "PRIVATE Member": "RSP",
          "Honorary Member": "HON",
        };
        const mappedMembershipType = membershipTypePayloadMap[membershipType] || membershipType;

        return generateMembershipIdAPI(mappedMembershipType);
      })
      .then((resp) => {
        const fullId = resp.data.results;
        setCustomMembershipId(fullId);
        const prefix = fullId.startsWith("HON") ? "HON" : "RSP";
        const number = fullId.slice(prefix.length);
        setMembershipIdPrefix(prefix);
        setMembershipIdNumber(number);

        return getAvailableMembershipIdsAPI();
      })
      .then((response) => {
        if (response.data && Array.isArray(response.data.results)) {
          setAvailableMembershipIds(response.data.results);
        }
      })
      .catch(() => {
        toast.error("Failed to update membership ID");
      });
  };

  // Handle edit button click
  const handleEditClick = () => {
    setIsEditingMembershipId(true);
  };

  const handleCancel = () => {
    toggleForm();
  };

  // Handle change in the numeric part of the membership ID
  const handleMembershipNumberChange = (e) => {
    if (isEditingMembershipId) {
      setMembershipIdNumber(e.target.value);
    }
  };

  // Handle selecting a reserved membership ID
  const handleMembershipIdSelection = (e) => {
    const selectedId = e.target.value;
    setCustomMembershipId(selectedId);
    const prefix = selectedId.startsWith("HON") ? "HON" : "RSP";
    const number = selectedId.slice(prefix.length);
    setMembershipIdPrefix(prefix);
    setMembershipIdNumber(number);
  };

  const filteredMembershipIds = availableMembershipIds.filter((id) => {
    if (membershipType === "Honorary Member") {
      return id.membership_id.startsWith("HON");
    }
    return !id.membership_id.startsWith("HON");
  });

  return (
    <div>
      {member.is_verified ? (
        <Button className="btn-sm" style={{ color: "white", backgroundColor: "green", border: "none" }}>
          VERIFIED
        </Button>
      ) : (
        <Button className="btn-sm" onClick={toggleForm} style={{ color: "white", backgroundColor: "grey", border: "none" }}>
          PENDING
        </Button>
      )}

      {showForm && (
        <div className="custom-popup">
          <form className="custom-popup-inner" onSubmit={handleSubmit}>
            <div className="custom-popup-header">
              <h2 className="">Verify Member</h2>
            </div>
            <div className="custom-popup-body p-3">
              <div className="custom-form-group">
                <label htmlFor="membershipType">Membership Type:</label>
                <input
                  type="text"
                  id="membershipType"
                  value={membershipType}
                  className="custom-select"
                  readOnly
                  style={{ backgroundColor: '#f0f0f0', color: '#333', border: '1px solid #ccc', padding: '0.375rem 0.75rem' }}
                />
              </div>

              {membershipType === "SAIL RSP Member" && (
                <div className="custom-form-group">
                  <label htmlFor="personnelNumber">Personnel Number:</label>
                  <input
                    type="text"
                    id="personnelNumber"
                    value={personnelNumber}
                    className="custom-form-control"
                    readOnly
                  />
                </div>
              )}

              <div className="custom-form-group">
                <label htmlFor="membershipId">Membership ID:</label>
                <div className="input-group" style={{ position: 'relative' }}>
                  {/* Prefix (Uneditable) */}
                  <input
                    type="text"
                    id="membershipIdPrefix"
                    value={membershipIdPrefix}
                    className="custom-form-control"
                    readOnly
                    style={{ width: '25%', backgroundColor: '#f0f0f0', borderRight: 'none' }}
                  />
                  {/* Editable Numeric Part */}
                  <input
                    type="text"
                    id="membershipIdNumber"
                    value={membershipIdNumber}
                    className="custom-form-control"
                    readOnly={!isEditingMembershipId}
                    onChange={handleMembershipNumberChange}
                    style={{ width: '50%', borderLeft: 'none' }}
                  />

                  {/* Edit and Skip Icons */}
                  {/* Edit and Skip Icons */}
                  <div className="tooltips-container">
                    {/* Edit Icon */}
                    <span
                      id="editIcon"
                      className="edit-icon-container"
                      onClick={handleEditClick}
                      style={{
                        position: "absolute",
                        right: "5rem",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      <FaEdit size={18} />
                    </span>
                    <Tooltip
                      placement="top"
                      isOpen={editTooltipOpen}
                      target="editIcon"
                      toggle={toggleEditTooltip}
                    >
                      Once editing is enabled, you cannot skip the value anymore.
                    </Tooltip>

                    {/* Skip Icon */}
                    {!isEditingMembershipId && (
                      <>
                        <span
                          id="skipIcon"
                          className="skip-link"
                          onClick={handleSkipClick}
                          onMouseEnter={(e) => {
                            e.target.style.transform = "scale(1.05) translateY(-50%)";
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.transform = "translateY(-50%)";
                          }}
                          style={{
                            position: "absolute",
                            right: "1.2rem",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            fontWeight: "bold",
                            backgroundColor: "brown",
                            color: "white",
                            padding: "5px 10px",
                            borderRadius: "4px",
                            transition: "transform 0.2s ease",
                          }}
                        >
                          Skip
                        </span>
                        <Tooltip
                          placement="top"
                          isOpen={skipTooltipOpen}
                          target="skipIcon"
                          toggle={toggleSkipTooltip}
                        >
                          You can skip the membership ID and reserve it for future VIP members.
                        </Tooltip>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="custom-form-group">
                <label htmlFor="reservedMembershipId">Reserved Membership IDs:</label>
                <select
                  id="reservedMembershipId"
                  value={customMembershipId}
                  onChange={handleMembershipIdSelection}
                  className="custom-select"
                >
                  <option value="">Select an ID</option>
                  {filteredMembershipIds.map((id) => (
                    <option key={id.membership_id} value={id.membership_id}>
                      {id.membership_id}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="custom-popup-footer d-flex justify-content-center mb-2">
              <button
                type="submit"
                className="btn mr-2"
                disabled={isSubmitLoading}
                style={{
                  backgroundColor: "black",
                  color: "white",
                  border: "none",
                }}
              >
                {isSubmitLoading ? "Submitting..." : "Submit"}
              </button>

              <button
                type="button"
                className="btn"
                onClick={handleCancel}
                style={{
                  backgroundColor: "#dc3545",
                  color: "white",
                  border: "none",
                }}
              >
                Cancel
              </button>
            </div>


          </form>
        </div>
      )}
    </div>
  );
};

export default VerifyMemberPopup;
