import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmRefundRoomBooking } from "../../services/RoomService";

const UserRefundRequest = ({ booking, updateBookingData, handleClose }) => {
  const [showCancelBooking, setShowCancelBooking] = useState(false);
  const [bookingToCancel, setBookingToCancel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentSource, setPaymentSource] = useState("");
  const [paymentTransactionID, setTransactionID] = useState("");
  const [transactionIDError, setTransactionIDError] = useState("");
  const [screenshot, setScreenshot] = useState(null);

  const handleCancelShow = () => {
    setBookingToCancel(booking);
    setShowCancelBooking(true);
  };

  const validateTransactionID = (transactionID) => {
    const regex = /^[a-zA-Z0-9_]{5,}$/;
    return regex.test(transactionID);
  };

  const handleFileChange = (e) => {
    setScreenshot(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateTransactionID(paymentTransactionID)) {
      setTransactionIDError(
        "Invalid Transaction ID. It must be at least 5 alphanumeric characters."
      );
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("paymentSource", paymentSource);
      formData.append("refund_id", booking.refund?.id);
      formData.append("paymentTransactionID", paymentTransactionID);

      if (screenshot) {
        formData.append("attachment", screenshot);
      }

      const resp = await confirmRefundRoomBooking(formData);
      updateBookingData(resp.data.results);
      toast.success(resp.data.message);
      handleClose();
      setShowCancelBooking(false);
      resetForm();
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setPaymentSource("");
    setScreenshot(null);
    setTransactionID("");
    setTransactionIDError("");
  };

  return (
    <>
      <button className="btn btn-sm btn-danger mb-1" onClick={handleCancelShow}>
        <i className="fa-solid fa-pen-to-square"></i> Refund
      </button>

      <Modal
        show={showCancelBooking}
        onHide={() => setShowCancelBooking(false)}
        centered
      >
        <Modal.Body className="p-3">
          {/* Booking Details Box */}
          {/* Compact Booking Details Card */}
          <div className="compact-booking-card p-3 rounded shadow">
            <h2 className="text-center mb-3">Booking Details</h2>
            <div className="details-row">
              <div className="detail-item">
                <i className="fa fa-bookmark icon"></i>
                <span className="label">Booking No:</span>
                <span className="value">{booking?.booking_number}</span>
              </div>
              <div className="detail-item">
                <i className="fa fa-home icon"></i>
                <span className="label">Room:</span>
                <span className="value">{booking?.booking_slot?.room.name}</span>
              </div>
            </div>
            <div className="details-row">
              <div className="detail-item">
                <i className="fa fa-user icon"></i>
                <span className="label">Member Name:</span>
                <span className="value">{booking?.user?.first_name} {booking?.user?.last_name}</span>
              </div>
              <div className="detail-item">
                <i className="fa fa-phone icon"></i>
                <span className="label">Phone:</span>
                <span className="value">{booking?.user?.phone_number}</span>
              </div>
            </div>
            <div className="details-row">
              <div className="detail-item">
                <i className="fa fa-money-bill icon"></i>
                <span className="label">Refund Amount:</span>
                <span className="value">₹{booking?.booking_net_amount}</span>
              </div>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="payment-source" className="form-label">
                Payment Source
              </label>
              <div className="mb-3">
                <select
                  id="payment-source"
                  className="form-select"
                  value={paymentSource}
                  onChange={(e) => setPaymentSource(e.target.value)}
                  aria-label="Select Payment Source"
                >
                  <option value="">Select Payment Source</option>
                  <option value="Cash">Cash</option>
                  <option value="Cheque">Cheque</option>
                  <option value="DebitCard">DebitCard</option>
                  <option value="UPI">UPI</option>
                  <option value="NEFT">NEFT</option>
                  <option value="IMPS">IMPS</option>
                </select>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="transaction-id" className="form-label">
                Transaction ID <span>*</span>
              </label>
              <input
                id="transaction-id"
                type="text"
                className="form-control"
                placeholder="Enter Transaction ID"
                value={paymentTransactionID}
                onChange={(e) => {
                  setTransactionID(e.target.value);
                  setTransactionIDError("");
                }}
              />
              {transactionIDError && (
                <small className="text-danger">{transactionIDError}</small>
              )}
            </div>
            <div className="mb-5">
              <label htmlFor="screenshot">
                <strong style={{ fontSize: "0.85em" }} className="me-1">
                  Upload Screenshot 
                </strong>
              </label>
              <input
                type="file"
                id="screenshot"
                className="form-control"
                onChange={handleFileChange}
         
              />
            </div>

            <div className="d-flex align-items-center justify-content-center mb-3">
              <Button
                type="submit"
                className="btn btn-success btn-block"
                disabled={loading}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <>
                    <i className="fas fa-paper-plane"></i> Refund
                  </>
                )}
              </Button>
              <Button
                type="button"
                className="btn btn-danger btn-block me-2"
                onClick={() => setShowCancelBooking(false)}
              >
                Cancel
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserRefundRequest;
