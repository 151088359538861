import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import ResponsiveMenu from "./ResponsiveMenu";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Nav,
} from "reactstrap";
import { getUserNameIcon } from "../../../../utils";
import { HiMenuAlt1 } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { logoutAction } from "../../../../store/actions/AuthActions";
import LogoutModal from "../../../Logout/Logout";
import useWindowSize from "../../../../constant/WindowSize";
import "./ResponsiveMenu.css";

export const NavbarLinks = [
  {
    name: "Home",
    link: "/",
    icon: "fa-solid fa-house",
  },
  {
    name: "About",
    link: "/about",
    icon: "fas fa-info-circle",
  },
  {
    name: "Rooms",
    link: "/club-rooms",
    icon: "fas fa-bed",
  },
  {
    name: "Events",
    link: "/events",
    icon: "fas fa-mountain",
  },
];

const Navbar = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const size = useWindowSize();
  const isMobile = size.width <= 768;

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  const toggleExitModal = () => setExitModalOpen(!exitModalOpen);

  function onLogout() {
    dispatch(logoutAction(navigate));
    toggleExitModal();
  }

  const handleProfileClick = () => {
    navigate("/user-profile");
  };

  // Handle background color change on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isHomePage = location.pathname === "/";

  return (
    <>
      <nav
        className={`fixed top-0 right-0 w-full z-50 backdrop-blur-sm transition-all duration-300 ${
          isHomePage
            ? scrolled
              ? "bg-white text-black shadow-md"
              : "bg-transparent text-white"
            : "bg-white text-black shadow-md"
        }`}
      >
        <div className="container-fluid px-4 py-1 transition-all duration-300">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-4 font-bold text-2xl">
              <div
                className="navbar-brand m-0 p-0 d-flex align-items-center cursor-pointer"
                onClick={() => navigate(`/`)}
              >
                <img
                  className="me-1 header-logo h-14"
                  alt="..."
                  src={require("../../../../assets/img/brand/logo.png")}
                />
                <h1
                  className={`m-0 light text-uppercase transition-all duration-300 ${
                    scrolled || !isHomePage ? "text-black" : "text-white"
                  }`}
                >
                  Rourkela CLUB
                </h1>
              </div>
            </div>
            {!isMobile && (
              <div>
              <ul className="flex items-center gap-6">
                {NavbarLinks.map((navItem) => (
                  <li className="py-2" key={navItem.name}>
                    <NavLink
                      to={navItem.link}
                      className={`navitemuser-active transition-all duration-300 hover:text-primary ${
                        scrolled || !isHomePage ? "text-black" : "text-white"
                      }`}
                    >
                      <i className={`${navItem.icon} me-2`}></i>
                      {navItem.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            )}
            <div className="flex items-center gap-4">
            {isMobile ? (<div>
                {showMenu ? (
                  <RxCross2
                    onClick={toggleMenu}
                    className="cursor-pointer transition-all text-lg"
                    size={30}
                  />
                ) : (
                  <HiMenuAlt1
                    onClick={toggleMenu}
                    className="cursor-pointer transition-all text-lg"
                    size={30}
                  />
                )}
              </div>) : ( <div>
                {user === null ? (
                  <div className="d-flex">
                    <Link to="/login" className="text-lg transition-all duration-300 hover:text-primary">
                      <i className="fa-regular fa-user"></i>
                    </Link>
                  </div>
                ) : (
                  <Nav className="align-items-center custom-nav">
                    <UncontrolledDropdown nav>
                      <DropdownToggle className="pr-0 randomNav" nav>
                        <Media className="align-items-center">
                          <span className="avatar avatar-sm rounded-circle">
                            {user?.profile_photo ? (
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}${user.profile_photo}`}
                                alt="profile"
                                style={{ height: "100%", width: "100%" }}
                              />
                            ) : (
                              <div className="avatar avatar-sm rounded-circle">
                                {getUserNameIcon(
                                  user?.first_name + " " + user?.last_name
                                )}
                              </div>
                            )}
                          </span>
                          <Media className="ml-2">
                            <span
                              className={`mb-0 text-sm font-weight-bold transition-all duration-300 ${
                                isHomePage
                                  ? scrolled
                                    ? "text-black"
                                    : "text-white"
                                  : "text-black"
                              }`}
                            >
                              {user?.first_name} {user?.last_name}
                            </span>
                          </Media>
                        </Media>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-arrow" right>
                        <DropdownItem className="noti-title" header tag="div">
                          <h6 className="text-overflow m-0">Welcome!</h6>
                        </DropdownItem>
                        <DropdownItem
                          className="dropdownItem"
                          onClick={handleProfileClick}
                        >
                          <i className="ni ni-single-02" />
                          <span>My profile</span>
                        </DropdownItem>
                        <DropdownItem
                          className="dropdownItem"
                          onClick={() => navigate(`/booking-history`)}
                        >
                          <i className="fas fa-history" />
                          <span>Booking History</span>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem
                          className="dropdownItem"
                          onClick={toggleExitModal}
                        >
                          <i className="fa-solid fa-power-off me-3"></i>
                          <span>Logout</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                )}
              </div>)}
              
            </div>
          </div>
        </div>
        <ResponsiveMenu
          setShowMenu={setShowMenu}
          showMenu={showMenu}
          user={user}
        />
        <LogoutModal
          show={exitModalOpen}
          onHide={toggleExitModal}
          onLogout={onLogout}
        />
      </nav>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Navbar);
