import axiosInstance from "./AxiosInstance";
import axios from "axios";

const apiUrl = "api/room";
const apiOpenRoom = "api/open_room";
const apiRoomDetails = "api/room_details";
const apiBooking = "api/booking";
const apiRefundBooking = "api/confirm_refund";
const apiBookingHistory = "api/booking_history";
const apiBookingStatus = "api/booking_status";
const apiSearchRoom = "api/search_room";
const apiMaintenance = "api/update_maintenance";
const apiCheckRoomStatus = "api/check_room_status";
const apiRoomAvailable = "api/availability";
const apiRoomHistory = "api/room_history";
const apiGeneratePaytm = "api/initiate_payment";
const apiReGeneratePaytm = "api/reinitiate_payment";
const apiPaymentCallBack = "api/callback_payment";
const apiTransactionStatus = "api/transaction_status";
const apiReferenceId = "api/reference_number";
const apiPaymentList = "api/payment_history";
const apiRefundRecord = "api/all_refund_record";
const apiPaymentSource = "api/payment_option";
const apiSendPaymentEmail = "api/send_payment_invoice";
const apiSendRefundEmail = "api/send_refund_invoice";
const apiModifyRoom = "api/search_room_change"
const apiRemoveFailedBooking = "api/remove_failed_booking"

export function getModifyRoomList(id) {
  return axiosInstance.get(`${apiModifyRoom}?id=${id}`);
}

export function getRoomList(page, page_size, query, room_type, sort_by = "-created_at") {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}&room_type=${room_type}&sort_by=${sort_by}`
  );
}

export function getOpenRoomList(page, page_size, query, room_type = "", sort_by = "-created_at") {
  return axiosInstance.get(
    `${apiOpenRoom}?page=${page}&page_size=${page_size}&query=${query}&room_type=${room_type}&sort_by=${sort_by}`
  );
}

export function getOpenRoom(id) {
  return axiosInstance.get(`${apiOpenRoom}/${id}`);
}

export function getRoomDetails(id) {
  return axiosInstance.get(`${apiRoomDetails}/${id}`);
}

export function getRoom(id) {
  return axiosInstance.get(`${apiUrl}/${id}`);
}

export function createRoom(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateRoom(data) {
  return axiosInstance.put(apiUrl, data);
}

export function deleteRoom(roomId) {
  const url = `${apiUrl}/${roomId}`;
  return axiosInstance.delete(url);
}

export function getFilterRoomList(query, room_type, start_date, end_date, room_slot_type) {
  return axiosInstance.get(
    `${apiSearchRoom}?query=${query}&room_type=${room_type}&start_date=${start_date}&end_date=${end_date}&room_slot_type=${room_slot_type}`
  );
}

export function getBookingList(page, page_size, query, room_type, start_date, end_date, sort_by) {
  return axiosInstance.get(
    `${apiBookingHistory}?page=${page}&page_size=${page_size}&query=${query}&room_type=${room_type}&start_date=${start_date}&end_date=${end_date}&sort_by=${sort_by}`
  );
}

export function bookingRoom(data) {
  return axiosInstance.post(apiBooking, data);
}


export function updateRoomBooking(data) {
  return axiosInstance.put(apiBooking, data);
}

export function confirmRefundRoomBooking(id) {
  return axiosInstance.post(apiRefundBooking, id);
}

export function approveRoomBooking(id) {
  return axiosInstance.patch(apiBooking, { id });
}

export function getBookingStatus(page, page_size, query = "", room_type = "", start_date = "", end_date = "", sort_by = "", status, export_type = false, export_all = false) {
  const params = {
    page,
    page_size,
    status,
    export: export_type,
    export_all: export_all
  };

  if (query) params.query = query;
  if (room_type) params.room_type = room_type;
  if (start_date) params.start_date = start_date;
  if (end_date) params.end_date = end_date;
  if (sort_by) params.sort_by = sort_by;
  if (status) params.status = status;

  return axiosInstance.get(apiBookingStatus, { params });
}


export function getPaymentList(page, page_size, query, payment_source, start_date, end_date, sort_by, date_range) {
  return axiosInstance.get(
    `${apiPaymentList}?page=${page}&page_size=${page_size}&query=${query}&payment_source=${payment_source}&start_date=${start_date}&end_date=${end_date}&sort_by=${sort_by}&date_range=${date_range}`
  );
}

export function getRefundList(page, page_size, query, start_date, end_date, sort_by, date_range) {
  return axiosInstance.get(
    `${apiRefundRecord}?page=${page}&page_size=${page_size}&query=${query}&start_date=${start_date}&end_date=${end_date}&sort_by=${sort_by}&date_range=${date_range}`
  );
}

export function sendEmail(transaction_id) {
  const url = `${apiSendPaymentEmail}/${transaction_id}`;
  return axiosInstance.post(url);
}
export function sendRefundEmail(transaction_id) {
  const url = `${apiSendRefundEmail}/${transaction_id}`;
  return axiosInstance.post(url);
}

export function updateMaintenanceRoom(room_id, is_maintenance) {
  return axiosInstance.post(apiMaintenance, { room_id, is_maintenance });
}

export function checkRoomStatus(room_id) {
  return axiosInstance.get(apiCheckRoomStatus, { params: { room_id } });
}


export function getMaintenanceList(page, page_size, query) {
  return axiosInstance.get(
    `${apiMaintenance}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function futureStopBooking(roomId, isEnabled, date = null) {
  const payload = { is_enabled: isEnabled, roomId };

  if (date) {
    // Extract year, month, and day and format as yyyy-mm-dd
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    payload.date = `${year}-${month}-${day}`; // Format as 'yyyy-mm-dd'

  }

  return axiosInstance.patch(apiUrl, payload);
}


// Room availability and history functions
export function getRoomAvailable(room_id) {
  return axiosInstance.get(`${apiRoomAvailable}?room_id=${room_id}`);
}

export function getRoomHistory(room_id) {
  return axiosInstance.get(
    `${apiRoomHistory}?room_id=${room_id}`
  );
}

export function getPurpose() {
  return axiosInstance.get("api/get_booking_purpose");
}

export function generatePaymentOrder(data) {
  return axiosInstance.post(apiGeneratePaytm, data);
}
export function regeneratePaymentOrder(data) {
  return axiosInstance.post(apiReGeneratePaytm, data);
}

export function removeFailedBooking(data) {
  return axiosInstance.post(apiRemoveFailedBooking, data);
}

export function callbackPayment(data) {
  return axiosInstance.post(apiPaymentCallBack, data);
}

export function CheckTransactionStatus(payment_id) {
  return axiosInstance.get(`${apiTransactionStatus}/${payment_id}`);
}

export function confirmBooking(data) {
  return axiosInstance.post(apiReferenceId, data);
}


export function getPaymentSourceList(page, page_size, query) {
  return axiosInstance.get(
    `${apiPaymentSource}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function createPaymentSource(data) {
  return axiosInstance.post(apiPaymentSource, data);
}

export function updatePaymentSource(data) {
  return axiosInstance.patch(apiPaymentSource, data);
}

export function deletePaymentSource(id) {
  return axiosInstance.delete(`${apiPaymentSource}/${id}`);
}
// Define the unassigned booking purpose function
export const getUnassignedBookingPurpose = async (roomId) => {
  try {
    const response = await axiosInstance.get(`api/unassigned_booking_purpose/${roomId}`);
    return response.data; // Assuming the response data contains the necessary details
  } catch (error) {
    console.error("Error fetching unassigned purposes:", error);
    throw error; // Re-throw the error for handling in the component
  }
};