import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "flatpickr/dist/themes/material_blue.css";
import { toast } from "react-toastify";
import { approveRoomBooking } from "../../services/RoomService";

const ApproveBookingModal = ({ booking, updateBookingData }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = (show) => {
    setShowModal(show);
  };
  const handleApprove = async () => {
    try {
      const response = await approveRoomBooking(booking.id);
      const { message, results } = response.data;
      updateBookingData(results);
      toast.success(message);
      toggleModal(false);
      window.location.reload();
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };


  return (
    <>
      <div
        onClick={() =>
          (booking.modified_booking_date || booking.modified_booking_time) &&
          toggleModal(true)
        }
      >
        {booking.modified_booking_date || booking.modified_booking_time ? (
          <button className="btn btn-sm btn-outline-success">
            <i className="fa-solid fa-pen-to-square"></i>{" "}
            {booking.booking_slot?.booking_date}
          </button>
        ) : (
          <span>{booking.booking_slot?.booking_date}</span>
        )}
      </div>

      <Modal
        show={showModal}
        onHide={() => toggleModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="bg-black" closeButton>
          <Modal.Title>
            <span className="text-white">Modify Booking</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div
            style={{
              background: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
              padding: "1rem",
              borderRadius: "15px",
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
              animation: "fadeIn 0.5s ease-in-out",
              fontFamily: "'Poppins', sans-serif",
            }}
          >
            <h5
              style={{
                color: "#343a40",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "1rem",
              }}
            >
              <i className="fas fa-door-open" style={{ color: "#00aaff", marginRight: "10px" }}></i>
              Room: {booking.booking_slot?.room?.name}
            </h5>

            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                <strong>
                  <i className="fas fa-hashtag" style={{ color: "#007bff" }}></i> Booking Number:
                </strong>
                <span style={{ marginLeft: "8px" }}>{booking?.booking_number}</span>
              </li>
              <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                <strong>
                  <i className="fas fa-calendar-alt" style={{ color: "#28a745" }}></i> Booking Date:
                </strong>
                <span style={{ marginLeft: "8px" }}>{booking?.modified_booking_date}</span>
              </li>
              <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                <strong>
                  <i className="fas fa-clock" style={{ color: "#ffc107" }}></i> Booking Time:
                </strong>
                <span style={{ marginLeft: "8px" }}>{booking?.modified_booking_time}</span>
              </li>
              <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                <strong>
                  <i className="fas fa-money-bill-wave" style={{ color: "#17a2b8" }}></i> Room Price:
                </strong>
                <span style={{ marginLeft: "8px" }}>{booking?.booking_net_amount}</span>
              </li>
              <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                <strong>
                  <i className="fas fa-user" style={{ color: "#6f42c1" }}></i> Member Name:
                </strong>
                <span style={{ marginLeft: "8px" }}>
                  {booking.user?.first_name} {booking.user?.last_name}
                </span>
              </li>
            </ul>

            {/* Buttons */}
            <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
              <button
                className="btn btn-sm btn-outline-success"
                style={{
                  marginRight: "1rem",
                  padding: "0.5rem 1.5rem",
                  borderRadius: "30px",
                  fontWeight: "bold",
                }}
                onClick={handleApprove}
              >
                Approve <i className="fas fa-check" style={{ marginLeft: "5px" }}></i>
              </button>
              <button
                className="btn btn-sm btn-outline-danger"
                style={{
                  padding: "0.5rem 1.5rem",
                  borderRadius: "30px",
                  fontWeight: "bold",
                }}
                onClick={() => toggleModal(false)}
              >
                Close <i className="fas fa-times" style={{ marginLeft: "5px" }}></i>
              </button>
            </div>
          </div>
        </Modal.Body>

        
      </Modal>
    </>
  );
};

export default ApproveBookingModal;
