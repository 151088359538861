import React from "react";
import { saveAs } from "file-saver";
import { BiExport } from "react-icons/bi";
import axiosInstance from "../../services/AxiosInstance";

function getBookingListExport(params) {
  const Token = localStorage.getItem("TOKEN");
  return axiosInstance.get("api/booking_history", {
    headers:
      process.env.REACT_APP_HEADER_TOKEN === "LOCALSTORAGE" && Token
        ? { Authorization: `Bearer ${Token}` }
        : {},
    params,
    responseType: "blob",
  });
}

const DownloadBookingList = ({
  currentPage,
  recordPage,
  searchQuery,
  roomType,
  startDate,
  endDate,
  status
}) => {
  
  const getFormattedDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

  const handleDownloadCurrentPage = () => {
    const params = {
      page: currentPage,
      page_size: recordPage,
      query: searchQuery,
      room_type: roomType,
      export: true,
      export_all: false,
      start_date: startDate,
      end_date: endDate,
      status: status,
    };
    getBookingListExport(params)
      .then((response) => {
        const filename = `booking_list_current_page_${getFormattedDate()}.xlsx`;
        saveAs(new Blob([response.data]), filename);
      })
      .catch((error) => {
        console.error("Error fetching booking list:", error);
      });
  };

  const handleDownloadAll = () => {
    const params = {
      query: searchQuery,
      room_type: roomType,
      export: true,
      export_all: true,
      start_date: startDate,
      end_date: endDate,
      status: status,
    };
    getBookingListExport(params)
      .then((response) => {
        const filename = `booking_list_all_pages_${getFormattedDate()}.xlsx`;
        saveAs(new Blob([response.data]), filename);
      })
      .catch((error) => {
        console.error("Error fetching booking list:", error);
      });
  };

  return (
    <div className="me-3 dropdown-filter-status">
      <button
        className="btn btn-sm text-white btn-success dropdown-toggle d-flex align-items-center cursor-pointer"
        type="button"
        id="bookingListDropdown"
      >
        <BiExport className="me-2" style={{ fontSize: '18px' }} />
      </button>
      <ul className="dropdown-filter-menu" style={{ width:"125px" }} aria-labelledby="bookingListDropdown">
        <li>
          <a
            className="dropdown-filter-item cursor-pointer"
            onClick={handleDownloadCurrentPage}
          >
            Current Page
          </a>
          <a
            className="dropdown-filter-item cursor-pointer"
            onClick={handleDownloadAll}
          >
            All Pages
          </a>
        </li>
      </ul>
    </div>
  );
};

export default DownloadBookingList;
