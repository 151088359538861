import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { format, parse } from "date-fns";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  updateRoomBooking,
  getRoomAvailable,
  getModifyRoomList,
} from "../../services/RoomService";
import "./BookingStatus.css";
import DynamicSelectInput, {
  StaticSelectInput,
} from "../../constant/SelectInput";
import RenderCalendar from "../../components/Public&User/Component/PopUpPage/RenderCalendar";

const EditBookingModal = ({ booking, updateBookingData }) => {
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);
  const [bookingDate, setBookingDate] = useState(null);
  const [bookingSlot, setBookingSlot] = useState(null);
  const [changeRoomOption, setChangeRoomOption] = useState([]);
  const [selectedChangeRoom, setSelectedChangeRoom] = useState("");
  const [availableData, setAvailableData] = useState([]);
  const [bookedDisableDateList, setBookedDisableDateList] = useState([]);
  const [showCancelBooking, setShowCancelBooking] = useState(false);
  const [bookingToCancel, setBookingToCancel] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [cancelReason, setCancelReason] = useState({
    cancel_purpose_id: "",
    cancel_reason: "",
  });
  const [cancelPurposeError, setCancelPurposeError] = useState("");
  const calendarRef = useRef(null);
  const [socketBookingProgress, setSocketBookingProgress] = useState([]);
  const [disableDateList, setDisableDateList] = useState([]);
  const socket = useRef(null);

  const roomId = booking.booking_slot?.room?.id;
  const userId = booking.booking_user?.user?.id;

  useEffect(() => {
    setBookingSlot(booking.booking_slot?.booking_time || null);
  }, [booking]);

  useEffect(() => {
    if (isOffCanvasOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOffCanvasOpen]);

  const fetchAvailableDate = async () => {
    if (isOffCanvasOpen && roomId) {
      try {
        const resp = await getRoomAvailable(roomId);
        const { results } = resp.data;
        setAvailableData(results);

        const bookedDate = results
          .filter(
            (item) =>
              item.status === "Booked" &&
              item.booking_time === booking.booking_slot?.booking_time
          )
          .map((item) => item.booking_date);

        setBookedDisableDateList([...bookedDisableDateList, ...bookedDate]);
        fetchRoomModifyList(roomId);
      } catch (error) {
        console.error(error.response?.data.message || error);
      }
    }
  };

  const fetchRoomModifyList = async (roomId) => { // Add roomId as a parameter
    try {
      const resp = await getModifyRoomList(roomId); // Pass the roomId when calling the function
      const fetchedRooms = resp.data.results;
      const filteredRooms = fetchedRooms
        .filter((item) =>
          bookingSlot === "09:00 AM to 11:00 PM"
            ? item.room_slot_type === "Full Day"
            : item.room_slot_type === "Time Slot"
        )
        .map((item) => ({
          value: item.value,
          label: item.label,
        }));
      setChangeRoomOption(filteredRooms);
    } catch (error) {
      console.error("Error fetching room history:", error);
    }
  };


  useEffect(() => {
    fetchAvailableDate();
  }, [isOffCanvasOpen, roomId, booking.booking_slot?.booking_time]);

  const socketConnection = useCallback(() => {
    if (booking.booking_time) {
      socket.current = new WebSocket(
        `${process.env.REACT_APP_WS_BASE_URL}/ws/bookings`
      );

      socket.current.onopen = () => {
        const { booking_time } = booking;
        const socketPayload = {
          event_type: "BOOKING_INIT",
          user_id: userId,
          room_id: roomId,
          booking_time,
        };
        sendMessage(socketPayload);
      };

      socket.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleSocketData(data.message);
      };

      socket.current.onclose = (event) => {


      };

      socket.current.onerror = (error) => {
        console.error("WebSocket Error: ", error);
        socket.current.close();
      };
    }
  }, [booking.booking_time]);

  useEffect(() => {
    socketConnection();
    return () => {
      if (socket.current) {
        socket.current.close();
      }
    };
  }, [socketConnection]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (booking.booking_date) {
        const socketPayload = {
          event_type: "BOOKING_CANCEL",
          userId,
          roomId,
          booking_date: booking.booking_date
            ? format(booking.booking_date, "yyyy-MM-dd")
            : "",
          booking_time: booking.booking_time,
        };
        sendMessage(socketPayload);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [booking.booking_date]);

  const sendMessage = (data) => {
    if (socket.current?.readyState === WebSocket.OPEN) {
      socket.current.send(JSON.stringify(data));
    }
  };

  const updateSocketData = (booking_time, booking_dates) => {
    let updatedSocketBookingProgress = socketBookingProgress.map((item) => {
      if (item.booking_time === booking_time) {
        return {
          booking_time,
          booking_date: [...item.booking_date, ...booking_dates],
        };
      }
      return item;
    });

    if (updatedSocketBookingProgress.length === 0) {
      updatedSocketBookingProgress = [
        { booking_time, booking_date: booking_dates },
      ];
    }
    setSocketBookingProgress(updatedSocketBookingProgress);
  };

  const handleSocketData = (data) => {
    if (data.roomId === roomId) {
      if (data.action === "BOOKING_INIT" && data.user_id === userId) {
        if (data.booking_dates.length > 0) {
          updateSocketData(data.booking_time, [...data.booking_dates]);
          if (booking.booking_time === data.booking_time) {
            setDisableDateList((prevDates) => {
              const updatedDates = [
                ...new Set([...prevDates, ...data.booking_dates]),
              ];
              return updatedDates;
            });
          }
        }
      } else if (data.action === "BOOKING_START") {
        const newDisabledDates = [...disableDateList, data.booking_date];
        if (booking.booking_time === data.booking_time) {
          setDisableDateList((prevDates) => {
            const updatedDates = [
              ...new Set([...prevDates, ...newDisabledDates]),
            ];
            return updatedDates;
          });
        }
        updateSocketData(data.booking_time, newDisabledDates);
      } else if (data.action === "BOOKING_CANCEL") {
        setDisableDateList((prevDates) => {
          const updatedDisableDateList = prevDates.filter(
            (item) => item !== data.booking_date
          );
          updateSocketData(data.booking_time, updatedDisableDateList);
          return [...new Set([...updatedDisableDateList])];
        });
      }
    }
  };

  const togglePopup = (isBookingSuccess = false) => {
    if (isOffCanvasOpen) {
      if (!isBookingSuccess && booking.booking_date) {
        const socketPayload = {
          event_type: "BOOKING_CANCEL",
          userId,
          roomId,
          booking_date: "",
          booking_time: booking.booking_time,
        };
        sendMessage(socketPayload);
      }
      setDisableDateList([]);
      setSocketBookingProgress([]);
    } else {
      const updateBookingInput = {
        roomId,
        booking_time:
          booking.booking_slot?.booking_time === "09:00 AM to 11:00 PM"
            ? "09:00 AM to 11:00 PM"
            : "09:00 AM to 03:00 PM",
        booking_date: "",
      };
      socket.current = new WebSocket(
        `${process.env.REACT_APP_WS_BASE_URL}/ws/bookings`
      );
      socket.current.onopen = () => {
        const { room_id, booking_time } = updateBookingInput;
        const socketPayload = {
          event_type: "BOOKING_INIT",
          userId,
          room_id,
          booking_time,
        };
        sendMessage(socketPayload);
      };
      socket.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleSocketData(data.message);
      };
      socket.current.onclose = (event) => {
        console.log("Socket Disconnected ", event);
      };
    }
    setIsOffCanvasOpen(!isOffCanvasOpen);
  };

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length > 0) {
      setBookingDate(selectedDates[0]);
      setDateError(false);
    }
  };

  const getDateStatus = (date, slotType) => {
    const found = availableData.find((item) => {
      const slotDate = new Date(item.booking_date);
      return (
        slotDate.toDateString() === date.toDateString() &&
        item.booking_time === slotType
      );
    });

    return found
      ? {
        status: found.status,
        username: `${found.booking_user?.user?.first_name} ${found.booking_user?.user?.last_name}`,
      }
      : {};
  };

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    setBookingSlot(selectedTime);
    getRoomAvailable(roomId)
      .then((resp) => {
        const { results } = resp.data;
        setAvailableData(results);
        const bookedDate = results
          .filter(
            (item) =>
              item.status === "Booked" && item.booking_time === selectedTime
          )
          .map((item) => item.booking_date);

        setBookedDisableDateList(bookedDate);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  };

  const handleCancelShow = () => {
    setBookingToCancel(booking);
    setShowCancelBooking(true);
  };

  const handleSelectChange = (name, value) => {
    if (name === "cancel_purpose_id") {
      setCancelReason((prev) => ({ ...prev, [name]: value }));
      setCancelPurposeError("");
    } else if (name === "room") {
      setSelectedChangeRoom(value)
    }
  };

  const handleUpdate = () => {
    try {
      const finalBookingDate = bookingDate
        ? format(new Date(bookingDate), "yyyy-MM-dd")
        : "";
      const updatedBooking = {
        id: booking.id,
        booking_date: finalBookingDate,
        booking_time: bookingSlot,
        room: selectedChangeRoom,
      };

      updateRoomBooking(updatedBooking)
        .then((resp) => {
          const { message, results } = resp.data;
          updateBookingData(results);
          toast.success(message);
          setIsOffCanvasOpen(false);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response?.data.message || "An error occurred");
        });
    } catch (error) {
      toast.error("Error updating booking.");
    }
  };
 

  const handleConfirm = () => {
    if (!cancelReason.cancel_purpose_id) {
      setCancelPurposeError("Cancel Purpose is required.");
      return;
    }

    if (bookingToCancel) {
      const payload = {
        id: bookingToCancel.id,
        cancel_booking: "YES",
        cancel_purpose: cancelReason.cancel_purpose_id,
        cancel_reason: cancelReason.cancel_reason,
      };

      updateRoomBooking(payload)
        .then((resp) => {
          const { message, results } = resp.data;
          updateBookingData(results);
          toast.success(message);
          setShowCancelBooking(false);
          setIsOffCanvasOpen(false);
        })
        .catch((error) => {
          console.log("error", error);
          if (error?.response) {
            toast.error(error.response?.data?.message || "An error occurred");
          }
        });
    }
  };

  const handleCloseModal = () => {
    setIsOffCanvasOpen(false);
    setBookingDate(null);
    let defaultBookingSlot;
    if (booking.booking_slot?.booking_time === "09:00 AM to 11:00 PM") {
      defaultBookingSlot = "09:00 AM to 11:00 PM";
    } else if (booking.booking_slot?.booking_time === "09:00 AM to 03:00 PM") {
      defaultBookingSlot = "09:00 AM to 03:00 PM";
    } else if (booking.booking_slot?.booking_time === "06:00 PM to 11:00 PM") {
      defaultBookingSlot = "06:00 PM to 11:00 PM";
    } else {
      defaultBookingSlot = "";
    }
    setBookingSlot(defaultBookingSlot);

    setDisableDateList([]);
    setSocketBookingProgress([]);
  };

  const handleCancelClose = () => {
    setShowCancelBooking(false);
    setCancelReason({ cancel_purpose_id: "", cancel_reason: "" });
  };

  return (
    <>
      <button className="btn btn-sm btn-outline-success" onClick={togglePopup}>
        <i className="fa-solid fa-pen-to-square"></i> Booked
      </button>

      {isOffCanvasOpen && (
        <div
          className={`offcanvas-container ${isOffCanvasOpen ? "open mt-6 mt-sm-0" : ""
            } `}
        >
          <div className={`offcanvas-overlay ${isOffCanvasOpen ? "open" : ""}`}>
            <div className="offcanvas-content">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h2 className="text-lg">
                  <strong>Edit Booking</strong>
                </h2>
                <button
                  className="btn btn-sm text-base"
                  onClick={handleCloseModal}
                >
                  &times;
                </button>
              </div>

              <div
                style={{
                  background:
                    "linear-gradient(135deg, #f0f4f8 0%, #d9e2ec 100%)",
                  borderRadius: "15px",
                  boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                  padding: "20px",
                  fontFamily: "'Poppins', sans-serif",
                  animation: "fadeIn 0.5s ease-in-out",
                  transition: "transform 0.2s ease-in-out",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.transform = "scale(1.02)")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                <h5
                  style={{
                    textAlign: "center",
                    color: "#343a40",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                  }}
                >
                  <i
                    className="fas fa-door-open"
                    style={{ color: "#007bff", marginRight: "10px" }}
                  ></i>{" "}
                  {/* Room Icon */}
                  {booking.booking_slot?.room?.name}
                </h5>
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                    <strong>
                      <i
                        className="fas fa-hashtag"
                        style={{ color: "#00aaff", marginRight: "8px" }}
                      ></i>
                      Booking Number:
                    </strong>
                    <span style={{ marginLeft: "8px" }}>
                      {booking?.booking_number}
                    </span>
                  </li>
                  <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                    <strong>
                      <i
                        className="fas fa-calendar-alt"
                        style={{ color: "#28a745", marginRight: "8px" }}
                      ></i>
                      Booking Date:
                    </strong>
                    <span style={{ marginLeft: "8px" }}>
                      {booking.booking_slot?.booking_date}
                    </span>
                  </li>
                  <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                    <strong>
                      <i
                        className="fas fa-clock"
                        style={{ color: "#ffc107", marginRight: "8px" }}
                      ></i>
                      Booking Time:
                    </strong>
                    <span style={{ marginLeft: "8px" }}>
                      {booking.booking_slot?.booking_time}
                    </span>
                  </li>
                  <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                    <strong>
                      <i
                        className="fas fa-money-bill-wave"
                        style={{ color: "#17a2b8", marginRight: "8px" }}
                      ></i>
                      Amount:
                    </strong>
                    <span style={{ marginLeft: "8px" }}>
                      {booking?.booking_net_amount}
                    </span>
                  </li>
                  <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                    <strong>
                      <i
                        className="fas fa-user"
                        style={{ color: "#6f42c1", marginRight: "8px" }}
                      ></i>
                      Member Name:
                    </strong>
                    <span style={{ marginLeft: "8px" }}>
                      {booking.user?.first_name} {booking.user?.last_name}
                    </span>
                  </li>
                </ul>
              </div>
              <Form.Group className="mb-3 mt-3">

                <div className="mb-3">
                  <Form.Label htmlFor="room">Select Rooms(If Necessary)</Form.Label>
                  <StaticSelectInput
                    parentClassName="mb-0 text-xs"
                    setParentInputValue={handleSelectChange}
                    optionsList={changeRoomOption}
                    name="room"
                    id="room"
                    placeholder="Select Room"
                  />
                </div>

                <Form.Label>Booking Slot</Form.Label>
                <br />
                {bookingSlot === "09:00 AM to 11:00 PM" ? (
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="booking_time"
                      id="wholeday"
                      checked
                      readOnly
                    />
                    <label className="form-check-label ml-2" htmlFor="wholeday">
                      Full Day
                    </label>
                  </div>
                ) : (
                  <>
                    <div className="d-flex flex-wrap justify-content-start">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="booking_time"
                          id="morning"
                          value="09:00 AM to 03:00 PM"
                          checked={bookingSlot === "09:00 AM to 03:00 PM"}
                          onChange={handleTimeChange}
                        />
                        <label
                          className="form-check-label me-sm-2 me-0"
                          htmlFor="morning"
                        >
                          9 AM - 3 PM
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="booking_time"
                          id="evening"
                          value="06:00 PM to 11:00 PM"
                          checked={bookingSlot === "06:00 PM to 11:00 PM"}
                          onChange={handleTimeChange}
                        />
                        <label className="form-check-label" htmlFor="evening">
                          6 PM - 11 PM
                        </label>
                      </div>
                    </div>
                  </>
                )}
              </Form.Group>
              <div className="mb-3">
                <Form.Label htmlFor="date" className="block">
                  Booking Date
                </Form.Label>
                <RenderCalendar
                  handleDateChange={handleDateChange}
                  bookingDate={bookingDate}
                  disableDateList={[
                    ...disableDateList,
                    ...bookedDisableDateList,
                  ]}
                  calendarRef={calendarRef}
                  getDateStatus={getDateStatus}
                  slotType={bookingSlot}
                />
              </div>
              <div className="mb-5">
                <span className="me-4"><b>Want to Cancel Booking?</b></span>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={handleCancelShow}
                >
                  Cancel Booking
                </button>
              </div>

              <div className="d-flex align-items-center justify-content-center flex-column">
                <div className="mb-3">
                  <Button
                    variant="success"
                    className="btn btn-sm"
                    onClick={handleUpdate}
                    disabled={!bookingSlot}
                  >
                    Update
                  </Button>

                  <Button
                    type="button"
                    className="btn btn-sm btn-danger ms-2"
                    variant="secondary"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        show={showCancelBooking}
        onHide={() => setShowCancelBooking(false)}
        centered="true"
        backdrop="static"
        keyboard={false}
        size="sm"
      >
        <Modal.Body className="p-4">
          <label htmlFor="cancel_purpose_id">
            <strong style={{ fontSize: "0.85em" }} className="me-1">
              Cancel Purpose<span className="text-danger">*</span>
            </strong>
          </label>
          <DynamicSelectInput
            parentClassName="mb-3 order"
            setParentInputValue={handleSelectChange}
            endpoint_name="booking_cancel_purpose"
            name="cancel_purpose_id"
            id="cancel_purpose_id"
            placeholder="Select Cancel Purpose"
          />
          {cancelPurposeError && (
            <p className="text-danger" style={{ fontSize: "0.8em" }}>
              {cancelPurposeError}
            </p>
          )}
          <label htmlFor="description" className="form-label">
            Reason For Cancel Booking:
          </label>
          <textarea
            id="description"
            name="description"
            className="form-control"
            placeholder="Reason"
            rows="4"
            onChange={(e) =>
              setCancelReason((prev) => ({
                ...prev,
                cancel_reason: e.target.value,
              }))
            }
          ></textarea>
        </Modal.Body>
        <div className="d-flex align-items-center justify-content-center mb-3">
          <Button variant="secondary" onClick={() => handleCancelClose()}>
            No
          </Button>
          <Button variant="danger" onClick={handleConfirm}>
            Yes
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default EditBookingModal;
