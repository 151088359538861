

import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import UserHeader from "../Headers/UserHeader";
import ChangePassword from "./ChangePassword";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useWindowSize from "../../constant/WindowSize";
import { getUserNameIcon } from "../../utils";

import { logoutAction } from "../../store/actions/AuthActions";
import LogoutModal from "../Logout/Logout";
import ChangeProfilePhoto from "./ChangeProfilePhoto";
import "./Profile.css";
import { getProfileData, updateProfileData } from "../../services/ProfileService";

const Profile = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    membership_id: "",
    membership_type: "",
    role: "",
    join_date: "",
    address: "",
    city: "",
    pin_code: ""
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const size = useWindowSize();
  const isMobile = size.width <= 768;

  const toggleExitModal = () => setExitModalOpen(!exitModalOpen);

  useEffect(() => {
    setIsLoading(true);
    getProfileData()
      .then((resp) => {
        setFormData(resp.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, []);

  function onLogout() {
    dispatch(logoutAction(navigate));
    toggleExitModal();
  }

  const handleEditToggle = () => {
    if (isEditable) {
      updateProfileData(formData)
        .then((resp) => {
          console.log("Profile updated successfully");
        })
        .catch((error) => {
          console.error("Error updating profile", error);
        });
    }
    setIsEditable(!isEditable); 
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <UserHeader />
      <Container className="mt--9 px-3 profile-style" fluid>
        <Row className="profile-row">
          <Col className="order-xl-2 mb-3 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <div className="profile-photo-container">
                {user?.profile_photo ? (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${user.profile_photo}`}
                    className="admin-profile-photo"
                    alt="profile"
                  />
                ) : (
                  <div className="lg-text-admin text-4xl d-flex align-items-center justify-content-center" >
                    {getUserNameIcon(user?.first_name + " " + user?.last_name)}
                  </div>
                )}
                <ChangeProfilePhoto />
              </div>
              <CardBody>
                <div className="text-center">
                  <h3>
                    {user?.first_name} {user?.last_name}
                  </h3>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    {user?.role} - Rourkela Club
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    Rourkela
                  </div>
                  <hr className="my-4" />
                  <div className="mb-4 d-flex align-items-center justify-content-between">
                    <span className="text-sm text-mute">Want to Change Password? </span> <ChangePassword />
                  </div>
                  <span onClick={toggleExitModal} className="cursor-pointer">
                    <i className="fa-solid fa-power-off me-2"></i>
                    Logout
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col className="order-xl-1 mb-7" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={handleEditToggle}
                      size="sm"
                    >
                      {isEditable ? "Save" : "Edit"}
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="profile-edit mb-4">
                <Form>
                <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row className="mb-3">
                      <Col lg="6">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-first-name"
                        >
                          First name
                        </label>
                        <Input
                          className="form-control-alternative"
                          name="first_name"
                          value={formData.first_name || ""}
                          id="input-first-name"
                          placeholder="First name"
                          type="text"
                          readOnly={!isEditable}
                          onChange={handleInputChange}
                        />
                      </Col>
                      <Col lg="6">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-last-name"
                        >
                          Last name
                        </label>
                        <Input
                          className="form-control-alternative"
                          name="last_name"
                          value={formData.last_name || ""}
                          id="input-last-name"
                          placeholder="Last name"
                          type="text"
                          readOnly={!isEditable}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6" className="mb-3">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-membership-id"
                        >
                          Membership ID
                        </label>
                        <Input
                          className="form-control-alternative"
                          name="membership_id"
                          value={formData.membership_id || ""}
                          id="input-membership-id"
                          placeholder="Membership ID"
                          type="text"
                          readOnly
                          onChange={handleInputChange}
                        />
                      </Col>
                      <Col lg="6" className="mb-3">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-email"
                        >
                          Email
                        </label>
                        <Input
                          className="form-control-alternative"
                          name="email"
                          value={formData.email || ""}
                          id="input-email"
                          placeholder="Email"
                          type="email"
                          readOnly
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Contact information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12" className="mb-3">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-address"
                        >
                          Address
                        </label>
                        <Input
                          className="form-control-alternative"
                          name="address"
                          value={formData.address || ""}
                          id="input-address"
                          placeholder="Home Address"
                          type="text"
                          readOnly={!isEditable}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg="6">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-city"
                        >
                          City
                        </label>
                        <Input
                          className="form-control-alternative"
                          name="city"
                          value={formData.city || ""}
                          id="input-city"
                          placeholder="City"
                          type="text"
                          readOnly={!isEditable}
                          onChange={handleInputChange}
                        />
                      </Col>
                      <Col lg="6">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-postal-code"
                        >
                          Pin code
                        </label>
                        <Input
                          className="form-control-alternative"
                          name="pin_code"
                          value={formData.pin_code || ""}
                          id="input-postal-code"
                          placeholder="Pin code"
                          type="text"
                          readOnly={!isEditable}
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6" className="mb-3">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-phone"
                        >
                          Phone Number
                        </label>
                        <Input
                          className="form-control-alternative"
                          name="phone_number"
                          value={formData.phone_number || ""}
                          id="input-phone"
                          placeholder="Phone Number"
                          type="text"
                          readOnly={!isEditable}
                          onChange={handleInputChange}
                        />
                      </Col>
                      <Col lg="6" className="mb-3">
                        <label
                          className="form-control-label d-flex align-items-start"
                          htmlFor="input-join-date"
                        >
                          Join Date
                        </label>
                        <Input
                          className="form-control-alternative"
                          name="join_date"
                          value={formData.join_date || ""}
                          id="input-join-date"
                          placeholder="Join Date"
                          type="text"
                          readOnly
                          onChange={handleInputChange}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <LogoutModal
        show={exitModalOpen}
        onHide={toggleExitModal}
        onLogout={onLogout}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Profile);

