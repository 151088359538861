import React, { useEffect, useState } from "react";
import "./memberCustom.css";
import { Table, Container } from "reactstrap";
import Header from "../../components/Headers/Header";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getMemberBookingHistory, getMemberDetails } from "../../services/VerifyMemberService";
import { LuArrowUpDown } from "react-icons/lu";
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";

const UserDetailsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const { id } = useParams();
  const navigate = useNavigate();
  console.log("new data", memberData);

  useEffect(() => {
    setIsLoading(true);
    getMemberDetails(id)
      .then((resp) => {
        setMemberData(resp.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user:", error);
        setMemberData(null);
      });
  }, [id]);

  useEffect(() => {
    getMemberBookingHistory(id)
      .then((resp) => {
        setData(resp.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [id]);

  const sortData = (key, direction) => {
    const sortedData = [...data].sort((a, b) => {
      if (key === "booking_slot.price") {
        const priceA = parseFloat(a.booking_slot.room?.price);
        const priceB = parseFloat(b.booking_slot.room?.price);
        return direction === "asc" ? priceA - priceB : priceB - priceA;
      } else if (key.includes(".")) {
        const keys = key.split(".");
        let aValue = a;
        let bValue = b;
        for (let nestedKey of keys) {
          aValue = aValue[nestedKey];
          bValue = bValue[nestedKey];
        }

        if (typeof aValue === "string" && aValue.match(/^\d{2}-\d{2}-\d{4}$/)) {
          const dateA = new Date(
            `${aValue.split("-").reverse().join("-")} 00:00:00`
          );
          const dateB = new Date(
            `${bValue.split("-").reverse().join("-")} 00:00:00`
          );
          return direction === "asc" ? dateA - dateB : dateB - dateA;
        }
        return direction === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      } else {
        if (typeof a[key] === "string" && a[key].match(/^\d{2}-\d{2}-\d{4}$/)) {
          const dateA = new Date(
            `${a[key].split("-").reverse().join("-")} 00:00:00`
          );
          const dateB = new Date(
            `${b[key].split("-").reverse().join("-")} 00:00:00`
          );
          return direction === "asc" ? dateA - dateB : dateB - dateA;
        }
        return direction === "asc"
          ? a[key].localeCompare(b[key])
          : b[key].localeCompare(a[key]);
      }
    });

    setData(sortedData);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    sortData(key, direction);
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <HiArrowNarrowUp />
      ) : (
        <HiArrowNarrowDown />
      );
    }
    return (
      <>
        <LuArrowUpDown />
      </>
    );
  };

  return (
    <>
      <Header />
      <Container className="mt-sm-3 mt-7 px-0 px-sm-3 mb-sm-0 mb-7" fluid>
        <div className="card p-sm-3 p-2">
          <div className="d-flex align-items-center mx-3 mb-3">
            <button
              type="button"
              className="btn btn-sm border-black text-black rounded-circle"
              onClick={() =>
                navigate(-1)}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            <h3 className="primary">Member Details</h3>
          </div>
          <div
            className="p-4 border rounded shadow"
            style={{ backgroundColor: "#ffffff", marginBottom: "20px" }}
          >
            <div className="row">
              <div className="col-md-4 col-12">
                <div className="d-flex align-items-center justify-content-center mb-4 mt-4">
                  {memberData?.profile_photo ? (
                    <img
                      className="member-details-profile-photo img-fluid rounded-circle"
                      alt="Profile"
                      src={`${process.env.REACT_APP_BASE_URL}${memberData.profile_photo}`}
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <span
                      className="text-avatar userTextProfile cursor-pointer"
                      style={{ fontSize: "3rem" }}
                    >
                      {memberData?.first_name?.slice(0, 1)}
                    </span>
                  )}
                </div>

                <h1
                  className="text-center text-xl mb-4"
                  style={{ fontSize: "1.5rem" }}
                >
                  {memberData?.first_name} {memberData?.last_name}
                </h1>

                <div className="mb-3" style={{ color: "#555" }}>
                  <strong style={{ color: "#333" }}>Membership Id: </strong>
                  {memberData?.membership_id}
                </div>

                <div className="mb-3" style={{ color: "#555" }}>
                  <strong style={{ color: "#333" }}>Membership Type: </strong>
                  {memberData?.membership_type}
                </div>

                {memberData?.membership_type === "SAIL RSP Member" && (
                  <div className="mb-3" style={{ color: "#555" }}>
                    <strong style={{ color: "#333" }}>
                      Personnel Number:{" "}
                    </strong>
                    {memberData?.personnel_number}
                  </div>
                )}

                <div className="mb-3" style={{ color: "#555" }}>
                  <strong style={{ color: "#333" }}>Email: </strong>
                  {memberData?.email}
                </div>

                <div className="mb-3" style={{ color: "#555" }}>
                  <strong style={{ color: "#333" }}>Phone Number: </strong>
                  {memberData?.phone_number}
                </div>

                <div className="mb-3" style={{ color: "#555" }}>
                  <strong style={{ color: "#333" }}>Joining Date: </strong>
                  {memberData?.join_date}
                </div>
              </div>

              <div className="col-md-8 booking-history-container">
                {data && data.length > 0 ? (
                  <>
                    <h6 className="mb-2 text-center">Booking History</h6>
                    <div
                      className="table-responsive-wrapper"
                      style={{ maxHeight: "470px", overflowY: "auto" }}
                    >
                      <Table className="booking-table" responsive>
                        <thead className="booking-table-head disable-select">
                          <tr className="booking-table-header">
                            <th
                              scope="col"
                              className="py-2"
                              onClick={() => handleSort("booking_number")}
                            >
                              <div className="table-header-content">
                                Booking No.
                                <span className="sort-icon">
                                  {renderSortIcon("booking_number")}
                                </span>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="py-2"
                              onClick={() =>
                                handleSort("booking_slot.room.name")
                              }
                            >
                              <div className="table-header-content">
                                Room Name
                                <span className="sort-icon">
                                  {renderSortIcon("booking_slot.room.name")}
                                </span>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="py-2"
                              onClick={() =>
                                handleSort("booking_slot.booking_date")
                              }
                            >
                              <div className="table-header-content">
                                Booking Date
                                <span className="sort-icon">
                                  {renderSortIcon("booking_slot.booking_date")}
                                </span>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="py-2"
                              onClick={() =>
                                handleSort("booking_slot.booking_time")
                              }
                            >
                              <div className="table-header-content">
                                Booking Time
                                <span className="sort-icon">
                                  {renderSortIcon("booking_slot.booking_time")}
                                </span>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="py-2"
                              onClick={() => handleSort("booking_slot.price")}
                            >
                              <div className="table-header-content">
                                Amount
                                <span className="sort-icon">
                                  {renderSortIcon("booking_slot.price")}
                                </span>
                              </div>
                            </th>
                            <th scope="col" className="py-2">
                              <div className="table-header-content">Status</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="booking-table-body">
                          {data.map((item, index) => (
                            <tr
                              key={index}
                              className={`booking-table-row ${item?.status === "Cancelled" ? "text-gray" : ""
                                }`}
                            >
                              <td>{item.booking_number}</td>
                              <td>{item.booking_slot?.room?.name}</td>
                              <td>{item.booking_slot?.booking_date}</td>
                              <td>{item.booking_slot?.booking_time}</td>
                              <td>{item?.booking_net_amount}</td>
                              <td>
                                {item?.status === "Cancelled" ? (
                                  <span className="text-danger px-2">
                                    Cancelled
                                  </span>
                                ) : (
                                  <span className="text-success px-2">
                                    Booked
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </>
                ) : (
                  <div className="text-center">No data available</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default UserDetailsPage;
