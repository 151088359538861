import React from "react";
import AllEvents from "../Component/Events/AllEvents";

const PlacesRoute = () => {
  return (
    <>
      <div className="pt-14">
        <AllEvents />
      </div>
    </>
  );
};

export default PlacesRoute;
