import React from "react";

const TableNoResultFound = () => {
  return (
    <tr>
      <td colSpan="8" className="text-center py-7">
        <div className="text-center">
          <i
            className="fas fa-search-minus"
            style={{ fontSize: "2rem", opacity: 0.8, color: "#17a2b8" }}
          ></i>
          <h3 style={{ opacity: 0.5 }}>No Result Found</h3>
        </div>
      </td>
    </tr>
  );
};

export default TableNoResultFound;
