import React, { useState, useEffect, useRef } from "react";
import { FaBell } from "react-icons/fa";
import { format } from "date-fns";
import { connect } from "react-redux";
import {
  getNotification,
  markNotificationsAsRead,
  markOneNotificationAsRead,
} from "../../../../services/NotificationService";
import { useNavigate } from "react-router-dom";
import "./Notification.css";


const Notification = ({ user }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [data, setData] = useState([]);
  const [removingNotifications, setRemovingNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [newMsgCount, setNewMsgCount] = useState(0);
  const recordsPage = 10;
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const socket = useRef(null);


  const clearNewNotificationCount = async () => {
    try {
      setNewMsgCount(0);
    } catch (error) {
      console.error("Failed to clear notification count", error);
    }
  };


  const fetchNotifications = async (page) => {
    setLoading(true);
    try {
      const resp = await getNotification(page, recordsPage);
      const { results } = resp.data;
      if (results.length > 0) {
        setData((prevNotifications) => [...prevNotifications, ...results]);
        setHasMore(results.length === recordsPage);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Failed to fetch notifications", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleSidebar = () => {
    const isOpen = isSidebarOpen
    setIsSidebarOpen((prev) => !prev);
    if (isOpen) {
      // when close
      setData((prev) => [])
      setHasMore(true)
      setCurrentPage((prev) => 1)
      document.body.classList.remove("no-scroll");
    } else {
      //  when open
      fetchNotifications(currentPage);
      clearNewNotificationCount()
      document.body.classList.add("no-scroll");
    }
  };

  useEffect(() => {
    if (user) {
      let socketURL;
      if (`process.env.REACT_APP_HEADER_TOKEN === "LOCALSTORAGE"`) {
        const token = localStorage.getItem("TOKEN");
        socketURL = `${process.env.REACT_APP_WS_BASE_URL}/ws/admin_notification?token=${token}`;
      } else {
        socketURL = `${process.env.REACT_APP_WS_BASE_URLs}/ws/admin_notification`;
      }

      socket.current = new WebSocket(socketURL);

      socket.current.onopen = (event) => {
        console.log("WebSocket connected", event);
      };

      socket.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message?.event_type === "NEW_COUNT") {
          setNewMsgCount((prev) => message?.new_count);
        } else if (message?.event_type === "NEW_MESSAGE") {
          setNewMsgCount((prev) => prev + 1);
        }
      };

      socket.current.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      socket.current.onclose = () => {
        console.log("WebSocket disconnected");
      };

      return () => {
        if (socket.current) {
          socket.current.close();
        }
      };
    }
  }, [user]);

  useEffect(() => {
    if (!isSidebarOpen || !sidebarRef.current) return;

    const handleScroll = () => {
      if (
        sidebarRef.current.scrollTop + sidebarRef.current.clientHeight >=
        sidebarRef.current.scrollHeight
      ) {
        if (!loading && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      }
    };

    sidebarRef.current.addEventListener("scroll", handleScroll);
    return () =>
      sidebarRef.current?.removeEventListener("scroll", handleScroll);
  }, [isSidebarOpen, loading, hasMore]);

  useEffect(() => {
    if (isSidebarOpen && currentPage > 1) {
      fetchNotifications(currentPage);
    }
  }, [currentPage, isSidebarOpen]);

  const handleNotificationClick = (index, notif) => {
    const { id, notification, booking, user } = notif;

    if (!data[index].is_read) {
      markAsRead(id);
    }

    const notificationType = notification?.notification_type;

    if (
      [
        "NEW BOOKING",
        "BOOKING MODIFY REQUEST",
        "BOOKING CANCELLED",
        "PAYMENT RECEIVED",
        "REFUND PROCESSED",
      ].includes(notificationType)
    ) {
      if (notification?.booking_number) {
        navigate(`/booking-status?bookingNumber=${notification?.booking_number}`);
      } else {
        console.error("Booking number is missing for this notification:", notif);
      }
    } else if (notificationType === "USER REGISTERED") {
      navigate(`/member?email=${user?.email}`);
    }

    toggleSidebar();
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  const markAsRead = (id) => {
    markOneNotificationAsRead({ id }).then(() => {
      setData((prev) =>
        prev.map((notif) =>
          notif.id === id ? { ...notif, is_read: true } : notif
        )
      );
    });
  };

  // const unreadCount = data.filter((notif) => !notif.is_read).length;

  const handleClearNotification = (id) => {
    const payload = { id, clear: true };
    markOneNotificationAsRead(payload);
    setRemovingNotifications((prev) => [...prev, id]);
    setTimeout(() => {
      setData((prevNotifications) =>
        prevNotifications.filter((notif) => notif.id !== id)
      );
      setRemovingNotifications((prev) =>
        prev.filter((notifId) => notifId !== id)
      );
    }, 300);
  };

  const markAllAsRead = () => {
    const unreadNotificationIds = data
      .filter((notif) => !notif.is_read)
      .map((notif) => notif.id);

    if (unreadNotificationIds.length > 0) {
      const payload = { ids: unreadNotificationIds };
      markNotificationsAsRead(payload)
        .then(() => {
          setData((prevNotifications) =>
            prevNotifications.map((notif) => ({ ...notif, is_read: true }))
          );
        })
        .catch((error) => {
          console.error("Error marking all notifications as read", error);
        });
    }
  };

  const clearAllNotifications = () => {
    const notificationIds = data.map((notif) => notif.id);
    markNotificationsAsRead({ ids: notificationIds, clearAll: true });
    setRemovingNotifications(notificationIds);

    setTimeout(() => {
      setData([]);
    }, 300);
  };

  return (
    <>
      <div className="notification-toggle" onClick={toggleSidebar}>
        <FaBell className="notification-icon" />
        {newMsgCount > 0 && (
          <span className="notification-badge">{newMsgCount}</span>
        )}
      </div>

      <div className={`notification-sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="notification-header">Notifications</div>
        {isSidebarOpen && (
          <div
            className={`notification-sidebar ${isSidebarOpen ? "open" : ""}`}
            ref={sidebarRef}
          >
            <div className="notification-header">Notifications</div>
            <div className="notification-content">
              {data.length > 0 ? (
                data.map((notif, index) => (
                  <div
                    key={notif.id}
                    className={`notification-item ${removingNotifications.includes(notif.id) ? "removing" : ""
                      }`}
                    style={{
                      backgroundColor: notif.is_read
                        ? "transparent"
                        : "rgba(255, 255, 255, 0.2)",
                    }}
                    onClick={() => handleNotificationClick(index, notif)}
                  >
                    <span className="notification-message">
                      {notif.notification?.message} by {notif.user?.first_name}{" "}
                      {notif.user?.last_name}
                    </span>
                    <div className="notification-time">
                      {format(notif.created_at, "yyyy-MM-dd HH:mm")}
                    </div>
                    <button
                      className="clear-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClearNotification(notif.id);
                      }}
                    >
                      Clear
                    </button>
                  </div>
                ))
              ) : !loading && (
                <div className="no-notification-item">No notifications</div>
              )}  {loading && (
                <div className="d-flex justify-content-center align-items-center loader-overlay">
                  <div
                    className="spinner-border text-white"
                    role="status"
                  ></div>
                </div>)}
            </div>
            {data.length > 0 && (
              <div className="notification-footer">
                <button className="read-all-button" onClick={markAllAsRead}>
                  Mark all as Read
                </button>
                <button
                  className="clear-all-button"
                  onClick={clearAllNotifications}
                >
                  Clear All
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {isSidebarOpen && (
        <div className="notification-overlay" onClick={toggleSidebar}></div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(Notification);
