import React, { useState } from "react";

const DateRangeTypeDropDown = ({ setDateRange, dateRange }) => {
  const [dropdown, setDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleDateRange = (dateRange) => {
    if (dateRange === "Last Week") {
      setDateRange("week");
    } else if (dateRange === "Last Month") {
      setDateRange("month");
    } else if (dateRange === "Last Year") {
      setDateRange("year");
    }
    else{
      setDateRange("");
    }
    setDropdown(false);
  };

  return (
    <div className="dropdown-filter-status">
      <button
        className="btn dropdown-toggle "
        type="button"
        id="roomTypeDropdown"
        onClick={handleToggleDropdown}
      >
        {dateRange === "week" ? "Last Week" : dateRange === "month" ? "Last Month" : dateRange === "year" ? "Last Year" : "Date Range"}
      </button>
      {dropdown && (
        <ul className="dropdown-filter-menu" aria-labelledby="roomTypeDropdown">
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleDateRange("")}
            >
              All
            </a>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleDateRange("Last Week")}
            >
              Last Week
            </a>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleDateRange("Last Month")}
            >
              Last Month
            </a>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleDateRange("Last Year")}
            >
              Last Year
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export const StatusTypeDropDown = ({
    setSelectedStatus, selectedStatus, style
}) => {
  const [statusdropdown, setStatusDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setStatusDropdown(!statusdropdown);
  };

  return (
    <div className="dropdown-filter-status  me-sm-5 me-0" style={style}>
      <button
        className="btn dropdown-toggle"
        type="button"
        id="roomTypeDropdown"
        onClick={handleToggleDropdown}
      >
        {selectedStatus || "Status"}
      </button>
      {statusdropdown && (
        <ul className="dropdown-filter-menu" aria-labelledby="roomTypeDropdown">
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => setSelectedStatus("")}
            >
              All
            </a>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => setSelectedStatus("SUCCESS")}
            >
              SUCCESS
            </a>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => setSelectedStatus("FAILED")}
            >
              FAILED
            </a>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => setSelectedStatus("PENDING")}
            >
              PENDING
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export const PaymentmodeTypeDropDown = ({
  setSelectedPaymentmode, selectedPaymentmode, style
}) => {
const [paymentmodedropdown, setPaymentmodedropdown] = useState(false);

const handleToggleDropdown = () => {
  setPaymentmodedropdown(!paymentmodedropdown);
};

return (
  <div className="dropdown-filter-status  me-sm-5 me-0" style={style}>
    <button
      className="btn dropdown-toggle"
      type="button"
      id="roomTypeDropdown"
      onClick={handleToggleDropdown}
    >
      {selectedPaymentmode || "Payment Source"}
    </button>
    {paymentmodedropdown && (
      <ul className="dropdown-filter-menu" aria-labelledby="roomTypeDropdown">
        <li>
          <a
            className="dropdown-filter-item cursor-pointer"
            onClick={() => setSelectedPaymentmode("")}
          >
            All
          </a>
          <a
            className="dropdown-filter-item cursor-pointer"
            onClick={() => setSelectedPaymentmode("Razorpay")}
          >
            Razorpay
          </a>
          <a
            className="dropdown-filter-item cursor-pointer"
            onClick={() => setSelectedPaymentmode("Paytm")}
          >
            Paytm
          </a>
          <a
            className="dropdown-filter-item cursor-pointer"
            onClick={() => setSelectedPaymentmode("Cash")}
          >
            Cash
          </a>
        </li>
      </ul>
    )}
  </div>
);
};

export default DateRangeTypeDropDown;
