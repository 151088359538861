import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Card, CardBody, NavItem, NavLink, Nav, Row, Col } from "reactstrap";
import { getBookingAmountGraph } from "../../services/DashBoardService";
import classnames from "classnames";

const apexColors = [
  "#6439FF",
  "#4F75FF",
  "#00CCDD",
  "#7CF5FF",
];

const BookingAmountGraph = () => {
  const [dataset, setDataSet] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [timePeriod, setTimePeriod] = useState("weekly");
  const [selectedDatasets, setSelectedDatasets] = useState([]);

  const toggleTimePeriod = (tp) => {
    setTimePeriod(tp);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const resp = await getBookingAmountGraph(timePeriod);
        if (isMounted) {
          setDataSet(resp.data.results);
          setSelectedDatasets([]); // Reset selection on new data load
          setIsLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          console.error(error.response ? error.response.data.message : error);
          setIsLoading(false);
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, [timePeriod]);

  const handleLegendClick = (index) => {
    setSelectedDatasets((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((i) => i !== index) // Deselect if already selected
        : [...prevSelected, index] // Add to selection if not selected
    );
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          color: "#525f7f",
        },
      },
      y: {
        stacked: true,
        grid: {
          color: "#e9ecef",
        },
        ticks: {
          color: "#525f7f",
          callback: function (value) {
            return `₹ ${value}`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (item) {
            const label = item.dataset.label || "";
            const yLabel = item.raw;
            return `${label}: ₹ ${yLabel}`;
          },
        },
      },
    },
  };

  const barChartData = {
    labels: dataset ? dataset.labels : [],
    datasets: dataset
      ? dataset.datasets
          .filter((_, index) => selectedDatasets.length === 0 || selectedDatasets.includes(index)) // Show only selected datasets
          .map((d, index) => ({
            ...d,
            backgroundColor: apexColors[index % apexColors.length],
          }))
      : [],
  };

  return (
    <Card className="shadow-sm p-4" style={{ borderRadius: "12px" }}>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="mb-0" style={{ color: "#2c3e50" }}>
          <strong>Booking Revenue</strong>
        </h2>
        <Nav pills>
          <NavItem>
            <NavLink
              className={classnames("py-1 px-3", { active: timePeriod === "yearly" })}
              style={{
                backgroundColor: timePeriod === "yearly" ? "#d63031" : "transparent",
                color: "#2c3e50",
                borderRadius: "10px",
                transition: "0.3s ease",
              }}
              onClick={() => toggleTimePeriod("yearly")}
            >
              Y
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("py-1 px-3", { active: timePeriod === "monthly" })}
              style={{
                backgroundColor: timePeriod === "monthly" ? "#6c5ce7" : "transparent",
                color: "#2c3e50",
                borderRadius: "10px",
                transition: "0.3s ease",
              }}
              onClick={() => toggleTimePeriod("monthly")}
            >
              M
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("py-1 px-3", { active: timePeriod === "weekly" })}
              style={{
                backgroundColor: timePeriod === "weekly" ? "#00b894" : "transparent",
                color: "#2c3e50",
                borderRadius: "10px",
                transition: "0.3s ease",
              }}
              onClick={() => toggleTimePeriod("weekly")}
            >
              W
            </NavLink>
          </NavItem>
        </Nav>
      </div>

      <div
        style={{
          height: "1px",
          background: "linear-gradient(90deg, black, white)",
          marginBottom: "15px",
          borderRadius: "2px",
          transition: "width 0.5s ease",
          width: "100%",
        }}
      ></div>

      <CardBody className="pt-3  ">
        {isLoading ? (
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : dataset ? (
          <div className="chart" style={{ height: "350px" }}>
            <Bar key={selectedDatasets.join(",")} data={barChartData} options={options} />
          </div>
        ) : null}
      </CardBody>

      {/* Custom Legend */}
      {dataset && (
        <div className="custom-legend mt--2">
          <Row>
            {dataset.datasets.map((d, index) => (
              <Col xs="6" sm="3" key={index} className="mb-3">
                <div
                  className="legend-item d-flex align-items-center"
                  onClick={() => handleLegendClick(index)}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className="legend-color-circle"
                    style={{
                      backgroundColor: apexColors[index % apexColors.length],
                      opacity: selectedDatasets.includes(index) || selectedDatasets.length === 0 ? 1 : 0.3,
                    }}
                  />
                  <span
                    className="legend-label"
                    style={{
                      textDecoration: selectedDatasets.includes(index) || selectedDatasets.length === 0 ? "none" : "line-through",
                    }}
                  >
                    {d.label}
                  </span>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </Card>
  );
};

export default BookingAmountGraph;
