import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardFooter, Table, Row } from "reactstrap";
import Pagination from "../../../../constant/Pagination.jsx";
import {
  CheckTransactionStatus,
  getBookingList,
} from "../../../../services/RoomService.js";
import useWindowSize from "../../../../constant/WindowSize";
import { OverlayTrigger, Tooltip, Button, Modal } from "react-bootstrap";
import EditBookingModal from "./EditBookingModal";
import { LuArrowUpDown } from "react-icons/lu";
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";
import PaymentStatusModal from "../PopUpPage/PaymentStatusModal.js";
import { useNavigate } from "react-router-dom";
import PendingBookingModal from "./PendingBooking.js";
import Loader from "../../../Loader/Loader.js";
import TableNoResultFound from "../../../../constant/NoResultFound/TableNoResultFound.js";
import { BsListColumns } from "react-icons/bs";
import RoomTypeDropDown from "../../../../views/BookingStatus/DropDown.js";
import DateRangeDropdown from "../../../../constant/DateRangeFilter.js";
import BookingReceipt from "./BookingDetails.js";

const recordsPage = 15;

const BookingHistory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [paymentData, setPaymentData] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    room_type: "",
    start_date: "",
    end_date: "",
  });

  const [expandedRows, setExpandedRows] = useState([]);
  const [sortBy, setSortBy] = useState("-created_at");
  const initialVisibleHeaders = [
    "Booking ID",
    "Room Name",
    "Booking Date",
    "Amount",
  ];

  const headers = [
    {
      label: "Booking ID",
      accessor: (item) => item?.booking_number,
      sortKey: "booking_number",
      width: 20,
    },
    {
      label: "Room Name",
      accessor: (item) => (
        <>
          <span className="text-primary cursor-pointer" onClick={() => navigate(`/room-details/${item?.booking_slot?.room?.id}`)}>{item.booking_slot?.room?.name}</span>
          {item.booking_status === "Modified" &&
            item.booking_slot?.status === "Booked" && (
              <OverlayTrigger
                trigger="click"
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-modify-${item}`}>
                    <div
                      style={{
                        padding: "15px",
                        color: "#fff",
                        backgroundColor: "#343a40",
                        borderRadius: "5px",
                      }}
                    >
                      <strong style={{ fontSize: "14px" }}>
                        Previous Details
                      </strong>
                      <hr
                        style={{
                          color: "white",
                          margin: "5px 0",
                          borderColor: "white",
                        }}
                      />
                      <div style={{ fontSize: "12px" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Booking Date:{" "}
                        </span>
                        {item.previous_booking_date
                          ? item.previous_booking_date
                          : "N/A"}
                        <br />
                        <span style={{ fontWeight: "bold" }}>
                          Booking Time:{" "}
                        </span>
                        {item.previous_booking_time
                          ? item.previous_booking_time
                          : "N/A"}
                      </div>
                    </div>
                  </Tooltip>
                }
                rootClose="true"
              >
                <i
                  className="fas fa-info-circle ms-1"
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                ></i>
              </OverlayTrigger>
            )}
        </>
      ),
      sortKey: "booking_slot__room__name",
      width: 25,
    },
    {
      label: "Booking Date",
      accessor: (item) => item?.booking_slot?.booking_date,
      sortKey: "booking_slot__booking_date",
      width: 25,
    },
    {
      label: "Booking Time",
      accessor: (item) => item?.booking_slot?.booking_time,
      sortKey: "booking_slot__booking_time",
      width: 20,
    },
    {
      label: "Date of Booking",
      accessor: (item) => item?.created_at,
      sortKey: "created_at",
      width: 20,
    },
    {
      label: "Amount",
      accessor: (item) => item?.booking_net_amount,
      sortKey: "booking_net_amount",
      width: 15,
    },
  ];
  const initialColumns = headers.filter((header) =>
    initialVisibleHeaders.includes(header.label)
  );
  const additionalColumns = headers.filter(
    (header) => !initialVisibleHeaders.includes(header.label)
  );

  const [visibleHeaders, setVisibleHeaders] = useState(initialColumns);
  const [selectedColumns, setSelectedColumns] = useState(
    () => new Set(initialColumns.map((column) => column.label))
  );
  const [columnOrder, setColumnOrder] = useState(
    visibleHeaders.map((header) => header.sortKey)
  );
  const [showModal, setShowModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleColumnSelection = (label) => {
    const updatedColumns = new Set(selectedColumns);

    if (updatedColumns.has(label)) {
      updatedColumns.delete(label);
    } else {
      updatedColumns.add(label);
    }

    setSelectedColumns(updatedColumns);
    setSelectAll(updatedColumns.size === headers.length);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedColumns(new Set());
    } else {
      setSelectedColumns(new Set(headers.map((header) => header.label)));
    }
    setSelectAll(!selectAll);
  };

  const handleApplyColumns = () => {
    const updatedVisibleHeaders = headers.filter((header) =>
      selectedColumns.has(header.label)
    );
    setVisibleHeaders(updatedVisibleHeaders);
    setColumnOrder(updatedVisibleHeaders.map((header) => header.sortKey));
    setShowModal(false);
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setPaymentData(null);
    }
    setIsModalOpen(!isModalOpen);
  };

  async function getTransactionStatus(payment_id) {
    try {
      toggleModal();
      const resp = await CheckTransactionStatus(payment_id);
      const data = resp.data.results;
      setPaymentData(data);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error checking payment status:", error);
      toggleModal();
    }
  }

  const handleBookingDetail = (item) => {
    setSelectedBooking(item);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const payment_id = queryParams.get("payment_id");
    if (payment_id) {
      getTransactionStatus(payment_id);
      navigate(location.pathname, { replace: true });
    }
  }, [location.search]);

  const renderColumnOptions = (columns) => {
    return columns.map((header, index) => (
      <div
        key={index}
        className="table-component__column-option"
        onClick={() => handleColumnSelection(header.label)}
        style={{ userSelect: "none" }}
      >
        <label className="table-component__checkbox-label">
          <input
            type="checkbox"
            className="table-component__checkbox"
            checked={selectedColumns.has(header.label)}
            onChange={() => handleColumnSelection(header.label)}
          />
          {header.label}
        </label>
        {selectedColumns.has(header.label) && (
          <span
            className="cancel-icon"
            onClick={(e) => {
              e.stopPropagation();
              handleColumnSelection(header.label);
            }}
          >
            ×
          </span>
        )}
      </div>
    ));
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const fromIndex = e.dataTransfer.getData("text/plain");
    const newOrder = [...columnOrder];
    const [movedItem] = newOrder.splice(fromIndex, 1);
    newOrder.splice(index, 0, movedItem);
    setColumnOrder(newOrder);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const size = useWindowSize();
  const isMobile = size.width <= 766;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getBookingList(
      currentPage,
      recordsPage,
      searchQuery,
      filter.room_type,
      filter.start_date,
      filter.end_date,
      sortBy
    )
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response.data.message);
        } else {
          console.error(error);
        }
        setIsLoading(false);
      });
  }, [
    currentPage,
    searchQuery,
    filter.room_type,
    filter.start_date,
    filter.end_date,
    sortBy,
  ]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const handleResetDateFilter = () => {
    setFilter({
      ...filter,
      start_date: "",
      end_date: "",
    });
  };

  const handleRowExpandToggle = (index) => {
    let newExpandedRows = [...expandedRows];
    if (newExpandedRows.includes(index)) {
      newExpandedRows = newExpandedRows.filter(
        (rowIndex) => rowIndex !== index
      );
    } else {
      newExpandedRows.push(index);
    }
    setExpandedRows(newExpandedRows);
  };

  const updateBookingData = (updatedBooking) => {
    setData(
      data.map((item) => {
        if (item.id === updatedBooking.id) {
          return updatedBooking;
        }
        return item;
      })
    );
  };
  const handleSort = (key) => {
    const sorbtbysplit = sortBy.split("-");
    const isAsc = sorbtbysplit.length > 1;
    const sortByValue = isAsc ? sorbtbysplit[1] : sortBy;
    if (sortByValue === key) {
      setSortBy(isAsc ? key : `-${key}`);
    } else {
      setSortBy(key);
    }
  };

  const renderSortIcon = (key) => {
    const sorbtbysplit = sortBy.split("-");
    const isAsc = sorbtbysplit.length > 1;
    const sortByValue = isAsc ? sorbtbysplit[1] : sortBy;
    if (sortByValue === key) {
      return isAsc ? <HiArrowNarrowUp /> : <HiArrowNarrowDown />;
    }
    return (
      <>
        <LuArrowUpDown />
      </>
    );
  };

  return (
    <>
      {selectedBooking ? (
        <BookingReceipt
          booking={selectedBooking}
          onClose={() => setSelectedBooking(null)}
        />
      ) : (
        <div
          className="dark:bg-gray-900 dark:text-white bg-gray-50 py-sm-3 py-2 px-sm-6 px-0"
          style={{ minHeight: "100vh" }}
        >
          <section data-aos="fade-up" className="container mt-5 px-2">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h1 className="mb-4 mt-3 border-l-8 border-primary/50 pl-2 text-xl font-bold">
                Booking History
              </h1>
              <div className="row align-items-center mb-2">
                <div className="position-relative col-md-7">
                  <DateRangeDropdown
                    filter={filter}
                    setFilter={setFilter}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    handleResetDateFilter={handleResetDateFilter}
                  />
                </div>

                <div
                  className={`col-md-3 d-flex ${isMobile
                    ? "justify-content-start mt-2"
                    : "justify-content-between"
                    } align-items-center gap-3`}
                >
                  <RoomTypeDropDown
                    setFilter={setFilter}
                    filter={filter}
                    setCurrentPage={setCurrentPage}
                    style={{
                      border: "1px solid #ced4da",
                      borderRadius: "0.375rem",
                      color: "#6c757d",
                      backgroundColor: "white",
                      ...(isMobile ? { width: "95vw" } : {}),
                    }}
                    className="py-2"
                  />
                </div>
                <div className="col-md-1 mb-sm-0 mb-2">
                  {!isMobile && (
                    <>
                      <button
                        className="btn btn-sm btn-info"
                        onClick={() => setShowModal(true)}
                      >
                        <BsListColumns
                          className="p-1"
                          style={{ fontSize: "1.4rem" }}
                        />
                      </button>
                      <Modal
                        className="table-component__modal"
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        size="lg"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Select Columns</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="table-component__column-options">
                            <div className="table-component__select-all mb-3">
                              <label className="table-component__checkbox-label">
                                <input
                                  type="checkbox"
                                  className="me-2"
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                                Select All
                              </label>
                            </div>
                            {renderColumnOptions(initialColumns)}
                            {renderColumnOptions(additionalColumns)}
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            className="btn btn-secondary"
                            onClick={() => setShowModal(false)}
                          >
                            Close
                          </button>
                          <button
                            className="btn btn-primary"
                            onClick={handleApplyColumns}
                          >
                            Apply
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </>
                  )}
                </div>
              </div>
            </div>

            {isMobile ? (
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead
                        className="thead-light disable-select"
                        style={{ userSelect: "none" }}
                      >
                        <tr style={{ fontSize: "1rem" }}>
                          <th
                            scope="col"
                            className="py-3"
                            onClick={() =>
                              handleSort("booking_slot__room__name")
                            }
                          >
                            <div className="d-flex align-items-center">
                              Room Name
                              <span className="ms-1">
                                {renderSortIcon("booking_slot__room__name")}
                              </span>
                            </div>
                          </th>

                          <th
                            scope="col"
                            className="py-3"
                            onClick={() =>
                              handleSort("booking_slot__booking_date")
                            }
                          >
                            <div className="d-flex align-items-center">
                              Booking
                              <span className="ms-1">
                                {renderSortIcon("booking_slot__booking_date")}
                              </span>
                            </div>
                          </th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="3" className="text-center my-7">
                              <Loader />
                            </td>
                          </tr>
                        ) : data ? (
                          data.map((item, index) => (
                            <React.Fragment key={index}>
                              <tr
                                key={index}
                                className={`${item?.booking_status === "Refunded" ||
                                  item?.booking_slot?.status === "Closed"
                                  ? "text-gray"
                                  : ""
                                  }`}
                              >
                                <td>
                                  <span className="text-primary cursor-pointer" onClick={() => navigate(`/room-details/${item?.booking_slot?.room?.id}`)}>{item.booking_slot?.room?.name}</span>
                                  {(item.booking_status === "Booked" || item.booking_status === "Modify") &&
                                    (item.previous_booking_date || item.previous_booking_time || item.previous_room) && (
                                      <OverlayTrigger
                                        trigger={["hover", "click"]}
                                        placement="top"
                                        overlay={
                                          <Tooltip id={`tooltip-modify-${index}`}>
                                            <div
                                              style={{
                                                padding: "15px",
                                                color: "#fff",
                                                backgroundColor: "#343a40",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <strong style={{ fontSize: "14px" }}>Previous Details</strong>
                                              <hr
                                                style={{
                                                  color: "white",
                                                  margin: "5px 0",
                                                  borderColor: "white",
                                                }}
                                              />
                                              <div style={{ fontSize: "12px" }}>
                                                {item.previous_room && item.previous_room !== item.booking_slot.room.name ? ( // Only show if the room has changed
                                                  <>
                                                    <span style={{ fontWeight: "bold" }}>Previous Room: </span>
                                                    {item.previous_room}
                                                    <br />
                                                  </>
                                                ) : null}

                                                {item.previous_booking_date ? (
                                                  <>
                                                    <span style={{ fontWeight: "bold" }}>Booking Date: </span>
                                                    {item.previous_booking_date}
                                                    <br />
                                                  </>
                                                ) : (
                                                  <span style={{ fontStyle: "italic" }}>Date not changed<br /></span>
                                                )}
                                                {item.previous_booking_time ? (
                                                  <>
                                                    <span style={{ fontWeight: "bold" }}>Booking Time: </span>
                                                    {item.previous_booking_time}
                                                    <br />
                                                  </>
                                                ) : (
                                                  <span style={{ fontStyle: "italic" }}>Time not changed<br /></span>
                                                )}
                                              </div>
                                            </div>
                                          </Tooltip>
                                        }
                                        rootClose
                                      >
                                        <i
                                          className="fas fa-info-circle ms-1 my-1"
                                          style={{
                                            color: "blue",
                                            cursor: "pointer",
                                          }}
                                        ></i>
                                      </OverlayTrigger>
                                    )}
                                  {/* Show tooltip for "Refunded" or "Refunded Processed" status */}

                                  {item.refund &&
                                    (item.booking_status === "Refunded" ||
                                      item.booking_status ===
                                      "Refund Processed" ||
                                      item.booking_status === "Cancelled") && (
                                      <OverlayTrigger
                                        trigger={["hover", "click"]}
                                        placement="top"
                                        overlay={
                                          <Tooltip
                                            id={`tooltip-refund-${index}`}
                                          >
                                            <div
                                              style={{
                                                padding: "15px",
                                                color: "#fff",
                                                backgroundColor: "#343a40",
                                                borderRadius: "5px",
                                              }}
                                            >
                                              <strong
                                                style={{ fontSize: "14px" }}
                                              >
                                                Refund Details
                                              </strong>
                                              <hr
                                                style={{
                                                  color: "white",
                                                  margin: "5px 0",
                                                  borderColor: "white",
                                                }}
                                              />
                                              <div style={{ fontSize: "12px" }}>
                                                <span
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  Cancellation Date:{" "}
                                                </span>
                                                {item?.refund
                                                  ?.refund_requested_at ||
                                                  "N/A"}
                                                <br />
                                                <span
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  Refund Date:{" "}
                                                </span>
                                                {item?.refund
                                                  ?.refund_completed_at ||
                                                  "N/A"}
                                              </div>
                                            </div>
                                          </Tooltip>
                                        }
                                        rootClose
                                      >
                                        <i
                                          className="fas fa-info-circle ms-1 my-1"
                                          style={{ color: "blue", cursor: "pointer" }}
                                        ></i>
                                      </OverlayTrigger>
                                    )}
                                </td>

                                <td>{item.booking_slot?.booking_date}</td>
                                <td>
                                  <Button
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => handleRowExpandToggle(index)}
                                  >
                                    <span>
                                      {item.booking_status === "Cancelled" ||
                                        item.booking_status ===
                                        "Refund Processed" ? (
                                        <span className="text-danger px-2">
                                          More...
                                        </span>
                                      ) : item.booking_slot?.status ===
                                        "Closed" ? (
                                        <span className="text-gray px-2">
                                          More...
                                        </span>
                                      ) : item.booking_status === "Refunded" ? (
                                        <span className="text-Purple px-2">
                                          More...
                                        </span>
                                      ) : item?.booking_status === "Pending" ? (
                                        <span className="text-danger px-2">
                                          More...
                                        </span>
                                      ) : (
                                        <span className="text-success px-2">
                                          More...
                                        </span>
                                      )}
                                    </span>
                                  </Button>
                                </td>
                              </tr>
                              {expandedRows.includes(index) && (
                                <tr>
                                  <td colSpan="3">
                                    <div>
                                      <div
                                        style={{
                                          marginBottom: "10px",
                                          color: "#555",
                                        }}
                                      >
                                        <strong style={{ color: "#333" }}>
                                          Booking Number:{" "}
                                        </strong>
                                        <span
                                          style={{ cursor: "pointer" }}
                                          className="text-primary"
                                          onClick={() =>
                                            handleBookingDetail(item)
                                          }
                                        >{item.booking_number}</span>
                                      </div>

                                      <div
                                        style={{
                                          marginBottom: "10px",
                                          color: "#555",
                                        }}
                                      >
                                        <strong style={{ color: "#333" }}>
                                          Booking Time:{" "}
                                        </strong>
                                        {item.booking_slot?.booking_time}
                                      </div>
                                      <div
                                        style={{
                                          marginBottom: "10px",
                                          color: "#555",
                                        }}
                                      >
                                        <strong style={{ color: "#333" }}>
                                          Date of Booking:{" "}
                                        </strong>
                                        {item.created_at}
                                      </div>
                                      <div
                                        style={{
                                          marginBottom: "10px",
                                          color: "#555",
                                        }}
                                      >
                                        <strong style={{ color: "#333" }}>
                                          Price:{" "}
                                        </strong>
                                        {item?.booking_net_amount}
                                      </div>

                                      <div
                                        style={{
                                          marginBottom: "10px",
                                          color: "#555",
                                        }}
                                        className="d-flex align-items-center"
                                      >
                                        <strong
                                          className="me-3"
                                          style={{ color: "#333" }}
                                        >
                                          Status:{" "}
                                        </strong>
                                        <span>
                                          {item.booking_status ===
                                            "Cancelled" ||
                                            item.booking_status ===
                                            "Refund Processed" ? (
                                            <span className="text-danger px-2">
                                              Cancelled
                                            </span>
                                          ) : item.booking_slot?.status ===
                                            "Closed" ? (
                                            <span className="text-gray px-2">
                                              Closed
                                            </span>
                                          ) : item.booking_status ===
                                            "Refunded" ? (
                                            <span className="text-purple px-2">
                                              Refunded
                                            </span>
                                          ) : item?.booking_status ===
                                            "Pending" ? (
                                            <PendingBookingModal room={item} />
                                          ) : (
                                            <EditBookingModal
                                              booking={item}
                                              updateBookingData={
                                                updateBookingData
                                              }
                                            />
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))
                        ) : (
                          <TableNoResultFound />
                        )}
                      </tbody>
                    </Table>
                    <CardFooter className="py-4">
                      <Pagination
                        currentPage={currentPage}
                        recordsPage={recordsPage}
                        dataLength={total}
                        handlePagination={handlePagination}
                      />
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            ) : (
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <Table
                      className="table-component__table align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light disable-select">
                        <tr style={{ fontSize: "1rem" }}>
                          {columnOrder.map((sortKey, index) => {
                            const header = visibleHeaders.find(
                              (h) => h.sortKey === sortKey
                            );
                            return (
                              <th
                                key={index}
                                scope="col"
                                className="py-3"
                                onClick={() => handleSort(header.sortKey)}
                                draggable
                                onDragStart={(e) => handleDragStart(e, index)}
                                onDrop={(e) => handleDrop(e, index)}
                                onDragOver={handleDragOver}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {header.label}
                                  <span className="ms-1">
                                    {renderSortIcon(header.sortKey)}
                                  </span>
                                </div>
                              </th>
                            );
                          })}
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td
                              colSpan={columnOrder.length + 1}
                              className="text-center my-7"
                            >
                              <div
                                className="text-center my-7"
                                style={{ width: "100%", height: "100%" }}
                              >
                                <div
                                  className="spinner-grow text-black me-2"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ) : data ? (
                          data.map((item, index) => (
                            <tr
                              key={index}
                              className={`${item?.booking_status === "Refunded" ||
                                item?.booking_slot?.status === "Closed"
                                ? "text-gray"
                                : ""
                                }`}
                            >
                              {columnOrder.map((sortKey, i) => {
                                const header = visibleHeaders.find(
                                  (h) => h.sortKey === sortKey
                                );
                                return (
                                  <td key={i}>
                                    {sortKey === "booking_number" ? (
                                      <div className="d-flex">
                                        <div
                                          style={{ cursor: "pointer" }}
                                          className="text-primary"
                                          onClick={() =>
                                            handleBookingDetail(item)
                                          }
                                        >
                                          {" "}
                                          {header.accessor(item)}{" "}
                                        </div>

                                        {(item.booking_status === "Booked" || item.booking_status === "Modify") &&
                                          (item.previous_booking_date || item.previous_booking_time || item.previous_room) && (
                                            <OverlayTrigger
                                              trigger={["hover", "click"]}
                                              placement="top"
                                              overlay={
                                                <Tooltip id={`tooltip-modify-${index}`}>
                                                  <div
                                                    style={{
                                                      padding: "15px",
                                                      color: "#fff",
                                                      backgroundColor: "#343a40",
                                                      borderRadius: "5px",
                                                    }}
                                                  >
                                                    <strong style={{ fontSize: "14px" }}>Previous Details</strong>
                                                    <hr
                                                      style={{
                                                        color: "white",
                                                        margin: "5px 0",
                                                        borderColor: "white",
                                                      }}
                                                    />
                                                    <div style={{ fontSize: "12px" }}>
                                                      {item.previous_room && item.previous_room !== item.booking_slot.room.name ? ( // Only show if the room has changed
                                                        <>
                                                          <span style={{ fontWeight: "bold" }}>Previous Room: </span>
                                                          {item.previous_room}
                                                          <br />
                                                        </>
                                                      ) : null}

                                                      {item.previous_booking_date ? (
                                                        <>
                                                          <span style={{ fontWeight: "bold" }}>Booking Date: </span>
                                                          {item.previous_booking_date}
                                                          <br />
                                                        </>
                                                      ) : (
                                                        <span style={{ fontStyle: "italic" }}>Date not changed<br /></span>
                                                      )}
                                                      {item.previous_booking_time ? (
                                                        <>
                                                          <span style={{ fontWeight: "bold" }}>Booking Time: </span>
                                                          {item.previous_booking_time}
                                                          <br />
                                                        </>
                                                      ) : (
                                                        <span style={{ fontStyle: "italic" }}>Time not changed<br /></span>
                                                      )}
                                                    </div>
                                                  </div>
                                                </Tooltip>
                                              }
                                              rootClose
                                            >
                                              <i
                                                className="fas fa-info-circle ms-1 my-1"
                                                style={{
                                                  color: "blue",
                                                  cursor: "pointer",
                                                }}
                                              ></i>
                                            </OverlayTrigger>
                                          )}
                                        {/* Show tooltip for "Refunded" or "Refunded Processed" status */}
                                        {item.refund &&
                                          (item.booking_status === "Refunded" ||
                                            item.booking_status ===
                                            "Refund Processed" ||
                                            item.booking_status ===
                                            "Cancelled") && (
                                            <OverlayTrigger
                                              trigger={["hover", "click"]}
                                              placement="top"
                                              overlay={
                                                <Tooltip
                                                  id={`tooltip-refund-${index}`}
                                                >
                                                  <div
                                                    style={{
                                                      padding: "15px",
                                                      color: "#fff",
                                                      backgroundColor:
                                                        "#343a40",
                                                      borderRadius: "5px",
                                                    }}
                                                  >
                                                    <strong
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      Refund Details
                                                    </strong>
                                                    <hr
                                                      style={{
                                                        color: "white",
                                                        margin: "5px 0",
                                                        borderColor: "white",
                                                      }}
                                                    />
                                                    <div
                                                      style={{
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        Cancellation Date:{" "}
                                                      </span>
                                                      {item?.refund
                                                        ?.refund_requested_at ||
                                                        "N/A"}
                                                      <br />
                                                      <span
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        Refund Date:{" "}
                                                      </span>
                                                      {item?.refund
                                                        ?.refund_completed_at ||
                                                        "N/A"}
                                                    </div>
                                                  </div>
                                                </Tooltip>
                                              }
                                              rootClose
                                            >
                                              <i
                                                className="fas fa-info-circle ms-1 my-1"
                                                style={{ color: "blue", cursor: "pointer" }}
                                              ></i>
                                            </OverlayTrigger>
                                          )}
                                      </div>
                                    ) : (
                                      header.accessor(item)
                                    )}
                                  </td>
                                );
                              })}
                              <td>
                                <span>
                                  {item.booking_status === "Refund Processed" ||
                                    item.booking_status === "Cancelled" ? (
                                    <span className="text-danger px-2">
                                      Cancelled
                                    </span>
                                  ) : item.booking_slot?.status === "Closed" ? (
                                    <span className="text-gray px-2">
                                      Closed
                                    </span>
                                  ) : item.booking_status === "Refunded" ? (
                                    <span className="text-purple px-2">
                                      Refunded
                                    </span>
                                  ) : item.booking_status === "Pending" ? (
                                    <PendingBookingModal room={item} />
                                  ) : (
                                    <EditBookingModal
                                      booking={item}
                                      updateBookingData={updateBookingData}
                                    />
                                  )}
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <TableNoResultFound />
                        )}
                      </tbody>
                    </Table>
                    <CardFooter className="py-4">
                      <Pagination
                        currentPage={currentPage}
                        recordsPage={recordsPage}
                        dataLength={total}
                        handlePagination={handlePagination}
                      />
                    </CardFooter>
                  </Card>
                </div>
              </Row>
            )}
          </section>
        </div>
      )}
      <PaymentStatusModal
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        paymentData={paymentData}
      />
    </>
  );
};

export default BookingHistory;
