import Chart from "chart.js";
import Header from "../../components/Headers/Header.js";
import {
  Container,
  Row,
  Col,
} from "reactstrap";

import {
  chartOptions,
  parseOptions,
} from "../../variables/charts.js";
import BookingAmountGraph from "./BookingAmountGraph"
import BookingRoomGraph from "./BookingRoomGraph.js";
import RecentBookingGraph from "./RecentBookingGraph.js";
import RoomTypeGraph from "./RoomTypeGraph.js";
import HighlightData from "./HighlightData.js";

const Dashboard = (props) => {


  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (
    <>
      <Header />
      <Container className="mt-sm-3 mt-7 mb-sm-0 mb-7" fluid>
        <HighlightData />
        <Row>
          <Col className="mb-3 mb-xl-0 p-0 p-sm-2" xl="7">

            <BookingAmountGraph />
          </Col>
          <Col className="p-0 p-sm-2" xl="5">
            <BookingRoomGraph />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col className="mb-3 mb-xl-0 p-0 p-sm-2" xl="7">
            <RoomTypeGraph />
          </Col>
          <Col className="p-0 p-sm-2" xl="5">
            <RecentBookingGraph />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
