import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./modalStyles.css";
import RazorpayPayment from "../PopUpPage/RazorPay";

const PendingBookingModal = ({ room }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = (show) => {
    setShowModal(show);
  };

  const handlePaymentSuccess = () => {
    // Close the modal after successful payment
    setShowModal(false);
  };

  return (
    <>
      <span
        className="text-orange px-2 cursor-pointer"
        onClick={() => toggleModal(true)}
      >
        <i className="fa-solid fa-pen-to-square"></i> Pending
      </span>

      <Modal show={showModal} onHide={() => toggleModal(false)} centered>
        <Modal.Body className="py-3 px-4">
          <div className="d-flex justify-content-center align-items-center mb-2">
            <div className="round-div border-0">
              <i className="fas fa-credit-card"></i>
            </div>
          </div>
          <h4 className="text-center mb-2">
            <strong>
              Your booking "{room?.booking_number}" is still pending. Please
              complete the payment to confirm your reservation.
            </strong>
          </h4>
          <div style={{ fontSize: "0.6rem", color: "gray" }} className="mb-3">
            <i className="fas fa-info-circle me-2"></i>
            <span>
              Please be aware that if the payment is not completed within the next
              24 hours, your booking will be automatically canceled.
            </span> 
            <br />
            <i className="fas fa-info-circle me-2"></i>
            <span>
              To ensure your reservation is secured, please make the payment as soon as
              possible using the "Pay Now" button.
            </span>
          </div>
          <div className="text-center">
            <RazorpayPayment
              price={Math.ceil(room?.booking_net_amount)}
              bookingId={room?.id}
              roomId={room?.booking_slot?.room?.id}
              bookingDate={room.booking_slot?.booking_date}
              bookingTime={room.booking_slot?.booking_time}
              paymentSource="Razorpay"
              paymentMode="Online"
              isPendingBooking="true"
              onPaymentSuccess={handlePaymentSuccess} // Pass the callback
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PendingBookingModal;
