import React, { useState } from "react";
import html2pdf from "html2pdf.js";
import { FaDownload } from "react-icons/fa";
import useWindowSize from "../../constant/WindowSize";
import { sendEmail } from "../../services/RoomService.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../.././../src/assets/img/brand/logo.png";
import "./Payment.css";

const PaymentDetails = ({ transaction, onClose }) => {
  const size = useWindowSize();
  const isMobile = size.width <= 1024;
  const [isLoading, setIsLoading] = useState(false);

  if (!transaction) return null;

  const price =
    (transaction?.net_amount || 0) -
    (transaction?.gst_amount || 0) +
    (transaction?.discount_amount || 0);

  // Function to send transaction ID to backend

  const sendPaymentInvoice = () => {
    setIsLoading(true); // Start loader
    sendEmail(transaction?.id)
      .then(() => {
        toast.success("Email sent successfully!");
      })
      .catch((error) => {
        toast.error("Error sending Email");
        console.error("Error sending Email:", error);
      })
      .finally(() => {
        setIsLoading(false); // Stop loader
      });
  };

  const downloadInvoice = () => {
    const element = document.getElementById("invoice");
    const buttons = document.querySelectorAll(".hide-when-printing");
    buttons.forEach((button) => (button.style.display = "none"));

    const options = {
      margin: 0.5,
      filename: "invoice.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        buttons.forEach((button) => (button.style.display = ""));
      })
      .save();
  };

  return (
    <div id="invoice" className="payment-card mx-2 mx-sm-9 mt-sm-4 mt-7 py-3">
      <div className="d-flex align-items-start align-items-sm-center justify-content-between mx-2 mx-sm-3 mb-3">
        <div className="d-flex align-items-center mb-2 mb-sm-0">
          <img
            src={logo}
            alt="Company Logo"
            className="me-2"
            style={{ height: "40px", width: "auto" }}
          />
          <h2 className="mb-0 fs-5 fs-sm-4">Payment Receipt</h2>
        </div>
        <div>
          <button
            className={`btn btn-success ${isMobile ? "px-2" : "btn-sm px-3"
              } text-center hide-when-printing`}
            onClick={downloadInvoice}
          >
            {isMobile ? <FaDownload /> : "Download Invoice"}
          </button>
        </div>
      </div>

      <hr className="divider mx-0 mx-sm-3" />

      {/* Payment Details */}
      <div className="payment-details mx-0 mx-sm-3">
        <table className="table table-borderless">
          <tbody>
            <tr className="">
              <td className="invoiced-to">
                <p className={`${isMobile ? "text-sm" : ""}`}>
                  <strong>Invoiced To:</strong>
                  <br />
                  {transaction.user?.first_name} {transaction.user?.last_name}{" "}
                  <br />
                  {transaction.user?.email} <br />
                  Address: {transaction.user?.address || "123 Main St, XYZ City"} <br />
                  City: {transaction.user?.city || "Dummy City"}, Pincode:{" "}
                  {transaction.user?.pin_code || "123456"}

                </p>
              </td>
              {!isMobile && (
                <td className="pay-to text-end">
                  <p>
                    <strong>Pay To:</strong>
                    <br />
                    Rourkela Club <br />
                    Sector 20 <br />
                    Rourkela, 769005 <br />
                    info@rourkelaclub.com
                  </p>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </div>

      <hr className="divider mx-2 mx-sm-3" />

      {/* Payment Method and Order Date */}
      <div className="mx-0 mx-sm-3">
        <table className="table table-borderless">
          <tbody>
            <tr>
              <td className={`payment-method ${isMobile ? "text-start flex-fill" : ""}`}>
                <p className={`${isMobile ? "text-xs" : ""}`}>
                  <strong>Payment Source:</strong> {transaction?.payment_source}
                  <br />
                  <strong>Transaction Id:</strong>{" "}
                  {transaction?.reference_number}
                </p>
              </td>
              <td className={`order-date text-end ${isMobile ? "text-start flex-fill" : ""}`}>
                {isMobile ? (
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="text-xs me-6">
                      <strong>Order Date:</strong> {transaction?.created_at}
                    </p>
                    <p className="text-xs">
                      <strong>Room Name:</strong>{" "}
                      {transaction.booking_slot?.room?.name}
                    </p>
                  </div>
                ) : (
                  <p>
                    <strong>Order Date:</strong> {transaction?.created_at}
                  </p>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <hr className="divider mx-2 mx-sm-3" />

      {/* Payment Summary */}
      <div className="payment-summary mx-2 mx-sm-3 mt-3 mb-7">
        <h2 className="summary-title mb-2 fs-6">
          <strong>Payment Summary</strong>
        </h2>
        <table className="table table-hover">
          <thead>
            <tr>
              {!isMobile && <th>Room Name</th>}
              <th className={`text-center ${isMobile ? "text-xs" : ""}`}>Price</th>
              <th className={`text-center ${isMobile ? "text-xs" : ""}`}>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {!isMobile && (
                <td className="room-name">
                  <em>{transaction.booking_slot?.room?.name}</em>
                </td>
              )}
              <td className="price text-center">₹{price.toFixed(2)}</td>
              <td className="total text-center">₹{price.toFixed(2)}</td>
            </tr>
            <tr>
              {!isMobile && <td></td>}
              <td className={`subtotal ${isMobile ? "text-end" : ""}`}>
                <p>
                  <strong>Subtotal:</strong>
                </p>
                {transaction?.discount_amount > 0 && (
                  <p>
                    <strong>Discount:</strong>
                  </p>
                )}
                <p>
                  <strong>CGST:</strong>
                </p>
                <p>
                  <strong>SGST:</strong>
                </p>
              </td>
              <td className="amounts text-center">
                <p>
                  <strong>₹{price.toFixed(2)}</strong>
                </p>
                {transaction?.discount_amount > 0 && (
                  <p>
                    <strong>- ₹{transaction?.discount_amount.toFixed(2)}</strong>
                  </p>
                )}
                <p>
                  <strong>+ ₹{(transaction?.gst_amount / 2).toFixed(2)}</strong>
                </p>
                <p>
                  <strong>+ ₹{(transaction?.gst_amount / 2).toFixed(2)}</strong>
                </p>
              </td>
            </tr>
            {isMobile ? (
              <>
                <tr>
                  <td className="total-label text-sm-end text-start">
                    <h4>
                      <strong>Total:</strong>
                    </h4>
                  </td>
                  <td className="total-amount text-center text-danger">
                    <h4>
                      <strong>₹{transaction?.net_amount}</strong>
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex gap-2">
                    <button
                      className="btn btn-danger btn-sm px-3 hide-when-printing"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary btn-sm px-3 hide-when-printing"
                      onClick={sendPaymentInvoice}
                      disabled={isLoading}
                    >
                      {isLoading ? "Sending..." : "Send Email"}
                    </button>
                  </td>
                  <td></td>
                </tr>
              </>
            ) : (
              <tr>
                <td className="d-flex gap-2">
                  <button
                    className="btn btn-danger btn-sm px-3 hide-when-printing"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary btn-sm px-3 hide-when-printing"
                    onClick={sendPaymentInvoice}
                    disabled={isLoading}
                  >
                    {isLoading ? "Sending..." : "Send Email"}
                  </button>
                </td>
                <td className="total-label">
                  <h4>
                    <strong>Total:</strong>
                  </h4>
                </td>
                <td className="total-amount text-center text-danger">
                  <h4>
                    <strong>₹{transaction?.net_amount.toFixed(2)}</strong>
                  </h4>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentDetails;
