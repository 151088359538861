import React, { useState } from 'react';
import PaymentsComponent from './Payments';
import RefundsComponent from './Refunds'; 
const PaymentRefundPage = () => {
  const [activeTab, setActiveTab] = useState('payments'); 

  return (
    <div>      
      {activeTab === 'payments' && <PaymentsComponent activeTab={activeTab} setActiveTab={setActiveTab} />}
      {activeTab === 'refunds' && <RefundsComponent activeTab={activeTab} setActiveTab={setActiveTab} />}
    </div>
  );
};

export default PaymentRefundPage;
