import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { updateMemberAPI, generateMembershipIdAPI } from "../../services/VerifyMemberService";

const INPUT_FIELD_LIST = [
  { label: "First Name", name: "first_name", type: "text", placeholder: "Enter your first name" },
  { label: "Last Name", name: "last_name", type: "text", placeholder: "Enter your last name" },
  {
    label: "Membership Type",
    name: "membership_type",
    type: "select",
    placeholder: "Select membership type",
    options: [
      { label: "SAIL RSP Member", value: "SAIL RSP Member" },
      { label: "GOVERNMENT Member", value: "GOVERNMENT Member" },
      { label: "PRIVATE Member", value: "PRIVATE Member" },
      { label: "Honorary Member", value: "Honorary Member" },
    ],
  },
  { label: "Email ID", name: "email", type: "email", placeholder: "Enter your email address" },
  { label: "Phone Number", name: "phone_number", type: "tel", placeholder: "Enter your phone number" },
];

const initialFormState = {
  first_name: "",
  last_name: "",
  membership_type: "",
  membership_id: "",
  email: "",
  phone_number: "",
  personnel_number: "",
};

const EditMemberPopup = ({ member, data, setData, onClose }) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [initialMembershipType, setInitialMembershipType] = useState(""); // To store the initial membership type
  const [errors, setErrors] = useState(initialFormState);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (member) {
      const { id, first_name, last_name, email, phone_number, membership_type, membership_id, personnel_number } = member;
      setFormData({
        member_id: id,
        first_name,
        last_name,
        email,
        phone_number,
        membership_type,
        membership_id,
        personnel_number: personnel_number || "",
      });
      setInitialMembershipType(membership_type);
      setIsChanged(false); // Set the initial membership type
    }
  }, [member]);

  // Generate Membership ID when membership type changes
  useEffect(() => {
    if (formData.membership_type && formData.membership_type !== initialMembershipType) {
      const membershipTypePayloadMap = {
        "SAIL RSP Member": "RSP",
        "GOVERNMENT Member": "RSP",
        "PRIVATE Member": "RSP",
        "Honorary Member": "HON",
      };
      const mappedMembershipType = membershipTypePayloadMap[formData.membership_type] || formData.membership_type;

      generateMembershipIdAPI(mappedMembershipType)
        .then((resp) => {
          const fullId = resp.data.results;
          setFormData((prevData) => ({
            ...prevData,
            membership_id: fullId,
          }));
          const prefix = fullId.startsWith("HON") ? "HON" : "RSP";
          const number = fullId.slice(prefix.length);
          setFormData((prevData) => ({
            ...prevData,
            membership_id: fullId,
            membership_id_prefix: prefix,
            membership_id_number: number,
          }));
          setIsChanged(true);
        })
        .catch(() => {
          toast.error("Failed to generate membership ID");
        });
    }
  }, [formData.membership_type, initialMembershipType]);


  const handleValidate = (name, value) => {
    if (name === 'email') {
      if (!value) {
        setErrors({ ...errors, [name]: 'Email is required' });
        return false;
      } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        setErrors({ ...errors, [name]: 'Invalid Email format.' });
        return false;
      } else {
        setErrors({ ...errors, [name]: '' });
        return true;
      }
    } else if (name === 'phone_number') {
      if (!value) {
        setErrors({ ...errors, [name]: 'Phone number is required' });
        return false;
      } else if (!/^\d{10}$/.test(value)) {
        setErrors({ ...errors, [name]: 'Invalid phone number.' });
        return false;
      } else {
        setErrors({ ...errors, [name]: '' });
        return true;
      }
    } else if (name === 'personnel_number') {
      if (formData.membership_type === "SAIL RSP Member") {
        if (!value) {
          setErrors({ ...errors, [name]: 'Personnel Number is required.' });
          return false;
        } else if (!/^\d{6}$/.test(value)) {
          setErrors({ ...errors, [name]: 'Personnel Number must be a 6-digit number.' });
          return false;
        } else {
          setErrors({ ...errors, [name]: '' });
          return true;
        }
      }
      return true;
    } else {
      const errorMsgObj = {
        first_name: "First name",
        last_name: "Last name",
        membership_type: "Membership type",
      };
      if (!value) {
        setErrors({ ...errors, [name]: `${errorMsgObj[name]} is required` });
        return false;
      } else {
        setErrors({ ...errors, [name]: '' });
        return true;
      }
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleValidate(name, value);
    setFormData({ ...formData, [name]: value });
    setIsChanged(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isSubmitLoading) {
      return;
    }

    // Validate the form fields
    const isValidInput = Object.keys(formData).map(key => handleValidate(key, formData[key])).every(isValid => isValid);

    if (isValidInput) {
      setIsSubmitLoading(true);


      if (!isChanged) {
        toast.info("No changes made");
        onClose();
        return;
      }


      updateMemberAPI(formData)
        .then((resp) => {
          const { results, message } = resp.data;


          const updatedData = data.map((d) => d.id === results.id ? results : d);
          setData(updatedData);


          toast.success(message);
          onClose();
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          setIsSubmitLoading(false);
        });
    }
  };

  return (
    <div className="popup">
      <form className="popup-inner" onSubmit={handleSubmit}>
        <div className="popup-header">
          <h2>Modify Member</h2>
        </div>
        <div className="popup-body">
          {INPUT_FIELD_LIST.map(({ label, name, type, placeholder, options }) => (
            <div className="form-group" key={name}>
              <label htmlFor={name}>{label}:</label>
              {type === "select" ? (
                <div className="input-group">
                  <select
                    id={name}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    className="custom-select"
                    onBlur={(e) => handleValidate(e.target.name, e.target.value)}
                  >
                    <option value="" disabled>{placeholder}</option>
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {errors[name] && (
                    <div className="form-error">{errors[name]}</div>
                  )}
                </div>
              ) : (
                <div className="input-group">
                  <input
                    type={type}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    onBlur={(e) => handleValidate(e.target.name, e.target.value)}
                    placeholder={placeholder}
                  />
                  {errors[name] && (
                    <div className="form-error">{errors[name]}</div>
                  )}
                </div>
              )}
            </div>
          ))}

          {/* Show the Membership ID field only if membership_type has changed */}
          {formData.membership_type !== initialMembershipType && (
            <div className="form-group">
              <label htmlFor="membership_id">Membership ID:</label>
              <div className="input-group">
                <input
                  type="text"
                  name="membership_id"
                  value={formData.membership_id}
                  onChange={handleChange}
                  onBlur={(e) => handleValidate(e.target.name, e.target.value)}
                  placeholder="Enter membership ID"
                />
                {errors.membership_id && (
                  <div className="form-error">{errors.membership_id}</div>
                )}
              </div>
            </div>
          )}

          {/* Display Personnel Number field conditionally */}
          {formData.membership_type === "SAIL RSP Member" && (
            <div className="form-group">
              <label htmlFor="personnel_number">Personnel Number:</label>
              <div className="input-group">
                <input
                  type="text"
                  name="personnel_number"
                  value={formData.personnel_number}
                  onChange={handleChange}
                  onBlur={(e) => handleValidate(e.target.name, e.target.value)}
                  placeholder="Enter Personnel Number"
                />
                {errors.personnel_number && (
                  <div className="form-error">{errors.personnel_number}</div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="btn-container">
          <button type="submit" className="bg-black text-white" disabled={isSubmitLoading}>Update</button>
          <button type="button" onClick={onClose} className="btn btn-danger">Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default EditMemberPopup;