import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { format, parse } from "date-fns";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  updateRoomBooking,
  getRoomAvailable,
} from "../../../../services/RoomService";
import DynamicSelectInput from "../../../../constant/SelectInput";
import RenderCalendar from "../PopUpPage/RenderCalendar";

const EditBookingModal = ({ booking, updateBookingData }) => {
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);
  const [bookingDate, setBookingDate] = useState(null);
  const [bookingSlot, setBookingSlot] = useState(null);
  const [socketBookingProgress, setSocketBookingProgress] = useState([]);
  const [availableData, setAvailableData] = useState([]);
  const [disableDateList, setDisableDateList] = useState([]);
  const [bookedDisableDateList, setBookedDisableDateList] = useState([]);
  const [showCancelBooking, setShowCancelBooking] = useState(false);
  const [bookingToCancel, setBookingToCancel] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [cancelReasonError, setCancelReasonError] = useState("");
  const [cancelReason, setCancelReason] = useState({
    cancel_purpose_id: "",
    cancel_reason: "",
  });
  const [cancelPurposeError, setCancelPurposeError] = useState("");

  const calendarRef = useRef(null);
  const socket = useRef(null);

  const roomId = booking.booking_slot?.room?.id;
  const userId = booking.booking_user?.user?.id;

  useEffect(() => {
    setBookingSlot(booking.booking_slot?.booking_time || null);
  }, [booking]);

  useEffect(() => {
    if (isOffCanvasOpen) {
      document.body.style.overflow = 'hidden'; 
    } else {
      document.body.style.overflow = 'auto'; 
    }
  }, [isOffCanvasOpen]);

  useEffect(() => {
    if (isOffCanvasOpen && roomId) {
      getRoomAvailable(roomId)
        .then((resp) => {
          const { results } = resp.data;
          setAvailableData(results);
          const bookedDate = results
            .filter(
              (item) =>
                item.status === "Booked" &&
                item.booking_time === booking.booking_slot?.booking_time
            )
            .map((item) => item.booking_date);
          setBookedDisableDateList([...bookedDisableDateList, ...bookedDate]);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    }
  }, [isOffCanvasOpen, roomId, booking.booking_slot?.booking_time]);

  const socketConnection = useCallback(() => {
    if (booking.booking_time) {
      socket.current = new WebSocket(
        `${process.env.REACT_APP_WS_BASE_URL}/ws/bookings`
      );
  
      socket.current.onopen = () => {
        const {booking_time } = booking;
        const socketPayload = {
          event_type: "BOOKING_INIT",
          user_id: userId,
          room_id:roomId,
          booking_time,
        };
        sendMessage(socketPayload);
        console.log("WebSocket connection opened");
      };
  
      socket.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleSocketData(data.message);
      };
  
      socket.current.onclose = (event) => {
        console.log("Socket Disconnected ", event);
        // if (!event.wasClean) {
        //   console.log("Reconnecting WebSocket...");
        //   setTimeout(socketConnection, 1000); // Attempt to reconnect after a delay
        // }
      };
  
      socket.current.onerror = (error) => {
        console.error("WebSocket Error: ", error);
        socket.current.close();
      };
    }
  }, [booking.booking_time]);
  

  useEffect(() => {
    socketConnection();
    return () => {
      if (socket.current) {
        socket.current.close();
      }
    };
  }, [socketConnection]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (booking.booking_date) {
        const socketPayload = {
          event_type: "BOOKING_CANCEL",
          userId,
          roomId,
          booking_date: booking.booking_date
            ? format(booking.booking_date, "yyyy-MM-dd")
            : "",
          booking_time: booking.booking_time,
        };
        sendMessage(socketPayload);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [booking.booking_date]);
  
  const sendMessage = (data) => {
    if (socket.current?.readyState === WebSocket.OPEN) {
      socket.current.send(JSON.stringify(data));
    }
  };

  const updateSocketData = (booking_time, booking_dates) => {
    let updatedSocketBookingProgress = socketBookingProgress.map((item) => {
      if (item.booking_time === booking_time) {
        return {
          booking_time,
          booking_date: [...item.booking_date, ...booking_dates],
        };
      }
      return item;
    });

    if (updatedSocketBookingProgress.length === 0) {
      updatedSocketBookingProgress = [
        { booking_time, booking_date: booking_dates },
      ];
    }
    setSocketBookingProgress(updatedSocketBookingProgress);
  };

  const handleSocketData = (data) => {
    if (data.roomId === roomId) {
      if (data.action === "BOOKING_INIT" && data.user_id === userId) {
        if (data.booking_dates.length > 0) {
          updateSocketData(data.booking_time, [...data.booking_dates]);
          if (booking.booking_time === data.booking_time) {
            setDisableDateList((prevDates) => {
              const updatedDates = [...new Set([...prevDates, ...data.booking_dates])];
              return updatedDates;
            });
          }
        }
      } else if (data.action === "BOOKING_START") {
        const newDisabledDates = [...disableDateList, data.booking_date];
        if (booking.booking_time === data.booking_time) {
          setDisableDateList((prevDates) => {
            const updatedDates = [...new Set([...prevDates, ...newDisabledDates])];
            return updatedDates;
          });
        }
        updateSocketData(data.booking_time, newDisabledDates);
      } else if (data.action === "BOOKING_CANCEL") {
        setDisableDateList((prevDates) => {
          const updatedDisableDateList = prevDates.filter(
            (item) => item !== data.booking_date
          );
          updateSocketData(data.booking_time, updatedDisableDateList);
          return [...new Set([...updatedDisableDateList])];
        });
      }
    }
  };

  const togglePopup = (isBookingSuccess = false) => {
    if (isOffCanvasOpen) {
      if (!isBookingSuccess && booking.booking_date) {
        const socketPayload = {
          event_type: "BOOKING_CANCEL",
          userId,
          roomId,
          booking_date: "",
          booking_time: booking.booking_time,
        };
        sendMessage(socketPayload);
      }
      setDisableDateList([]);
      setSocketBookingProgress([]);
    } else {
      const updateBookingInput = {
        roomId,
        booking_time:
          booking.booking_slot?.booking_time === "09:00 AM to 11:00 PM"
            ? "09:00 AM to 11:00 PM"
            : "09:00 AM to 03:00 PM",
        booking_date: "",
      };
      socket.current = new WebSocket(
        `${process.env.REACT_APP_WS_BASE_URL}/ws/bookings`
      );
      socket.current.onopen = () => {
        const { room_id, booking_time } = updateBookingInput;
        const socketPayload = {
          event_type: "BOOKING_INIT",
          userId,
          room_id,
          booking_time,
        };
        sendMessage(socketPayload);
      };
      socket.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        handleSocketData(data.message);
      };
      socket.current.onclose = (event) => {
        console.log("Socket Disconnected ", event);
      };
    }
    setIsOffCanvasOpen(!isOffCanvasOpen);
  };

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length > 0) {
      setBookingDate(selectedDates[0]);
      setDateError(false);
    }
  };

  const getDateStatus = (date, slotType) => {
    const found = availableData.find((item) => {
      const slotDate = new Date(item.booking_date);
      return (
        slotDate.toDateString() === date.toDateString() &&
        item.booking_time === slotType
      );
    });

    return found
      ? {
          status: found.status,
          username: `${found.booking_user?.user?.first_name} ${found.booking_user?.user?.last_name}`,
        }
      : {};
  };

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    setBookingSlot(selectedTime);
  
    // Fetch available data for the selected time slot
    getRoomAvailable(roomId)
      .then((resp) => {
        const { results } = resp.data;
        setAvailableData(results);
  
        // Filter booked dates based on the selected time slot
        const bookedDate = results
          .filter((item) => item.status === "Booked" && item.booking_time === selectedTime)
          .map((item) => item.booking_date);
  
        setBookedDisableDateList(bookedDate); // Update booked dates
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  };  

  const handleCancelShow = () => {
    setBookingToCancel(booking);
    setShowCancelBooking(true);
  };

  const handleUpdate = () => {
    try {
      if (!bookingDate) {
        setDateError(true);
        return;
      }

      const updatedBooking = {
        id: booking.id,
        booking_date: format(new Date(bookingDate), "yyyy-MM-dd"),
        booking_time: bookingSlot,
      };

      updateRoomBooking(updatedBooking)
        .then((resp) => {
          const { message, results } = resp.data;
          updateBookingData(results);
          toast.success(message);
          setIsOffCanvasOpen(false);
          toast.info(
            "Please contact the admin to confirm the booking within 24 hours. Otherwise, the previous date will be automatically booked.",
            {
              autoClose: false,
            }
          );
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error.response.data.message);
          } else {
            toast.error("An error occurred. Please try again.");
          }
        });
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again.");
    }
  };

  const handleCloseModal = () => {
    setIsOffCanvasOpen(false);
    setBookingDate(null);
    let defaultBookingSlot;
    if (booking.booking_slot?.booking_time === "09:00 AM to 11:00 PM") {
      defaultBookingSlot = "09:00 AM to 11:00 PM";
    } else if (booking.booking_slot?.booking_time === "09:00 AM to 03:00 PM") {
      defaultBookingSlot = "09:00 AM to 03:00 PM";
    } else if (booking.booking_slot?.booking_time === "06:00 PM to 11:00 PM") {
      defaultBookingSlot = "06:00 PM to 11:00 PM";
    } else {
      defaultBookingSlot = "";
    }
    setBookingSlot(defaultBookingSlot);

    setDisableDateList([]);
    setSocketBookingProgress([]);
  };

  const handleSelectChange = (name, value) => {
    setCancelReason((prev) => ({ ...prev, [name]: value }));
    if (name === "cancel_purpose_id" && value) {
      setCancelPurposeError("");
    }
  };

  const handleConfirm = () => {
    // Reset errors
    setCancelPurposeError("");
    setCancelReasonError("");

    // Validate mandatory fields
    if (!cancelReason.cancel_purpose_id) {
      setCancelPurposeError("Cancel Purpose is required.");
    }

    if (!cancelReason.cancel_reason) {
      setCancelReasonError("Reason for cancellation is required.");
    }

    // If both fields are valid, proceed with cancellation
    if (cancelReason.cancel_purpose_id && cancelReason.cancel_reason) {
      if (bookingToCancel) {
        const payload = {
          id: bookingToCancel.id,
          cancel_booking: "YES",
          cancel_purpose: cancelReason.cancel_purpose_id,
          cancel_reason: cancelReason.cancel_reason,
        };

        updateRoomBooking(payload)
          .then((resp) => {
            const { message, results } = resp.data;
            updateBookingData(results);
            toast.success(message);
            setShowCancelBooking(false);
            setIsOffCanvasOpen(false);
            setCancelPurposeError("");
            setCancelReasonError(""); // Reset cancel reason error
          })
          .catch((error) => {
            console.log("error", error);
            if (error?.response) {
              toast.error(error.response?.data?.message || "An error occurred");
            }
          });
      }
    }
  };

  const handleCancelClose = () => {
    setShowCancelBooking(false);
    setCancelReason({ cancel_purpose_id: "", cancel_reason: "" });
    setCancelPurposeError(""); // Reset errors on close
    setCancelReasonError(""); // Reset errors on close
  };

  return (
    <>
      {booking.modified_booking_date || booking.modified_booking_time ? (
        <span className="text-yellow">Requested</span>
      ) : (
        <button
          className="btn btn-sm btn-outline-success"
          onClick={togglePopup}
        >
          <i className="fa-solid fa-pen-to-square"></i> Booked
        </button>
      )}

      {isOffCanvasOpen && (
        <div
          className={`offcanvas-container ${
            isOffCanvasOpen ? "open" : ""
          } mt-sm-0`}
        >
         <div className={`offcanvas-overlay ${isOffCanvasOpen ? "open" : ""}`}>
          <div className="offcanvas-content">
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <h1>
                <strong>Edit Booking</strong>
              </h1>
              <button className="btn text-xl" onClick={handleCloseModal}>
                &times;
              </button>
            </div>
            <div
              style={{
                background: "linear-gradient(135deg, #f0f4f8 0%, #d9e2ec 100%)",
                borderRadius: "15px",
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                padding: "20px",
                fontFamily: "'Poppins', sans-serif",
                animation: "fadeIn 0.5s ease-in-out",
                transition: "transform 0.2s ease-in-out",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "scale(1.02)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "scale(1)")
              }
            >
              <h5
                style={{
                  textAlign: "center",
                  color: "#343a40",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                <i
                  className="fas fa-door-open"
                  style={{ color: "#007bff", marginRight: "10px" }}
                ></i>
                {booking.booking_slot?.room?.name}
              </h5>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                  <strong>
                    <i
                      className="fas fa-hashtag"
                      style={{ color: "#00aaff", marginRight: "8px" }}
                    ></i>
                    Booking Number:
                  </strong>
                  <span style={{ marginLeft: "8px" }}>
                    {booking?.booking_number}
                  </span>
                </li>
                <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                  <strong>
                    <i
                      className="fas fa-calendar-alt"
                      style={{ color: "#28a745", marginRight: "8px" }}
                    ></i>
                    Booking Date:
                  </strong>
                  <span style={{ marginLeft: "8px" }}>
                    {booking.booking_slot?.booking_date}
                  </span>
                </li>
                <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                  <strong>
                    <i
                      className="fas fa-clock"
                      style={{ color: "#ffc107", marginRight: "8px" }}
                    ></i>
                    Booking Time:
                  </strong>
                  <span style={{ marginLeft: "8px" }}>
                    {booking.booking_slot?.booking_time}
                  </span>
                </li>
                <li style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>
                  <strong>
                    <i
                      className="fas fa-money-bill-wave"
                      style={{ color: "#17a2b8", marginRight: "8px" }}
                    ></i>
                    Amount:
                  </strong>
                  <span style={{ marginLeft: "8px" }}>
                    {booking?.booking_net_amount}
                  </span>
                </li>
              </ul>
            </div>

            <Form.Group className="mb-3 mt-3">
              <Form.Label>Booking Slot</Form.Label>
              <br />
              {bookingSlot === "09:00 AM to 11:00 PM" ? (
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="booking_time"
                    id="wholeday"
                    checked
                    readOnly
                  />
                  <label className="form-check-label ml-2" htmlFor="wholeday">
                    Full Day
                  </label>
                </div>
              ) : (
                <>
                  <div className="d-flex flex-wrap justify-content-start">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="booking_time"
                        id="morning"
                        value="09:00 AM to 03:00 PM"
                        checked={bookingSlot === "09:00 AM to 03:00 PM"}
                        onChange={handleTimeChange}
                      />
                      <label
                        className="form-check-label me-sm-2 me-0"
                        htmlFor="morning"
                      >
                        9 AM - 3 PM
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="booking_time"
                        id="evening"
                        value="06:00 PM to 11:00 PM"
                        checked={bookingSlot === "06:00 PM to 11:00 PM"}
                        onChange={handleTimeChange}
                      />
                      <label className="form-check-label" htmlFor="evening">
                        6 PM - 11 PM
                      </label>
                    </div>
                  </div>
                </>
              )}
            </Form.Group>
            <div className="mb-3">
              <label htmlFor="date" className="block">
                Booking Date
              </label>
              <RenderCalendar
                handleDateChange={handleDateChange}
                bookingDate={bookingDate}
                disableDateList={[...disableDateList, ...bookedDisableDateList]}
                calendarRef={calendarRef}
                getDateStatus={getDateStatus}
                slotType={bookingSlot}
              />
              {dateError && (
                <div className="text-danger mt-1">Please select a date.</div>
              )}
            </div>
            <div className="mb-5">
              <span className="me-4">Want to Cancel Booking?</span>
              <button
                className="btn btn-sm btn-danger"
                onClick={handleCancelShow}
              >
                Cancel Booking
              </button>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div className="mb-3">
                <Button
                  variant="success"
                  className="btn btn-sm"
                  onClick={handleUpdate}
                  disabled={!bookingSlot || !bookingDate}
                >
                  Update
                </Button>

                <Button
                  type="button"
                  className="btn btn-sm btn-danger ms-2"
                  variant="secondary"
                  onClick={handleCloseModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
          </div>
        </div>
      )}

      <Modal
        show={showCancelBooking}
        onHide={() => setShowCancelBooking(false)}
        centered
        backdrop="static"
        keyboard={false}
        size="sm"
      >
        <Modal.Body className="p-4">
          <label htmlFor="cancel_purpose_id">
            <strong style={{ fontSize: "0.85em" }} className="me-1">
              Cancel Purpose<span className="text-danger">*</span>
            </strong>
          </label>
          <DynamicSelectInput
            parentClassName="mb-3 order"
            setParentInputValue={handleSelectChange}
            endpoint_name="booking_cancel_purpose"
            name="cancel_purpose_id"
            id="cancel_purpose_id"
            placeholder="Select Cancel Purpose"
          />
          {cancelPurposeError && (
            <p className="text-danger" style={{ fontSize: "0.8em" }}>
              {cancelPurposeError}
            </p>
          )}
          <label htmlFor="description" className="form-label">
            Reason For Cancel Booking:
            <span className="text-danger">*</span>
          </label>
          <textarea
            id="description"
            name="description"
            className="form-control"
            placeholder="Reason"
            rows="4"
            onChange={(e) =>
              setCancelReason((prev) => ({
                ...prev,
                cancel_reason: e.target.value,
              }))
            }
          ></textarea>
          {cancelReasonError && (
            <p className="text-danger" style={{ fontSize: "0.8em" }}>
              {cancelReasonError}
            </p>
          )}
        </Modal.Body>
        <div className="d-flex align-items-center justify-content-center mb-3">
          <Button variant="secondary" onClick={() => handleCancelClose()}>
            No
          </Button>
          <Button variant="danger" onClick={handleConfirm}>
            Yes
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default EditBookingModal;
