import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const Location = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <span id="location"></span>
      <section
        data-aos="fade-up"
        style={{
          backgroundColor: "#f4f4f9", // Light background for a clean look
          padding: "40px 0",
        }}
      >
        <div
          className="container my-4"
          style={{
            maxWidth: "1100px",
            margin: "auto",
            padding: "0 20px",
          }}
        >
          <h1
            style={{
              borderLeft: "8px solid #5C1212", // Matching with the theme color
              paddingLeft: "16px",
              marginBottom: "24px",
              fontSize: "2.5rem",
              fontWeight: "bold",
              color: "#333",
            }}
          >
            Visit Our Location
          </h1>

          <p
            style={{
              fontSize: "1.1rem",
              color: "#666",
              lineHeight: "1.3",
              marginBottom: "24px",
              maxWidth: "900px",
            }}
          >
            Explore the serene surroundings of Rourkela Club, nestled within the
            heart of the Rourkela Steel Plant. Our convenient location makes it
            easily accessible to all, with an expansive view of lush greenery
            and industrial marvels. Here's how you can find us on the map.
          </p>

          <div
            style={{
              borderRadius: "20px",
              overflow: "hidden",
              boxShadow: "0 8px 24px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
              transition: "all 0.3s ease", // Smooth hover effect
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3692.6317944574002!2d84.87733177466056!3d22.254044979719875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a201c2066cd7f69%3A0x5962f6d454cf9c77!2sRourkela%20Club!5e0!3m2!1sen!2sin!4v1721740662674!5m2!1sen!2sin"
              width="100%"
              height="480"
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              style={{
                border: "none", // Clean look without a border
              }}
            ></iframe>
          </div>

          <div
            style={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              style={{
                backgroundColor: "#5C1212", 
                color: "#fff",
                padding: "12px 24px",
                fontSize: "1.1rem",
                fontWeight: "bold",
                borderRadius: "30px",
                border: "none",
                cursor: "pointer",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#761A1A"; 
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "#5C1212"; 
              }}
              onClick={handleOpenModal}
            >
              Get Directions
            </button>
          </div>
        </div>
      </section>

      {/* Modal for Full-Screen Map */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Body style={{ padding: "0" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3692.6317944574002!2d84.87733177466056!3d22.254044979719875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a201c2066cd7f69%3A0x5962f6d454cf9c77!2sRourkela%20Club!5e0!3m2!1sen!2sin!4v1721740662674!5m2!1sen!2sin"
            width="100%"
            height="600"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            style={{
              border: "none",
            }}
          ></iframe>
        </Modal.Body>
        <Modal.Footer style={{ justifyContent: "center" }}>
          <button
            style={{
              backgroundColor: "#5C1212",
              color: "#fff",
              padding: "10px 20px",
              fontSize: "1rem",
              fontWeight: "bold",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
            }}
            onClick={handleCloseModal}
          >
            Close Map
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Location;
