import React, { useState, useEffect } from "react";
import { Card, CardFooter, Table, Container, Row } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Headers/Header.js";
import Pagination from "../../constant/Pagination";
import { getPaymentList } from "../../services/RoomService.js";
import useWindowSize from "../../constant/WindowSize";
import { Button, Modal } from "react-bootstrap";
import { LuArrowUpDown } from "react-icons/lu";
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";
import { FcBullish } from "react-icons/fc";
import PaymentDetails from "./PaymentDetails.js";
import "./Payment.css";
import TableNoResultFound from "../../constant/NoResultFound/TableNoResultFound.js";
import { CgExtensionRemove } from "react-icons/cg";
import { PaymentmodeTypeDropDown } from "./Dropdown.js";
import { FaPaperclip, FaRegCopy } from "react-icons/fa";
import DateRangeDropdown from "../../constant/DateRangeFilter.js";
import DownloadPaymentList from "../../utils/paymentDownload.js";

const recordsPage = 15;

const Payments = ({ activeTab, setActiveTab }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [data, setData] = useState([]);
  const [date_range, setdate_range] = useState("");
  const [filter, setFilter] = useState({
    status: "",
    start_date: "",
    end_date: "",
  });
  const [sort_by, setSort_by] = useState(
    "-booking_transaction__booking_number"
  );
  const [expandedRows, setExpandedRows] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedPaymentmode, setSelectedPaymentmode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const initialVisibleheadersPayment = [
    "Booking ID",
    "Transaction ID",
    "Member Name",
    "Date Of Booking",
    "Amount",
  ];
  const [currentAttachment, setCurrentAttachment] = useState(null);
  const size = useWindowSize();
  const isMobile = size.width <= 1024;
  const navigate = useNavigate();

  const handleShowAttachment = (attachmentUrl) => {
    const fullUrl = `${process.env.REACT_APP_BASE_URL}${attachmentUrl}`;
    setCurrentAttachment(fullUrl);
    setShowModals(true);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Transaction ID copied to clipboard!", {});
      })
      .catch((error) => {
        toast.error("Failed to Copy Transaction ID.", {});
        console.error("Error copying text: ", error);
      });
  };

  const handleClose = () => setShowModals(false);

  const headersPayment = [
    {
      label: "Booking ID",
      accessor: (item) => item?.booking_transaction?.booking_number,
      sortKey: "booking_transaction__booking_number",
      width: 15,
    },
    {
      label: "Transaction ID",
      accessor: (item) =>
        item?.status === "Razorpay" ? (
          <div className="d-flex align-items-center">
            <span
              style={{
                color: "green",
                fontSize: "1.5em",
                marginRight: "5px",
              }}
            >
              ✔
            </span>
            <span className="d-flex align-items-center">
              {item?.reference_number}
              <FaRegCopy
                style={{
                  color: "grey",
                  cursor: "pointer",
                  marginLeft: "3px",
                }}
                onClick={() => copyToClipboard(item?.reference_number)}
                title="Copy Transaction ID"
              />
              {item?.attachment && (
                <FaPaperclip
                  style={{
                    color: "#007bff",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                  onClick={() => handleShowAttachment(item?.attachment)}
                />
              )}
            </span>
          </div>
        ) : (
          <span className="d-flex align-items-center">
            {item?.reference_number}
            <FaRegCopy
              style={{
                color: "#6c757d",
                cursor: "pointer",
                marginLeft: "5px",
              }}
              onClick={() => copyToClipboard(item?.reference_number)}
              title="Copy Transaction ID"
            />
            {item?.attachment && (
              <FaPaperclip
                style={{
                  color: "#007bff",
                  cursor: "pointer",
                  marginLeft: "5px",
                }}
                onClick={() => handleShowAttachment(item?.attachment)}
              />
            )}
          </span>
        ),
      sortKey: "reference_number",
      width: 30,
    },
    {
      label: "Date Of Booking",
      accessor: (item) => item?.created_at,
      sortKey: "created_at",
      width: 20,
    },
    {
      label: "Member Name",
      accessor: (item) => (
        <span
          className="text-primary cursor-pointer"
          onClick={() => navigate(`/member/${item?.user?.id}`)}
        >
          {item?.user?.first_name} {item?.user?.last_name}
        </span>
      ),
      sortKey: "user__first_name",
      width: 25,
    },
    {
      label: "Booked Room",
      accessor: (item) => (
        <span
          className="text-primary cursor-pointer"
          onClick={() => navigate(`/room/${item?.booking_slot?.room?.id}`)}
        >
          {item?.booking_slot?.room?.name}
        </span>
      ),
      sortKey: "booking_slot__room__name",
      width: 20,
    },
    {
      label: "Payment Source",
      accessor: (item) => item?.payment_source,
      sortKey: "payment_source",
      width: 20,
    },
    {
      label: "Amount",
      accessor: (item) => (
        <div
          style={{ cursor: "pointer" }}
          className="text-primary"
          onClick={() => handleAmountClick(item)}
        >
          {parseFloat(item?.net_amount).toFixed(2)}
        </div>
      ),
      sortKey: "net_amount",
      width: 15,
    },
  ];

  const initialColumns = headersPayment.filter((header) =>
    initialVisibleheadersPayment.includes(header.label)
  );
  const additionalColumns = headersPayment.filter(
    (header) => !initialVisibleheadersPayment.includes(header.label)
  );

  const [visibleheadersPayment, setVisibleheadersPayment] =
    useState(initialColumns);
  const [selectedColumns, setSelectedColumns] = useState(
    () => new Set(initialColumns.map((column) => column.label))
  );
  const [columnOrder, setColumnOrder] = useState(
    visibleheadersPayment.map((header) => header.sortKey)
  );

  const [selectAll, setSelectAll] = useState(false);

  const handleColumnSelection = (label) => {
    const updatedColumns = new Set(selectedColumns);

    if (updatedColumns.has(label)) {
      updatedColumns.delete(label);
    } else {
      updatedColumns.add(label);
    }

    setSelectedColumns(updatedColumns);
    setSelectAll(updatedColumns.size === headersPayment.length);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedColumns(new Set());
    } else {
      setSelectedColumns(new Set(headersPayment.map((header) => header.label)));
    }
    setSelectAll(!selectAll);
  };

  const handleApplyColumns = () => {
    const updatedVisibleheadersPayment = headersPayment.filter((header) =>
      selectedColumns.has(header.label)
    );
    setVisibleheadersPayment(updatedVisibleheadersPayment);
    setColumnOrder(
      updatedVisibleheadersPayment.map((header) => header.sortKey)
    );
    setShowModal(false);
  };

  const renderColumnOptions = (columns) => {
    return columns.map((header, index) => (
      <div
        key={index}
        className="table-component__column-option"
        onClick={() => handleColumnSelection(header.label)}
        style={{ userSelect: "none" }}
      >
        <label className="table-component__checkbox-label">
          <input
            type="checkbox"
            className="table-component__checkbox"
            checked={selectedColumns.has(header.label)}
            onChange={() => handleColumnSelection(header.label)}
          />
          {header.label}
        </label>
        {selectedColumns.has(header.label) && (
          <span
            className="cancel-icon"
            onClick={(e) => {
              e.stopPropagation(); // Prevents event bubbling to parent div
              handleColumnSelection(header.label);
            }}
          >
            ×
          </span>
        )}
      </div>
    ));
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const fromIndex = e.dataTransfer.getData("text/plain");
    const newOrder = [...columnOrder];
    const [movedItem] = newOrder.splice(fromIndex, 1);
    newOrder.splice(index, 0, movedItem);
    setColumnOrder(newOrder);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleAmountClick = (item) => {
    setSelectedTransaction(item);
  };

  const handleRowExpandToggle = (index) => {
    let newExpandedRows = [...expandedRows];
    if (newExpandedRows.includes(index)) {
      newExpandedRows = newExpandedRows.filter(
        (rowIndex) => rowIndex !== index
      );
    } else {
      newExpandedRows.push(index);
    }
    setExpandedRows(newExpandedRows);
  };

  useEffect(() => {
    setIsLoading(true);
    getPaymentList(
      currentPage,
      recordsPage,
      searchQuery,
      selectedPaymentmode,
      filter.start_date,
      filter.end_date,
      sort_by,
      date_range
    )
      .then((resp) => {
        const { results, total_count_amount, count } = resp.data;

        setData(results);
        setTotalAmount(total_count_amount);
        setTotal(count);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, [
    currentPage,
    searchQuery,
    filter.start_date,
    filter.end_date,
    selectedPaymentmode,
    sort_by,
    date_range,
  ]);

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const handleResetDateFilter = () => {
    setFilter({
      ...filter,
      start_date: "",
      end_date: "",
    });
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
  };

  const handleSort = (key) => {
    const sorbtbysplit = sort_by.split("-");
    const isAsc = sorbtbysplit.length > 1;
    const sort_byValue = isAsc ? sorbtbysplit[1] : sort_by;
    if (sort_byValue === key) {
      setSort_by(isAsc ? key : `-${key}`);
    } else {
      setSort_by(key);
    }
  };

  const renderSortIcon = (key) => {
    const sorbtbysplit = sort_by.split("-");
    const isAsc = sorbtbysplit.length > 1;
    const sort_byValue = isAsc ? sorbtbysplit[1] : sort_by;
    if (sort_byValue === key) {
      return isAsc ? <HiArrowNarrowUp /> : <HiArrowNarrowDown />;
    }
    return (
      <>
        <LuArrowUpDown />
      </>
    );
  };

  return (
    <>
      <Header />
      {selectedTransaction ? (
        <PaymentDetails
          transaction={selectedTransaction}
          onClose={() => setSelectedTransaction(null)}
        />
      ) : (
        <Container
          className="Container mt-sm-0 mt-7 mb-sm-0 mb-7 p-sm-2 p-1"
          fluid
        >
          <div className="d-flex flex-wrap align-items-center justify-content-between p-2">
            <div>
              {/* Header with toggle options */}
              <div className="d-flex mb-3">
                <h3
                  className={`cursor-pointer me-3 ${
                    activeTab === "payments" ? "active-tab" : "inactive-tab"
                  }`}
                  onClick={() => setActiveTab("payments")}
                >
                  Payments
                </h3>
                <h3
                  className={`cursor-pointer ${
                    activeTab === "refunds" ? "active-tab" : "inactive-tab"
                  }`}
                  onClick={() => setActiveTab("refunds")}
                >
                  Refunds
                </h3>
              </div>
            </div>
            <div className="row align-items-end">
              <div className="col-md-7 mb-3">
                <DateRangeDropdown
                  filter={filter}
                  setFilter={setFilter}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  handleResetDateFilter={handleResetDateFilter}
                />
              </div>
              <div className="col-md-5 mb-3">
                <div className="search-bar position-relative">
                  <input
                    type="text"
                    className="form-control search-input w-100"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  {!searchQuery && (
                    <i className="fas fa-search search-icon"></i>
                  )}
                  {searchQuery && (
                    <i
                      className="fas fa-times position-absolute"
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                        color: "#999999",
                      }}
                      onClick={() => setSearchQuery("")}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="summary-section d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div className="d-flex align-items-center mb-3 mb-md-0 px-3">
              <div className="summary-item me-6">
                <span className="summary-title text-sm">Total Payments</span>
                <span className="summary-amount text-sm">
                  ₹{totalAmount?.total_amount?.SUCCESS}{" "}
                  <FcBullish className="ms-1 icon-size" />
                </span>
              </div>
              <div className="summary-item">
                <span className="summary-title text-sm">
                  Total Transactions
                </span>
                <span className="summary-count text-sm">
                  {total} <FcBullish className="ms-1 icon-size" />
                </span>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <PaymentmodeTypeDropDown
                setSelectedPaymentmode={setSelectedPaymentmode}
                selectedPaymentmode={selectedPaymentmode}
              />
              {activeTab === "payments" && (
                <div className="ms-auto">
                  <DownloadPaymentList
                    currentPage={currentPage}
                    recordsPage={recordsPage}
                    searchQuery={searchQuery}
                    startDate={filter.start_date}
                    endDate={filter.end_date}
                    sort_by={sort_by}
                    date_range={date_range}
                  />
                </div>
              )}
              {!isMobile && (
                <div className="me-3">
                  <button
                    className="btn btn-sm btn-info"
                    onClick={() => setShowModal(true)}
                  >
                    <CgExtensionRemove style={{ fontSize: "1.4rem" }} />
                  </button>
                  <Modal
                    className="table-component__modal"
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    size="lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Select Columns</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="table-component__column-options">
                        <div className="table-component__select-all">
                          <label className="table-component__checkbox-label">
                            <input
                              type="checkbox"
                              className="me-3"
                              checked={selectAll}
                              onChange={handleSelectAll}
                            />
                            Select All
                          </label>
                        </div>
                        {renderColumnOptions(initialColumns)}
                        {renderColumnOptions(additionalColumns)}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="table-component__close-btn"
                        onClick={() => setShowModal(false)}
                      >
                        Close
                      </button>
                      <button
                        className="table-component__apply-btn"
                        onClick={handleApplyColumns}
                      >
                        Apply
                      </button>
                    </Modal.Footer>
                  </Modal>
                </div>
              )}
            </div>
          </div>
          <Row>
            <div className="col" id="table-to-download">
              <Card className="shadow">
                {isMobile ? (
                  <>
                    <Table className="table-flush" responsive>
                      <thead className="thead-light disable-select">
                        <tr style={{ fontSize: "0.875rem" }}>
                          <th
                            scope="col"
                            className="py-2"
                            onClick={() => handleSort("reference_number")}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              Transaction ID
                              <span className="ms-1">
                                {renderSortIcon("reference_number")}
                              </span>
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="py-2"
                            onClick={() => handleSort("net_amount")}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              Amount
                              <span className="ms-1">
                                {renderSortIcon("net_amount")}
                              </span>
                            </div>
                          </th>
                          <th scope="col" className="py-2">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <div className="text-center my-5">
                              <div
                                className="spinner-border text-primary"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </tr>
                        ) : data ? (
                          data.map((item, index) => (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td className="status-success">
                                  <span className="d-flex align-items-center">
                                    {item?.reference_number}
                                    <FaRegCopy
                                      style={{
                                        color: "#6c757d",
                                        cursor: "pointer",
                                        marginLeft: "5px",
                                      }}
                                      onClick={() =>
                                        copyToClipboard(item?.reference_number)
                                      }
                                      title="Copy Transaction ID"
                                    />
                                    {item?.attachment && (
                                      <FaPaperclip
                                        style={{
                                          color: "#007bff",
                                          cursor: "pointer",
                                          marginLeft: "5px",
                                        }}
                                        onClick={() =>
                                          handleShowAttachment(item?.attachment)
                                        }
                                      />
                                    )}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    marginBottom: "5px",
                                    cursor: "pointer",
                                    color: "#007bff",
                                  }}
                                  onClick={() => handleAmountClick(item)}
                                >
                                  {parseFloat(item.net_amount).toFixed(2)}
                                </td>
                                <td>
                                  <Button
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => handleRowExpandToggle(index)}
                                  >
                                    More...
                                  </Button>
                                </td>
                              </tr>
                              {expandedRows.includes(index) && (
                                <tr>
                                  <td colSpan="3">
                                    <div style={{ fontSize: "0.75rem" }}>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "#555",
                                          }}
                                        >
                                          <strong style={{ color: "#333" }}>
                                            Booking No.:{" "}
                                          </strong>
                                          {
                                            item?.booking_transaction
                                              ?.booking_number
                                          }
                                        </div>
                                        <div
                                          style={{
                                            marginBottom: "5px",
                                            color: "#555",
                                          }}
                                        >
                                          <strong style={{ color: "#333" }}>
                                            Date of Booking:{" "}
                                          </strong>
                                          {item.created_at}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          marginBottom: "5px",
                                          color: "#555",
                                        }}
                                      >
                                        <strong style={{ color: "#333" }}>
                                          Member Name:{" "}
                                        </strong>
                                        <span
                                          className="text-primary cursor-pointer"
                                          onClick={() =>
                                            navigate(
                                              `/member/${item?.user?.id}`
                                            )
                                          }
                                        >
                                          {item?.user?.first_name} 
                                          {item?.user?.last_name}
                                        </span>
                                      </div>

                                      <div
                                        style={{
                                          marginBottom: "5px",
                                          color: "#555",
                                        }}
                                      >
                                        <strong style={{ color: "#333" }}>
                                          Room Name:{" "}
                                        </strong>
                                        <span
                                          className="text-primary cursor-pointer"
                                          onClick={() =>
                                            navigate(
                                              `/room/${item?.booking_slot?.room?.id}`
                                            )
                                          }
                                        >
                                          {item?.booking_slot?.room?.name}
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          marginBottom: "5px",
                                          color: "#555",
                                        }}
                                      >
                                        <strong style={{ color: "#333" }}>
                                          Payment Source:{" "}
                                        </strong>
                                        {item?.payment_source}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))
                        ) : (
                          <TableNoResultFound />
                        )}
                      </tbody>
                    </Table>
                    <Modal show={showModals} onHide={handleClose} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Proof Of Payment</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="text-center">
                        {currentAttachment ? (
                          <img
                            src={currentAttachment}
                            alt="Attachment"
                            style={{ width: "100%", height: "auto" }}
                          />
                        ) : (
                          <p>No Proof Of Payment Found</p>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <CardFooter className="py-4">
                      <Pagination
                        currentPage={currentPage}
                        recordsPage={recordsPage}
                        dataLength={total}
                        handlePagination={handlePagination}
                      />
                    </CardFooter>
                  </>
                ) : (
                  <>
                    <Table
                      className="table-component__table align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light disable-select">
                        <tr style={{ fontSize: "1rem" }}>
                          {columnOrder.map((sortKey, index) => {
                            const header = visibleheadersPayment.find(
                              (h) => h.sortKey === sortKey
                            );
                            return (
                              <th
                                key={index}
                                scope="col"
                                className="py-3"
                                onClick={() => handleSort(header.sortKey)}
                                draggable
                                onDragStart={(e) => handleDragStart(e, index)}
                                onDrop={(e) => handleDrop(e, index)}
                                onDragOver={handleDragOver}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {header.label}
                                  <span className="ms-1">
                                    {renderSortIcon(header.sortKey)}
                                  </span>
                                </div>
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td
                              colSpan={columnOrder.length}
                              className="text-center my-7"
                            >
                              <div
                                className="text-center my-7"
                                style={{ width: "100%", height: "100%" }}
                              >
                                <div
                                  className="spinner-grow text-black me-2"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ) : data ? (
                          data.map((item, index) => (
                            <tr key={index}>
                              {columnOrder.map((sortKey, i) => {
                                const header = visibleheadersPayment.find(
                                  (h) => h.sortKey === sortKey
                                );
                                return <td key={i}>{header.accessor(item)}</td>;
                              })}
                            </tr>
                          ))
                        ) : (
                          <TableNoResultFound />
                        )}
                      </tbody>
                    </Table>
                    <Modal show={showModals} onHide={handleClose} centered>
                      <Modal.Header closeButton>
                        <Modal.Title>Proof Of Payment</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="text-center">
                        {currentAttachment ? (
                          <img
                            src={currentAttachment}
                            alt="Attachment"
                            style={{ width: "100%", height: "auto" }}
                          />
                        ) : (
                          <p>No Proof Of Payment found</p>
                        )}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <CardFooter className="py-4">
                      <Pagination
                        currentPage={currentPage}
                        recordsPage={recordsPage}
                        dataLength={total}
                        handlePagination={handlePagination}
                      />
                    </CardFooter>
                  </>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Payments;
