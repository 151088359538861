import React, { useState } from "react";
const RoomTypeDropDown = ({
  setFilter,
  filter,
  setCurrentPage,
  style,
  className,
  classNamedropdown,
}) => {
  const [dropdown, setDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleFilterChange = (name, value) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      [name]: value, // Update room_type filter
    }));
    setCurrentPage(1); // Reset to first page
    setDropdown(false);
  };

  return (
    <div className={`dropdown-filter-status ${classNamedropdown}`}>
      <button
        className={`btn dropdown-toggle ${className}`}
        style={style}
        type="button"
        id="roomTypeDropdown"
        onClick={handleToggleDropdown}
      >
        {filter.room_type || "Room Type"}
      </button>
      {dropdown && (
        <ul className="dropdown-filter-menu" aria-labelledby="roomTypeDropdown">
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilterChange("room_type", "")}
            >
              All
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilterChange("room_type", "Room")}
            >
              Room
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilterChange("room_type", "Lounge")}
            >
              Lounge
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilterChange("room_type", "Hall")}
            >
              Hall
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilterChange("room_type", "Mandap")}
            >
              Mandap
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export const StatusTypeDropDown = ({
  setFilterStatus,
  filterStatus,
  setCurrentPage,
  style
}) => {
  const [statusdropdown, setStatusDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setStatusDropdown(!statusdropdown);
  };

  const handleFilter = (status) => {
    setFilterStatus(status);
    setCurrentPage(1);
    setStatusDropdown(false);
  };

  return (
    <div className="dropdown-filter-status">
      <button
        className="btn dropdown-toggle"
        type="button"
        style={style}
        id="statusDropdown"
        onClick={handleToggleDropdown}
      >
        {filterStatus || "Status"}
      </button>
      {statusdropdown && (
        <ul className="dropdown-filter-menu" aria-labelledby="statusDropdown">
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilter(null)}
            >
              All
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilter("Pending")}
            >
              Pending
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilter("Modify")}
            >
              Modify
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilter("Booked")}
            >
              Booked
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilter("Cancelled")}
            >
              Cancelled
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleFilter("Refunded")}
            >
              Refunded
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export const SortTypeDropDown = ({
  setSortBy,
  sortBy,
  style,
  className,
  classNamedropdown
}) => {
  const [sortdropdown, setSortDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setSortDropdown(!sortdropdown);
  };

  
  const handleSortPrice = (order) => {
    if (order === "Low-High") {
      setSortBy("price");
    } else if (order === "High-Low") {
      setSortBy("-price");
    }
    setSortDropdown(false);
  };

  return (
    <div className={`dropdown-filter-status ${classNamedropdown}`}>
      <button
        className={`btn dropdown-toggle ${className}`}
        onClick={handleToggleDropdown}
        type="button"
        id="sortDropdown"
        style={style}
      >
        {sortBy === "-created_at"
          ? "Sort By"
          : sortBy === "-price"
          ? "High-Low"
          : "Low-High"}
      </button>
      {sortdropdown && (
        <ul className="dropdown-filter-menu" aria-labelledby="sortDropdown">
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleSortPrice("Low-High")}
            >
              Low-High
            </a>
          </li>
          <li>
            <a
              className="dropdown-filter-item cursor-pointer"
              onClick={() => handleSortPrice("High-Low")}
            >
              High-Low
            </a>
          </li>
        </ul>
      )}
    </div>
  );
};

export default RoomTypeDropDown;


// import React, { useState } from 'react';
// import Flatpickr from 'react-flatpickr';
// import 'flatpickr/dist/themes/material_green.css'; // Optional theme for the calendar
// import 'flatpickr/dist/flatpickr.css'; // Default Flatpickr styles
// import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';

// const DateFilterDropdown = () => {
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [selectedFilter, setSelectedFilter] = useState(''); // Selected filter
//   const [customRange, setCustomRange] = useState([null, null]); // Custom range state
//   const [isCustomRangeOpen, setIsCustomRangeOpen] = useState(false); // Custom range calendar state

//   const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

//   const predefinedFilters = [
//     { label: 'Today', value: 'today' },
//     { label: 'Yesterday', value: 'yesterday' },
//     { label: 'Last 7 Days', value: 'last7days' },
//     { label: 'Last 15 Days', value: 'last15days' },
//     { label: 'Last 30 Days', value: 'last30days' },
//     { label: 'Last Month', value: 'lastMonth' },
//     { label: 'This Month', value: 'thisMonth' },
//     { label: 'Custom Range', value: 'custom' },
//   ];

//   const handleFilterSelect = (filter) => {
//     setSelectedFilter(filter.value);
//     if (filter.value === 'custom') {
//       setIsCustomRangeOpen(true);
//     } else {
//       setIsCustomRangeOpen(false);
//       // Perform action based on filter (today, yesterday, etc.)
//       console.log(`Selected filter: ${filter.value}`);
//     }
//   };

//   const applyCustomRange = () => {
//     console.log('Custom range selected:', customRange);
//     setIsCustomRangeOpen(false);
//   };

//   const cancelCustomRange = () => {
//     setCustomRange([null, null]);
//     setIsCustomRangeOpen(false);
//   };

//   // Inline styles for the component
//   const styles = {
//     dropdownContainer: {
//       marginBottom: '20px',
//     },
//     customRangeCalendar: {
//       marginTop: '10px',
//       border: '1px solid #ddd',
//       padding: '10px',
//       borderRadius: '5px',
//       backgroundColor: '#fff',
//       width: '332px', // Set a fixed width for consistency
//       boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
//       position: 'absolute', // Position calendar absolute for dropdown look
//       zIndex: 1000, // Ensure it appears above other elements
//     },
//     customRangeActions: {
//       display: 'flex',
//       justifyContent: 'space-between',
//       marginTop: '10px',
//     },
//     buttonApply: {
//       backgroundColor: '#4CAF50',
//       color: 'white',
//     },
//     buttonCancel: {
//       backgroundColor: '#f44336',
//       color: 'white',
//     },
//   };

//   return (
//     <div style={styles.dropdownContainer}>
//       <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
//         <DropdownToggle caret>
//           {selectedFilter ? predefinedFilters.find((filter) => filter.value === selectedFilter)?.label : 'Select Date Range'}
//         </DropdownToggle>
//         <DropdownMenu>
//           {predefinedFilters.map((filter) => (
//             <DropdownItem key={filter.value} onClick={() => handleFilterSelect(filter)}>
//               {filter.label}
//             </DropdownItem>
//           ))}
//         </DropdownMenu>
//       </Dropdown>
//       {isCustomRangeOpen && (
//         <div style={styles.customRangeCalendar}>
//           <Flatpickr
//             value={customRange}
//             placeholder='select date range'
//             className='text-sm mb-2'
//             onChange={(dates) => setCustomRange(dates)}
//             options={{
//               mode: 'range',
//               dateFormat: 'Y-m-d',
//               inline: true, // Show the calendar inline
//               static: true, // Keep it static for dropdown look
//             }}
//           />
//           <div className='mt-3 d-flex align-item-center justify-content-end'>
//             <button className='btn btn-sm btn-primary' onClick={applyCustomRange}>
//               Apply
//             </button>
//             <button className='btn btn-sm btn-secondary' onClick={cancelCustomRange}>
//               Cancel
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default DateFilterDropdown;
